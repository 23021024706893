.page-project-floor {
  display: grid;
  --padding-inline: 1rem;
  --overlap: 3.8rem;
  grid-template-columns: var(--padding-inline) auto var(--padding-inline);
  grid-template-rows: auto var(--overlap) auto auto;
  // padding-bottom: 12rem;

  .cover {
    grid-row: 1;
    grid-column: 2;
    padding: 0 1rem;
    gap: 0;
    min-height: 0;

    .head {
      position: relative;
    }

    .cover-content {
      padding-top: 14.8rem;
      padding-bottom: 2rem;

      .h2 {
        margin: 1.2rem 0;
      }
      .lead {
        --font-weight: 400;
      }
    }
  }
  .background {
    // inset: unset;
    position: relative;
    grid-row: 1 / span 2;
    grid-column: 1 / span 3;

    img {
      border-radius: 2.4rem;
    }
  }

  .items {
    grid-column: 2;
    grid-row: 2 / span 2;
    padding: 4rem 1.2rem;
    background: #fff;

    display: grid;
    gap: 2.2rem;

    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
    border-radius: 1.8rem;

    text-align: center;
  }

  .item {
    .num {
      font-size: 4.32rem;
      line-height: 5.76rem;
      color: var(--color-blue);
      font-weight: 700;
    }

    .title {
      font-size: 2.6rem;
      line-height: 3.2rem;
      font-weight: 700;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .collection {
    padding-top: 6.4rem;
    padding-bottom: 5.8rem;
    grid-column: 1 / span 3;
    grid-row: 4;
    display: grid;
    gap: 2.8rem;

    .sub2 {
      text-align: center;
    }
  }

  @media (min-width: $lg) {
    padding-bottom: 8.6rem;
    --padding-inline: max(2rem, calc(50vw - 60.4rem));
    --overlap: 3.8rem;
    // grid-template-columns: var(--padding-inline) auto var(--padding-inline);
    grid-template-rows: auto auto var(--overlap) auto;

    .cover {
      padding: 0 4rem;

      .cover-content {
        padding-bottom: 7rem;
      }
    }

    .items {
      padding: 6.4rem 6.3rem 5.7rem;
      text-align: left;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 3.4rem;
    }

    .item {
      position: relative;
      padding-left: 6.3rem;
      max-width: 47.3rem;

      .num {
        position: absolute;
        left: 0;
        top: -0.2rem;
      }

      .title {
        margin-bottom: 0.6rem;
      }

      &:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
      }
    }

    .scroller {
      padding-bottom: 7.4rem;
    }

    .collection {
      padding-top: 8.4rem;
      padding-bottom: 0;
      gap: 4rem;
    }
  }
}
