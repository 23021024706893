.floor {
  position: relative;
  z-index: 1;

  .background {
    position: absolute;
    inset: 0;
    z-index: -1;
  }
}
