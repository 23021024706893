.page-facilitats-floor {
  z-index: 2;
  .cover {
    position: relative;
    z-index: 2;
    margin-bottom: -5.2rem;
    min-height: 67rem;

    .products-list {
      position: absolute;
      z-index: 1;
      inset: auto 2rem 2.8rem;
      color: var(--color-white);
    }
  }

  .content {
    --inline-padding: 1rem;
    position: relative;
    display: grid;
    z-index: 1;
    // padding: 0 1rem;
  }

  .numbered-list {
    padding: 10.5rem 1.2rem 5.2rem;
    background: var(--color-white);
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
    border-radius: 22px;
  }

  .products-wrapper {
    display: none;
  }

  @media (min-width: $xl) {
    .cover {
      padding-top: 22.3rem;
      padding-bottom: 47.9rem;

      .lead {
        max-width: 46.7rem;
      }

      .products-list {
        display: none;
      }
    }

    .content {
      --container-width: 113rem;
      --inline-padding: 2rem;
      --border-radius: 6rem;
      display: grid;
      z-index: 3;
      margin-top: -42.5rem;
      grid-template-columns: 380fr 173fr 576fr;
      grid-template-columns: 380fr 173fr 707fr;

      .products-wrapper {
        position: relative;
        isolation: isolate;
        display: block;
        grid-column: 1 / span 2;
        grid-row: 1;
        background: #0005;
        border-radius: var(--border-radius) 9in 9in var(--border-radius);
        padding: 1.7rem;
        overflow: hidden;
      }

      .numbered-list {
        grid-column: 2 / span 2;
        grid-row: 1;
        padding: 3.6rem 3.4rem 4.4rem calc(var(--computed-width) * 0.173 + 4rem);
        border-radius: 9in var(--border-radius) var(--border-radius) 9in;
      }
    }
  }
}
