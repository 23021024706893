.home-projects-floor {
  display: grid;
  gap: 3rem;

  padding-top: 7.5rem;
  padding-bottom: 8.6rem;

  background: var(--bg-section-alt);

  .cards {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  @media (min-width: $lg) {
    --container-inline-padding: 4rem;

    padding-top: 12rem;
    padding-bottom: 15rem;
    position: relative;
    gap: 9rem;

    .cards {
      flex-direction: row;
      gap: 2.6rem;
    }

    .card:first-child {
      margin-bottom: 11rem;
    }
    .card:last-child {
      margin-top: 11rem;
    }

    &::before {
      --offset: 40rem;
    }

    .cards {
      isolation: isolate;

      .parallax-element {
        content: "";
        position: absolute;
        display: block;
        width: 141.5rem;
        height: 86.9rem;
        left: 50%;
        bottom: -3rem;
        margin-left: -23rem;

        > div {
          position: absolute;
          inset: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='1415' height='869' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M419.263 253.463A2205.358 2205.358 0 0 1 1156.13 3.943a216.498 216.498 0 0 1 86.78.924 217.1 217.1 0 0 1 79.53 34.932 217.59 217.59 0 0 1 59.53 63.342 217.776 217.776 0 0 1 29.99 81.598 217.32 217.32 0 0 1-4.36 86.772 216.598 216.598 0 0 1-38.01 78.036 216.133 216.133 0 0 1-65.57 56.79 216.118 216.118 0 0 1-82.61 26.44c-320.08 48.785-620.855 183.359-870.473 389.471-44.433 34.99-100.806 51.166-157.074 45.072-56.268-6.093-107.97-33.974-144.058-77.684-36.089-43.71-53.703-99.784-49.08-156.242C5.35 576.936 31.844 524.572 74.55 487.489A2226.429 2226.429 0 0 1 419.422 253.11' fill='%234AADF8'/%3E%3C/svg%3E");
        }
      }
    }

    &[data-variant="tot-tallers"] {
      .cards {
        isolation: isolate;

        .parallax-element {
          content: "";
          width: 78.5rem;
          height: 65.4rem;
          left: 50%;
          bottom: -3rem;
          margin-left: -23rem;

          > div {
            background-image: url("data:image/svg+xml,%3Csvg width='785' height='654' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M69.487 178.042a42.49 42.49 0 0 1 32.15-34.593L700.065 1.599c25.592-6.066 50.493 12.34 52.2 38.586l32.09 493.165c1.478 22.722-15.217 42.571-37.857 45.006l-699.16 75.193C19.472 656.545-3.61 632.219.843 604.551l68.643-426.509Z' fill='%23FF604F'/%3E%3C/svg%3E");
          }
        }
      }
    }

    &[data-variant="sequencies"] {
      .cards {
        isolation: isolate;

        .parallax-element {
          content: "";
          width: 138.9rem;
          height: 98.8rem;
          left: 90%;
          bottom: 5rem;
          margin-left: -23rem;

          > div {
            background-image: url("data:image/svg+xml,%3Csvg width='1391' height='990' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30.25 878.939c-60.89-105.463-24.755-240.318 80.708-301.208L1059.22 30.249c105.47-60.889 240.32-24.754 301.21 80.709 60.89 105.463 24.76 240.318-80.71 301.207L331.457 959.647C225.994 1020.54 91.139 984.402 30.25 878.939Z' fill='%235D4D9B'/%3E%3C/svg%3E");
          }
        }
      }
      .card:first-child {
        margin-bottom: 0;
      }
      .card:last-child {
        margin-top: 0;
      }
    }
  }
}
