.head {
  color: var(--color, var(--color-professor2));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  padding: 2rem;
  text-align: center;

  .bullet {
    content: "";
    display: block;
    --size: 2.4rem;
    width: var(--size);
    height: var(--size);
    background: var(--dot-color, var(--color-professor2));
    border-radius: 9in;
  }

  .head__body {
    margin-top: -1.2rem;
    color: var(--body-color, #000);
    max-width: 67rem;
  }

  // .label {
  //   margin-top: 0.2rem;
  //   font-size: 1.8rem;
  //   line-height: 1.4rem;
  //   font-weight: 700;
  // }
}
