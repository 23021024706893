.page-demos-floor {
  padding-top: 5rem;
  padding-bottom: 11.4rem;

  .head {
    margin-bottom: 7rem;
  }

  .collections {
    display: grid;
    gap: 2rem;
  }

  .collection {
    --item-gap: 0.8rem;
    --item-width: 14.4rem;

    display: grid;
    gap: 2rem;
    overflow: hidden;

    .h2 {
      color: var(--color-professor1);
      // color: #000;
    }
  }

  // &[data-variant="digital"] .collection {
  //   --item-width: 17.6rem;
  // }

  .demos {
    .scroller {
      padding-bottom: 12rem;
    }
  }

  .demos[data-variant="hibrid"] {
    // --media-width: 14.3rem;
    // --media-height: 17.6rem;
    --media-width: 20.5rem;
    --media-height: 25.2rem;
  }
  .demos[data-variant="digital"] {
    // --media-width: 24.6rem;
    // --media-height: 17.6rem;
    --media-width: 22.9rem;
    --media-height: 32.1rem;
  }

  // .demos {
  // }

  // .scroll-wrap {
  //   width: 100vw;
  //   overflow-y: hidden;
  //   overflow-x: auto;
  //   scroll-snap-type: x mandatory;
  //   height: calc(100% + 2rem);
  // }

  // .scroller {
  //   display: flex;
  //   gap: var(--item-gap);
  // }

  // .demo {
  //   scroll-snap-align: start;
  //   width: min-content;
  //   margin-bottom: 12rem;
  //   margin-left: -2rem;
  //   padding-left: 2rem;

  //   &:first-child {
  //     margin-left: 0;
  //   }
  //   &:last-child {
  //     padding-right: 2rem;
  //   }

  //   > div {
  //     width: min-content;
  //     position: relative;
  //   }

  //   button {
  //     all: unset;
  //     position: relative;
  //     display: block;
  //     cursor: pointer;
  //     z-index: 2;
  //   }

  //   img {
  //     width: var(--item-width);
  //     max-width: none;
  //     border-radius: 1.2rem;
  //     box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.25);
  //   }

  //   .dropdown {
  //     position: absolute;
  //     background: #fff8;
  //     border: 2px solid rgba(0, 0, 0, 0.05);
  //     border-top: 0;
  //     border-radius: 0 0 1.1rem 1.1rem;
  //     inset: 100% 0.7rem auto;
  //     transform: translateY(-100%);
  //     transition: transform 0.2s ease-in-out;
  //     padding: 0.5rem 0.5rem;
  //     z-index: 1;

  //     li {
  //       padding: 0.7rem 0.2rem;
  //       + li {
  //         border-top: 2px solid rgba(#1e1348, 0.05);
  //       }
  //     }

  //     a {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       color: var(--color-professor1);
  //       font-weight: 700;
  //       font-size: 1.4rem;
  //       line-height: 1.6rem;

  //       &::after {
  //         flex: 0 0 2.4rem;
  //         content: "";
  //         display: block;
  //         width: 2.4rem;
  //         height: 2.4rem;
  //         background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21.6a9.6 9.6 0 1 0 0-19.199 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.697 1.697l1.552 1.551H8.399a1.2 1.2 0 1 0 0 2.4h4.304l-1.552 1.552a1.2 1.2 0 1 0 1.697 1.697l3.6-3.6a1.2 1.2 0 0 0 0-1.697Z' fill='%23FF604F'/%3E%3C/svg%3E");
  //       }
  //     }
  //   }

  //   .open .dropdown {
  //     transform: translateY(0);
  //   }
  // }

  .collection[data-variant="red"] {
    img {
      box-shadow: 0px 20px 40px -20px rgba(255, 96, 79, 0.6);
    }
  }
  .collection[data-variant="orange"] {
    img {
      box-shadow: 0px 20px 40px -20px rgba(247, 186, 138, 0.84);
    }
  }
  .collection[data-variant="blue"] {
    img {
      box-shadow: 0px 20px 40px -20px rgba(162, 189, 243, 0.61);
    }
  }
  .collection[data-variant="green"] {
    img {
      box-shadow: 0px 20px 40px -20px rgba(68, 200, 135, 0.57);
    }
  }
  .collection[data-direction="even"] {
    .h2 {
      text-align: left;
    }
  }
  .collection[data-direction="odd"] {
    .h2 {
      text-align: right;
    }
  }

  .list {
    height: 200vh;

    .parallax-element {
      width: 290.5rem;
      height: 191rem;
      background-image: url("data:image/svg+xml,%3Csvg width='2905' height='1910' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M620.029 26.552c258.219 39.924 507.291 125.45 735.651 252.604a216.382 216.382 0 0 1 68.12 53.742 217.078 217.078 0 0 1 41.62 76.24 217.531 217.531 0 0 1 8.43 86.516 217.465 217.465 0 0 1-26.1 82.922 217.14 217.14 0 0 1-56.44 66.036 216.634 216.634 0 0 1-77.75 38.562 216.082 216.082 0 0 1-86.58 4.908 216.117 216.117 0 0 1-81.54-29.535C862.287 501.634 542.022 424.435 218.557 435.125c-56.541.558-111.042-21.073-151.858-60.271C25.883 335.655 1.987 281.998.12 225.345c-1.87-56.652 18.436-111.807 56.578-153.68C94.838 29.79 147.792 4.519 204.244 1.249A2225.487 2225.487 0 0 1 620.371 26.37M2168.9 890.996a2205.482 2205.482 0 0 1 485.78 607.574 216.42 216.42 0 0 1 28.72 81.89c3.96 28.97 2.02 58.45-5.71 86.67a217.4 217.4 0 0 1-39.23 77.57 217.508 217.508 0 0 1-66.46 56.02 217.165 217.165 0 0 1-83.03 25.5c-29.1 2.82-58.46-.28-86.31-9.11a216.206 216.206 0 0 1-75.73-42.27 216.195 216.195 0 0 1-53.02-68.64c-155-284.24-384.04-521.08-662.87-685.43-48.03-29.835-82.46-77.309-95.92-132.282-13.46-54.972-4.89-113.083 23.88-161.916 28.78-48.834 75.47-84.518 130.11-99.428 54.63-14.91 112.88-7.865 162.3 19.631a2226.39 2226.39 0 0 1 337.88 244.25' fill='%23F5F7FA'/%3E%3C/svg%3E");
    }
  }

  @media (min-width: 1034px) {
    position: relative;
    z-index: -1;
    isolation: isolate;
    padding-top: 18rem;

    .collection {
      --container-width: 136rem;
      --item-gap: 2.6rem;

      // --item-width: clamp(14.4rem, 14vw, 20.5rem);
      --item-width: 100%;
      // --item-width: 14.4rem;
      .h2 {
        // color: var(--color-professor1);
        color: #000;
      }
    }

    .demos {
      --padding-inline: calc(50vw - var(--container-width) / 2);
    }
    // .demos {
    //   max-width: calc(var(--container-width) + 4rem);
    //   margin: 0 auto;
    //   padding: 0 2rem;
    // }

    // .demo {
    //   padding: 0;
    //   margin: 0 0 10rem;
    //   flex-grow: 1;
    //   > div {
    //     width: 100%;
    //     display: flex;
    //   }

    //   button,
    //   img {
    //     flex-grow: 1;
    //     width: 100%;
    //   }

    //   &:first-child {
    //     margin-left: 0;
    //   }
    //   &:last-child {
    //     padding-right: 0;
    //   }
    // }

    .collection[data-direction="odd"] {
      .scroller {
        justify-content: flex-end;
      }
    }

    .parallax-element {
      z-index: -1;
    }

    &[data-variant="digital"] .collection {
      --item-width: clamp(17.6rem, 20vw, 32.1rem);
    }
  }
}
