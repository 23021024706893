.index-video-floor {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  justify-content: center;
  align-items: center;

  background: #4aadf8;
  color: #fff;
  padding: 0 2rem 4rem;

  .head {
    --color: #fff;
    --dot-color: var(--color-professor1);
    --body-color: #fff;
  }

  &__video {
    // display: flex;
    // flex: 100%;

    a {
      position: relative;
      display: flex;
      overflow: hidden;
      border-radius: 1.2rem;
    }

    .video-play {
      --size: 9.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: var(--size);
      height: var(--size);
      // opacity: .3;
    }

    .poster {
      // max-width: 100%;
    }

    iframe {
      // display: none;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;

      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
    }

    .active iframe {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media (min-width: $lg) {
    gap: 3rem;
    padding: 5rem 2rem 18rem;

    padding-top: 12rem;
    margin-top: -9rem;

    &__video {
      max-width: 113rem;

      a {
        border-radius: 2.2rem;
      }

      .video-play {
        --size: 16rem;
      }
    }
  }
}
