.card {
  --border-radius: 2.2rem;
  display: flex;
  flex-direction: column;
  isolation: isolate;

  background: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.8rem 2.5rem -1rem rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius);

  .media {
    margin-bottom: -4.8rem;
    z-index: -1;
    overflow: hidden;
    border-radius: var(--border-radius);
  }
  .glyph {
    position: absolute;
    width: 6.4rem;
    height: 7.5rem;
    left: 2rem;
    top: 0;
    padding: 1.7rem 0.8rem;

    background: var(--color-white);
    border-radius: 0 0 9in 9in;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 2rem;
  }

  a {
    display: flex;
    align-items: center;
    gap: 1.3rem;
    margin-top: 1.2rem;
    // height: 4rem;
    font-weight: 600;
  }

  @media (min-width: $lg) {
    flex-direction: row;
    align-items: center;
    height: min-content;

    .media {
      margin-bottom: 0;
      // flex: 0 0 43.2rem;
      height: calc(39.6rem + 2px);
      inset: -1px;
      margin-bottom: -2px;

      picture img {
        height: 100%;
        width: 100%;
      }
    }

    .content {
      gap: 2.7rem;
      padding: 1.4rem;
      width: 27rem;
      flex: 0 1 min-content;

      .h2 {
        position: relative;
        margin-left: -7.5rem;
      }
    }
  }
}
