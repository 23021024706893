.sequencies-header {
  --nav-gap: 2.3rem;
  --nav-item-padding: 2.3rem;

  height: 7.2rem;
  position: fixed;
  inset: 0.8rem 0.8rem auto;
  // overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.4rem 0 2.2rem;
  background: var(--color-professor1);
  border-radius: 1.9rem;
  z-index: calc(var(--z-layout) + 1);
  font-size: 1.4rem;
  line-height: 1;

  color: #fff;

  > a {
    display: block;
    max-width: 10.2rem;
    margin-right: auto;

    > svg {
      height: 100%;
      width: 100%;
    }
  }

  nav {
    position: absolute;
    top: calc(100% + 0.8rem);
    left: 0;
    right: 0;

    padding: 4.6rem;
    background: var(--color-professor1);
    border-radius: 1.9rem;
    display: flex;
    flex-direction: column;
    gap: 3.6rem;

    > a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem var(--nav-item-padding) 0;
      height: 3rem;
      color: var(--color-white);
      border-radius: 9in;
      text-decoration: none;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 600;
      text-align: center;

      &[aria-current="page"] {
        &:before {
          --size: 0.6rem;
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          width: var(--size);
          height: var(--size);
          transform: translate(-50%, -50%);
          background: var(--color-white);
          border-radius: 9in;
        }
      }

      &[data-key="llengua-catalana"]::before {
        background: var(--color-red);
      }

      &[data-key="lengua-castellana"]::before {
        background: var(--color-orange);
      }

      &[data-key="ciencies-socials"]::before {
        background: var(--color-yellow);
      }

      &[data-key="tecnologia"]::before {
        background: var(--color-blue);
      }

      &[aria-current="page"] {
        background: var(--color-professor2);
      }
    }
  }

  &:not(.open) nav {
    display: none;
  }

  .actives {
    margin-left: 1rem;
    .nav-item {
      display: none;
      background: #372a69;
      height: 4.4rem;

      justify-content: center;
      align-items: center;
      padding: 0.3rem 2rem 0;
      border-radius: 1.5rem;

      text-align: center;
      max-width: 17rem;

      font-size: 1.4rem;
      line-height: 1.6rem;

      [data-page="llengua-catalana"] &[data-key="llengua-catalana"],
      [data-page="lengua-castellana"] &[data-key="lengua-castellana"],
      [data-page="ciencies-socials"] &[data-key="ciencies-socials"],
      [data-page="tecnologia"] &[data-key="tecnologia"] {
        display: flex;
      }
    }
  }

  .toggler {
    all: unset;
    display: inline-flex;
    margin-left: 1rem;
    cursor: pointer;

    circle {
      fill: var(--color-professor2);
    }

    [data-page="sequencies"] &,
    [data-page="llengua-catalana"] & {
      circle[data-key="llengua-catalana"] {
        fill: var(--color-red);
      }
    }

    [data-page="sequencies"] &,
    [data-page="lengua-castellana"] & {
      circle[data-key="lengua-castellana"] {
        fill: var(--color-orange);
      }
    }

    [data-page="sequencies"] &,
    [data-page="ciencies-socials"] & {
      circle[data-key="ciencies-socials"] {
        fill: var(--color-yellow);
      }
    }

    [data-page="sequencies"] &,
    [data-page="tecnologia"] & {
      circle[data-key="tecnologia"] {
        fill: var(--color-blue);
      }
    }
  }

  @media (min-width: $xl) {
    inset: 1.6rem 1.6rem auto;
    height: 8rem;
    padding: 0 2.2rem 0 3.5rem;
    justify-content: space-between;

    > a {
      max-width: 20.8rem;
    }

    .toggler,
    .actives {
      display: none;
    }

    nav {
      position: static;
      flex-direction: row;
      padding: 0;
      gap: 3.6rem;
      margin-left: 3.6rem;

      > a {
        height: 5.2rem;
        border-radius: 1.6rem;
        padding: 0 0.7rem;
        width: 15.6rem;

        &[aria-current="page"] {
          font-weight: 700;
        }
      }
    }
    &:not(.open) nav {
      display: flex;
    }
  }
}
