.index-hero-floor {
  --media-height: 29.4rem;
  // --media-height: 29.4rem;
  padding-top: 15rem;
  padding-bottom: calc(var(--media-height) + 5.6rem);
  background: var(--color-professor1);
  color: #fff;

  .logo {
    position: absolute;
    top: 4rem;
    left: 2.2rem;
    width: 10.7rem;
  }

  .h1 {
    margin-top: 2.9rem;
    margin-bottom: 2rem;

    b {
      color: #eb61a6;
    }
  }

  .scroll {
    position: absolute;
    left: 50%;
    bottom: var(--media-height);
    transform: translate(-50%, 50%);
  }

  .background {
    height: var(--media-height);
    top: auto;

    img {
      border-top-left-radius: 10rem;
    }
  }

  @media (min-width: $lg) {
    border-radius: 0 0 30rem 0;

    padding-top: 14rem;
    padding-bottom: 14rem;
    min-height: max(79rem, 100vh);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo {
      left: 4rem;
      width: 12.4rem;
    }

    .h1 {
      --font-size: 4.8rem;
      --line-height: 4.8rem;
      //       font-size: 80px;
      // font-weight: bold;
      // line-height: 82px;
      // font-size: 8rem;
      // line-height: 8.2rem;
    }

    .scroll {
      bottom: 6.5rem;
    }

    .background {
      top: 0;
      height: auto;
      left: auto;
      width: 50%;
      border-radius: 0 0 30rem 0;
      overflow: hidden;

      img {
        border-radius: 0;
      }

      video {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
      }
    }

    .content {
      width: 50%;
      padding-right: 10rem;
    }
  }

  @media (min-width: 1025px) {
    .h1 {
      --font-size: 8rem;
      --line-height: 8.2rem;
    }

    .content {
      padding-right: 13rem;
    }
  }
}
