.home-hero-floor {
  min-height: calc(100vh - 11.4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  color: var(--color-white);
  margin-bottom: 11.4rem;
  // --container-inline-padding: 2rem;
  // background: var(--bg-section);
  isolation: isolate;
  z-index: 5;

  .conten {
    width: min(100%, 35rem);
    display: grid;
    gap: 2.4rem;
  }

  .background {
    border-radius: 2.2rem 2.2rem 10rem 0;
    inset: 0;
    overflow: hidden;
  }

  &[data-variant="sequencies"] {
    .background img {
      object-position: top;
    }
  }

  .scroll {
    position: absolute;
    transform: translate(-50%, 50%);
    bottom: 0;
    left: 50%;
    display: block;
    cursor: pointer;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.15) #4444dd);
  }

  @media (min-width: $lg) {
    --container-width: 114rem;
    min-height: 100vh;
    margin: 0;
    padding-top: 8rem;
    padding-bottom: 4rem;

    .conten {
      width: min(100%, 79rem);
    }

    .background {
      border-radius: 2.2rem 2.2rem 30rem 0;
    }

    .scroll {
      bottom: 6.4rem;
    }
  }
}
