.parallax-element {
  position: absolute;
  // top: calc(var(--y) * 0.1rem);
  // left: calc(var(--x) * 0.1rem);
  z-index: -1;
  color: #f5f7fa;
  // transform: translate3d();
  // color: #f00;

  // background: #f00;

  &[data-shape="circle"] > [data-ref="element"] {
    width: 49.9rem;
    height: 49.9rem;
    background-image: url("data:image/svg+xml,%3Csvg width='499' height='499' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='249.5' cy='249.5' r='249.5' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }

  &[data-shape="cross"] > [data-ref="element"] {
    width: 61.6rem;
    height: 62.3rem;
    background-image: url("data:image/svg+xml,%3Csvg width='616' height='623' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M297.845 31.894a116.892 116.892 0 0 1 26.611 37.62l40.139 86.622 86.584-40.122a116.858 116.858 0 0 1 98.249 212.024l-86.584 40.122 40.138 86.62a116.859 116.859 0 0 1-18.896 129.707 116.88 116.88 0 0 1-81.978 37.987 116.858 116.858 0 0 1-84.565-31.814 116.87 116.87 0 0 1-26.61-37.619l-40.139-86.621-86.585 40.123A116.858 116.858 0 0 1 65.96 294.519l86.586-40.122-40.14-86.623a116.879 116.879 0 0 1-4.856-90.22 116.888 116.888 0 0 1 60.922-66.722 116.872 116.872 0 0 1 129.373 21.062Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }

  &[data-shape="morph"] > [data-ref="element"] {
    width: 290.5rem;
    height: 191rem;
    background-image: url("data:image/svg+xml,%3Csvg width='2905' height='1910' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M620.029 26.552c258.219 39.924 507.291 125.45 735.651 252.604a216.382 216.382 0 0 1 68.12 53.742 217.078 217.078 0 0 1 41.62 76.24 217.531 217.531 0 0 1 8.43 86.516 217.465 217.465 0 0 1-26.1 82.922 217.14 217.14 0 0 1-56.44 66.036 216.634 216.634 0 0 1-77.75 38.562 216.082 216.082 0 0 1-86.58 4.908 216.117 216.117 0 0 1-81.54-29.535C862.287 501.634 542.022 424.435 218.557 435.125c-56.541.558-111.042-21.073-151.858-60.271C25.883 335.655 1.987 281.998.12 225.345c-1.87-56.652 18.436-111.807 56.578-153.68C94.838 29.79 147.792 4.519 204.244 1.249A2225.487 2225.487 0 0 1 620.371 26.37M2168.9 890.996a2205.482 2205.482 0 0 1 485.78 607.574 216.42 216.42 0 0 1 28.72 81.89c3.96 28.97 2.02 58.45-5.71 86.67a217.4 217.4 0 0 1-39.23 77.57 217.508 217.508 0 0 1-66.46 56.02 217.165 217.165 0 0 1-83.03 25.5c-29.1 2.82-58.46-.28-86.31-9.11a216.206 216.206 0 0 1-75.73-42.27 216.195 216.195 0 0 1-53.02-68.64c-155-284.24-384.04-521.08-662.87-685.43-48.03-29.835-82.46-77.309-95.92-132.282-13.46-54.972-4.89-113.083 23.88-161.916 28.78-48.834 75.47-84.518 130.11-99.428 54.63-14.91 112.88-7.865 162.3 19.631a2226.39 2226.39 0 0 1 337.88 244.25' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }
}
