.page-sequencies-facilitats-floor {
  z-index: 2;
  margin-bottom: 5rem;

  .cover {
    position: relative;
    z-index: 2;
    margin-bottom: -5.2rem;
    min-height: 55.2rem;
    justify-content: flex-end;
  }

  .content {
    --inline-padding: 1rem;
    position: relative;
    display: grid;
    z-index: 1;
    // padding: 0 1rem;
  }

  .numbered-list {
    padding: 10.5rem 1.2rem 40%;
    background: var(--color-white);
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 50vw 50vw;
  }

  .features-list {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 45% 2rem 4rem;
    background: #5c4d9b;
    // box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
    border-radius: 50vw 50vw 2.2rem 2.2rem;
    margin-top: -30vw;

    .h3 {
      color: var(--color-professor3);
    }

    ul {
      display: grid;
      gap: 2.2rem;
    }

    li {
      padding-left: 4.5rem;
      background: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.998 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Zm4.449-11.152a1.2 1.2 0 0 0-1.697-1.697l-3.952 3.952-1.551-1.551a1.2 1.2 0 0 0-1.697 1.696l2.4 2.4a1.2 1.2 0 0 0 1.697 0l4.8-4.8Z' fill='%23B2A4D2'/%3E%3C/svg%3E");
      color: #fff;
    }
  }

  @media (min-width: $xl) {
    margin-bottom: 18rem;

    .cover {
      padding-top: 22.3rem;
      padding-bottom: 47.9rem;

      .lead {
        max-width: 46.7rem;
      }

      .products-list {
        display: none;
      }
    }

    .content {
      --container-width: 113rem;
      --inline-padding: 2rem;
      --border-radius: 6rem;
      display: grid;
      z-index: 3;
      margin-top: -42.5rem;
      grid-template-columns: 380fr 173fr 576fr;
      grid-template-columns: 380fr 173fr 707fr;

      .numbered-list {
        grid-column: 2 / span 2;
        grid-row: 1;
        padding: 3.8rem 3.4rem 4.4rem calc(var(--computed-width) * 0.173 + 4rem);
        border-radius: 9in var(--border-radius) var(--border-radius) 9in;
      }

      .features-list {
        grid-column: 1 / span 2;
        grid-row: 1;
        margin: 0;
        padding: 0;
        border-radius: var(--border-radius) 9in 9in var(--border-radius);

        padding: 4.2rem 4rem 4.2rem 3.2rem;

        ul {
          gap: 1.8rem;
        }
      }
    }
  }
}
