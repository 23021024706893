.home-itineraris-floor {
  position: relative;
  padding-top: 4rem;
  // padding-bottom: 4rem;
  z-index:4;
  // background: var(--bg-section);

  .wrapper {
    position: relative;
    height: 66.1rem;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 30rem;
    margin-top: -0.7rem;
    z-index: 1;
  }

  .title {
    font-size: 4.8rem;
    line-height: 5.2rem;
    font-weight: 700;
    margin-bottom: 1.3rem;

    b {
      display: block;
      font-size: 11rem;
      line-height: 10rem;
    }
  }

  .circle {
    svg {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (min-width: $lg) {
    padding-top: 16rem;
    // padding-bottom: 16rem;

    .wrapper {
      height: 79rem;
    }

    .content {
      width: 36.1rem;
      margin-top: -3rem;
    }

    .title {
      font-size: 7rem;
      line-height: 7.2rem;
      margin-bottom: 1rem;

      b {
        display: block;
        font-size: 17.8rem;
        line-height: 15.45rem;
      }
    }
  }
}
