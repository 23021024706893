.products-list {
  display: grid;
  gap: 0.8rem;

  img {
    width: 3.4rem;
  }

  li {
    display: flex;
    align-items: center;
    gap: 0.9rem;
  }
  @media (min-width: $xl) {
    background: var(--color-white);
    border-radius: 1.8rem;
    padding: .9rem 2rem;
    width: 26.3rem;

    gap: 0rem;
    .product-name {
      padding: 1.7rem 0;
      flex-grow: 1;
    }
    li + li .product-name {
      border-top: 1px solid #edecf0;
    }
  }
}
