.page-sequencies-intro-floor {
  padding-top: 20.4rem;
  padding-bottom: 22.4rem;
  text-align: center;

  .content {
    max-width: 40rem;
    margin: 0 auto;
  }

  .h2 b {
    display: block;
    font-size: 11rem;
    line-height: 1;
    color: var(--color-professor2);
  }

  .lead {
    font-weight: 400;
    margin-top: 1.2rem;
  }

  [data-label] {
    --size: 13.9rem;
    position: absolute;
    width: var(--size);
    height: var(--size);

    > div {
      font-weight: 700;
      font-size: 2.6rem;
      line-height: 3.2rem;
      letter-spacing: 0.01em;
      color: var(--brand);
      position: absolute;
    }

    svg {
      circle {
        fill: var(--brand);
      }
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 50.7rem;
      height: 21.2rem;
      background-image: url("data:image/svg+xml,%3Csvg width='507' height='212' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='507' height='212' rx='100' fill='%23F5F7FA'/%3E%3C/svg%3E");
      z-index: -1;
    }
  }

  [data-label="1"] {
    top: 3.8rem;
    left: 4.4rem;

    > div {
      top: 7.2rem;
      left: 12.3rem;
    }
    &::before {
      top: 3.7rem;
      left: 4.2rem;
    }
  }

  [data-label="2"] {
    bottom: 5.2rem;
    right: 1.4rem;

    > div {
      bottom: 4.2rem;
      right: 10.2rem;
    }
    &::before {
      bottom: 2.8rem;
      right: 3.4rem;
    }
  }

  @media (min-width: $lg) {
    padding-top: 22.7rem;
    padding-bottom: 12.7rem;

    .h2 b {
      font-size: 17.8rem;
      margin-bottom: -2rem;
    }

    .lead {
      margin-top: 2rem;
    }

    [data-label="1"] {
      top: calc(50% - 18rem);
      left: 4.4rem;
    }

    [data-label="2"] {
      top: calc(50% - 3rem);
      bottom: auto;
      right: 1.4rem;

      &::before {
        top: 3.8rem;
        bottom: auto;
      }
    }
  }

  @media (min-width: $xl) {
    [data-label="1"] {
      left: calc(50% - 55rem);
    }

    [data-label="2"] {
      right: calc(50% - 55rem);
    }
  }
}
