.app-footer {
  // .content,
  position: relative;
  // z-index: 1;

  .header,
  .info,
  // .form,
  // .form-footer,
  .footer {
    display: flex;
    flex-direction: column;
  }

  display: grid;
  gap: 4.6rem;
  padding-top: 5.4rem;
  padding-bottom: 4.2rem;
  background: var(--color-professor1);
  color: var(--color-white);

  // .content {
  //   gap: 4.6rem;
  // }

  .wrapper {
    display: flex;
    gap: 4.6rem;
    flex-direction: column;
  }

  .header {
    font-weight: 700;
    gap: 3rem;
  }

  .title {
  }

  .info {
    gap: 1.7rem;
    font-size: 2.2rem;
    line-height: 2.6rem;

    > div {
      display: flex;
      align-items: center;
      gap: 3.2rem;
    }
  }

  .form {
    display: grid;
    gap: 4.6rem;
    font-weight: 600;
  }

  .form-fields {
    display: grid;
    gap: 2rem;

    & + .form-fields {
      margin-top: 4.2rem;
    }
  }

  .form-title {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }

  .form-field {
    label {
      display: none;
    }

    input {
      height: 5.2rem;
      display: flex;
      padding: 0 1.2rem;
      align-items: center;
      border-radius: 0.6rem;
      border: 0;
      width: 100%;

      font-family: inherit;
    }
  }

  .checkbox {
    input {
      display: none;
    }

    span {
      display: inline-block;
      padding-left: 2.8rem;
    }

    span::before {
      content: "";
      display: block;
      position: absolute;
      width: 2rem;
      height: 2rem;
      margin-left: -2.8rem;
      background: center;
      background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.5' y='2.5' width='19' height='19' rx='3.5' fill='%234AADF8' stroke='%231E1348'/%3E%3C/svg%3E%0A");
    }

    input:checked + span::before {
      background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.5' y='2.5' width='19' height='19' rx='3.5' fill='%234AADF8' stroke='%231E1348'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m16.288 9.293-.003-.003a.997.997 0 0 0-1.412-.003L10.99 13.17l-.875-.87a1 1 0 0 0-1.41 0l-.005.005a.995.995 0 0 0-.005 1.408c.002 0 .003.002.004.003l1.584 1.58a1 1 0 0 0 1.413-.002l4.59-4.59a1 1 0 0 0 .002-1.411Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .form-choices {
    grid-column: 1 / span 2;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  .form-footer {
    display: grid;
    margin-top: 2rem;
    gap: 1.6rem;
  }

  .footer {
    align-items: center;

    svg {
      max-width: 23.4rem;
    }
  }

  .parallax-element {
    // z-index: 0;
    top: -4rem;
    right: -18rem;

    > div {
      --size: 28.9rem;
      width: var(--size);
      height: var(--size);
      background: var(--color-professor2);
      border-radius: 9in;
    }
  }

  @media (min-width: $lg) {
    padding-top: 12rem;
    padding-bottom: 14rem;
    --container-width: min(80%, 112rem);

    .parallax-element {
      // z-index: 0;
      top: -11rem;
      right: -17rem;

      > div {
        --size: 33.7rem;
      }
    }
  }

  @media (min-width: 960px) {
    // --container-width: min(100%, 144rem);
    --container-width: min(80%, 112rem);
    --container-left-padding: clamp(2rem, 2vw, 15rem);
    --container-right-padding: clamp(2rem, 2vw, 15rem);
    padding-top: 22rem;
    padding-bottom: 24rem;

    border-top-left-radius: 30rem;

    // grid-template-columns: auto max(35rem, 50%);
    // grid-template-columns: repeat(2, min(55rem, 50%));

    .form,
    .content {
      flex: 1 0 min(55rem, 50%);
    }

    .wrapper {
      flex-direction: row;
      gap: 2rem;
    }

    gap: 1.6rem;

    .form {
      gap: 1.7rem;
    }
    .form-fields {
      grid-template-columns: repeat(2, 1fr);
    }
    .form-title {
      grid-column: 1 / span 2;
    }

    .form-footer {
      grid-template-columns: auto 15.7rem;
    }

    .footer {
      align-items: start;
      margin-top: 2rem;
      grid-column: 1 / span 2;
      svg {
        max-width: 41.6rem;
      }
    }

    [data-view="home"] &::before {
      content: "";
      position: absolute;
      display: block;

      inset: 0;
      background: var(--bg-section-alt);
      z-index: -1;
    }

    .form-choices {
      margin-bottom: 3rem;
    }

    [data-view="index"] & {
      padding-bottom: 10rem;
      .footer {
        width: 50%;
        transform: translateY(-100%);
        margin-top: -2rem;
      }
      // opacity: 0.4;
    }
  }
}
