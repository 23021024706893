.cutom-checkbox {
  --transition: 0.15s ease-in-out;
  // opacity:.5;

  [type="checkbox"] {
    display: none;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem 0 1.2rem;
    white-space: nowrap;
    height: 4rem;
    border-radius: 0.6rem;

    background-color: rgba(#4aadf8, 0.3);
    transition: background-color var(--transition);

    font-size: 1.4rem;
    line-height: 2.4rem;

    span {
      padding-top: 0.4rem;
      display: block;
    }

    svg {
      color: var(--color-blue);
      opacity: 0.3;
      transition: color var(--transition), opacity var(--transition);
    }
  }

  [type="checkbox"]:checked + div {
    background-color: #4aadf8;
    font-weight: 700;

    svg {
      color: #fff;
      opacity: 1;
    }
  }
}
