:root {
  ///
  /// Tokens

  // Colors
  --color-professor1: #1e1348;
  --color-professor2: #5c4d9b;
  --color-professor3: #e7daed;
  --color-professor4: #b2a4d2;

  --color-red: #ff604f;
  --color-orange: #f29045;
  --color-blue: #4aadf8;
  --color-green: #44c887;
  --color-yellow: #fdb71a;

  --color-white: #fff;
  --color-dark: #000;

  --bg-section: var(--color-white);
  --bg-section-alt: #f5f7fa;
  // --bg-section: #0f0;
  // --bg-section-alt: #f00;

  // Font family
  --font-family-base: "campton", sans-serif;

  --font-size-large: 1.8rem;
  --font-size-base: 1.8rem;
  --font-size-small: 1.6rem;
  --line-height-base: 2.4rem;

  // Font weight
  --font-weight-regular: 300;
  --font-weight-bold: 700;
  --font-weight-accent: 700;

  // z-index
  --z-layout: 100;
  --z-modal: 1000;
  --z-debug: 10000;

  // transition
  --duration-hover: 0.3s;

  --slider-transition: 0.3s ease-in-out;

  ///
  ///
  ///
  ///
  ///
  ///
  ///
  ///
  /// Layout
  --padding-inline: 2rem;

  --header-height: 5.8rem;
  --flow-space: 1.6rem;
  // --container-width: min(90%, 1rem);
  // --container-inline: max(5vw, 50vw - 48rem);
  // --container-block: max(10vh, 12.8rem);
  // --container-inline: 50vw - 48rem;
  // --container-inline: 5vw;

  @media (min-width: $lg) {
    --padding-inline: max(5vw, 50vw - 48rem);
  }
}
