html {
  font-size: 62.5%;
  scroll-padding-top: 15rem;
  height: 100%;
  text-rendering: optimizelegibility;
}

body {
  height: auto;
  min-height: 100%;
  height: 100%;
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--color-dark);
  background: var(--color-white);
  overflow-x: hidden;
  letter-spacing: 0.01em;

  &.no-scroll {
    overflow: hidden;
  }
}

body,
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#viewport {
  z-index: 1;
}

main {
  background: var(--color-white);
  position: relative;
  width: 100vw;

  top: 0;
  left: 0;
  z-index: 0;

  --brand: var(--color-professor2);

  &[data-page="llengua-catalana"] {
    --brand: var(--color-red);
    --brand-light: #f7bcbe;
  }
  &[data-page="lengua-castellana"] {
    --brand: var(--color-orange);
    --brand-light: #fbcdac;
  }
  &[data-page="ciencies-socials"] {
    --brand: var(--color-yellow);
    --brand-light: #ffdd7f;
  }
  &[data-page="tecnologia"] {
    --brand: var(--color-blue);
    --brand-light: #d8e5ff;
  }
}

a {
  color: var(--color-blue);
  text-decoration: none;

  &:hover,
  &:focus-visible,
  &[aria-current] {
    text-decoration: underline;
  }
}

i {
  font-style: italic;
}

.h1,
.h2,
.h3,
.sub1,
.sub2,
.pretitle,
.lead,
.product-name {
  font-size: var(--font-size, 4.8rem);
  line-height: var(--line-height, 5.2rem);
  font-weight: var(--font-weight, 700);
  font-weight: var(--font-weight, 700);
  letter-spacing: var(--letter-spacing, 0.01);
}

.h3 {
  --font-size: 3.2rem;
  --line-height: 3.2rem;
}

.sub1 {
  --font-size: 3rem;
  --line-height: 3.6rem;
  --font-weight: 500;
}
.sub2 {
  --font-size: 2.6rem;
  --line-height: 3.2rem;
}

.pretitle {
  --font-size: 1.8rem;
  --line-height: 2.2rem;
  --font-weight: 700;
  --letter-spacing: 0.05em;
  font-style: italic;
  color: var(--color-blue);
}

.lead {
  --font-size: 1.8rem;
  --line-height: 2.4rem;
  --font-weight: 600;
}

.product-name {
  --font-size: 1.6rem;
  --line-height: 3.6rem;
}

@media (min-width: 768px) {
  .h1 {
    --font-size: 11rem;
    --line-height: 10rem;
  }

  .h2 {
    --font-size: 7rem;
    --line-height: 7.2rem;
  }

  .h3 {
    --font-size: 5.2rem;
    --line-height: 5rem;
  }

  .sub1 {
    --font-size: 3.6rem;
    --line-height: 4rem;
  }
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

.vertical-flow > * + * {
  margin-top: var(--flow-space, 1.6rem);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

///

@media (max-width: 767.99px) {
  .lg {
    display: none !important;
  }
}
@media (max-width: 959.99px) {
  .xl {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .xs {
    display: none !important;
  }
}

:where(.row) {
  display: flex;
}

:where(.stack) {
  display: flex;
  flex-direction: column;
  justify-content: var(--justify, flex-start);
  align-items: var(--align, center);
}

body {
  background: var(--bg-section);

  &:not([data-view="home"]) {
    .app-footer {
      z-index: 1;
    }
    // background: linear-gradient(to top, #f00, #0f0) center bottom 200vh;
  }
}
