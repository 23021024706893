.page-materials-floor {
  .products {
    display: grid;
    gap: 12rem;
    padding-top: 5rem;
    padding-bottom: 8rem;
    position: relative;
    z-index: 1;
  }

  @media (min-width: $lg) {
    padding-bottom: 9.7rem;

    .products {
      --container-width: 114rem;
      gap: 15.4rem;
    }
  }
}
