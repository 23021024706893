.page-tallers-floor {
  --height: 100vh;
  --slide1-color: var(--color-yellow);
  --slide2-color: var(--color-blue);
  --slide3-color: var(--color-green);
  --slide4-color: var(--color-red);

  // height: var(--height);
  position: relative;
  z-index: 3;
  padding-bottom: 9.1rem;

  .pin-space {
    // height: var(--height);
    position: relative;
  }

  .pin-element {
    // position: absolute;
    left: 0;
    display: flex;
    place-content: center;
    // min-height: 100vh;

    > div {
      // position: absolute;
      width: 100vw;
      height: 100vh;
      inset: 0;
      display: grid;
      padding-top: 8rem;
      grid-template-rows: min-content min-content min-content auto;

      background: var(--bg-section);

      border-bottom-right-radius: 10rem;
      z-index: 1;
      isolation: isolate;
      place-content: center;
    }

    .content {
      display: grid;
    }
  }

  .pin-space[data-pinned="top"] .pin-element {
    position: absolute;
    top: 0;
  }
  .pin-space[data-pinned="center"] .pin-element {
    position: fixed;
    top: 0;
  }
  .pin-space[data-pinned="bottom"] .pin-element {
    position: absolute;
    bottom: 0;
  }

  .slides {
    position: relative;
    display: grid;
    // height: 100vh;
  }

  .slide {
    // position: absolute;
    flex: 0 0 100vw;
    grid-column: 1;
    grid-row: 1;
    // height: 100vh;
    // display: flex;
    // flex-direction: column;
    // gap: 1.5rem;
    // padding: 1rem 2rem 0;
  }

  .bullets {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    // position: absolute;
    bottom: 1rem;
    padding: 2rem;
    margin-top: 2.4rem;
    // left: 50%;
    // transform: translateX(-50%);

    li {
      --size: 1.8rem;
      width: var(--size);
      height: var(--size);
      border-radius: 9in;
      cursor: pointer;
    }
  }

  // .slides li ,
  .bullets li {
    &:nth-child(1) {
      background: var(--color-red);
    }
    &:nth-child(2) {
      background: var(--color-orange);
    }
    &:nth-child(3) {
      background: var(--color-blue);
    }
    &:nth-child(4) {
      background: var(--color-red);
    }
  }

  --slide-transition: 0.3s ease-in-out;

  .slide,
  .num {
    opacity: 0;
    transition: opacity var(--slide-transition);
  }

  .bullets li {
    transition: width var(--slide-transition);
  }
  .nums {
    transition: color var(--slide-transition);
  }

  &[data-slide="1"] {
    .slide:nth-child(1),
    .num:nth-child(1) {
      opacity: 1;
    }
    .bullets li:nth-child(1) {
      width: 8rem;
    }
    .nums {
      color: var(--slide1-color);
    }
  }

  &[data-slide="2"] {
    .slide:nth-child(2),
    .num:nth-child(2) {
      opacity: 1;
    }
    .bullets li:nth-child(2) {
      width: 8rem;
    }
    .nums {
      color: var(--slide2-color);
    }
  }

  &[data-slide="3"] {
    .slide:nth-child(3),
    .num:nth-child(3) {
      opacity: 1;
    }
    .bullets li:nth-child(3) {
      width: 8rem;
    }
    .nums {
      color: var(--slide3-color);
    }
  }

  .pas-a-pas {
    margin-left: auto;
    margin-right: auto;
    max-width: 22.4rem;
    position: relative;
    z-index: 2;
  }

  // .slider {
  //   position: relative;
  // }

  @media (min-width: $lg) {
    .bullets {
      position: absolute;
      top: 70rem;
      left: 50%;
    }

    .pas-a-pas {
      max-width: 45rem;
      margin-top: 3.9rem;
    }
  }

  @media (min-width: 960px) {
    padding-bottom: 6rem;

    .bullets {
      top: 55rem;
    }
  }
}

.taller-slide {
  --inline-padding: 1rem;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3.8rem;
  display: grid;
  gap: 4rem;

  .taller-slide-media {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 18.3rem;
      filter: drop-shadow(var(--media-shadow));
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 37.5rem;
      height: 28.9rem;
      top: 6.5rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      background: no-repeat center / contain;
      background-image: var(--shape);
    }
  }

  .taller-slide-content {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
  }

  &[data-variant="red"] {
    --media-shadow: 0 2rem 4rem rgba(255, 96, 79, 0.4);
    --shape: url("data:image/svg+xml,%3Csvg width='468' height='484' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.514 68.674c-.84-11.993 8.104-22.44 20.083-23.458l356.953-30.35c12.118-1.03 22.773 7.967 23.788 20.086l33.56 400.937c1.112 13.284-9.75 24.496-23.062 23.806l-365.89-18.97c-11.102-.575-20.03-9.343-20.806-20.433L16.514 68.674Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }
  &[data-variant="orange"] {
    --media-shadow: 0 2rem 4rem rgba(247, 186, 138, 0.4);
    --shape: url("data:image/svg+xml,%3Csvg width='468' height='484' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.683 71.38c-.358-13.562 11.526-24.213 24.968-22.378l328.254 44.822c11.143 1.522 19.345 11.202 19.014 22.444l-9.81 334.007c-.342 11.64-9.694 20.997-21.333 21.345l-308.208 9.22c-12.175.364-22.329-9.234-22.65-21.41L47.683 71.38Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }
  &[data-variant="blue"] {
    --media-shadow: 0 2rem 4rem rgba(162, 189, 243, 0.4);
    --shape: url("data:image/svg+xml,%3Csvg width='468' height='484' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.088 443.244c-12.628 1.368-23.787-8.21-24.349-20.899L7.921 87.5c-.493-11.147 7.437-20.899 18.451-22.688l340.301-55.27c11.803-1.918 22.977 5.944 25.158 17.702l64.533 347.846c2.335 12.589-6.531 24.505-19.261 25.885L47.088 443.244Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }

  @media (min-width: $lg) {
    --container-width: 96rem;
    grid-template-columns: repeat(2, 1fr);

    .taller-slide-media {
      justify-content: flex-end;
      align-items: flex-start;
      padding-left: 7.8rem;

      img {
        max-width: 28.2rem;
      }

      &::before {
        width: 46.8rem;
        height: 48.4rem;
        top: -11rem;
        margin-left: -3rem;
      }
    }

    .taller-slide-content {
      padding-top: 2.4rem;
      font-size: 2.4rem;
      line-height: 3.8rem;
      text-align: left;
    }
  }
}
