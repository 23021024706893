.sequencia {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  &__label {
    margin: 0 auto;
    width: 29rem;
    overflow-x: hidden;

    &,
    > div {
      display: flex;
      height: 7.6rem;
      border-radius: 9in;
      justify-content: center;
      align-items: center;
    }

    > div {
      color: #fff;
      background: var(--brand);
      padding: 0 3.3rem;
      position: relative;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        inset: 0 -100vw;
        z-index: -1;
      }

      &::before {
        right: 50%;
        background: var(--brand-light);
      }
      &::after {
        left: 50%;
        background: #f5f7fa;
      }
    }
  }

  &[data-step="1"] &__label {
    justify-content: start;
  }
  &[data-step="3"] &__label {
    justify-content: end;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .media {
      img {
        border-radius: 1.6rem;
      }

      .video,
      .video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
    }
  }

  @media (min-width: $lg) {
    gap: 7rem;
    --container-width: min(100%, 108.2rem);
    // padding: 2rem;

    &__label {
      width: 49rem;
    }

    &__block {
      --media-width: 40vw;
      --content-width: 43.7rem;
      --offset-width: 0;

      display: grid;
      // display: flex;
      // flex-direction: row;
      place-content: center;
      grid-template-columns: var(--media-width) minmax(2rem, 7rem) var(--content-width) var(--offset-width);
      // grid-template-columns: 50.6rem  7rem 43.7rem  6.9rem;
      gap: 0;

      .media {
        grid-column: 1;
        grid-row: 1;
        img {
          border-radius: 2.2rem;
        }
      }

      .content {
        grid-column: 3;
        grid-row: 1;
        padding-top: 4.3rem;
      }

      &:nth-child(odd) {
        grid-template-columns: var(--content-width) minmax(2rem, 7rem) var(--media-width) var(--offset-width);
        .media {
          grid-column: 3;
        }
        .content {
          grid-column: 1;
        }
        // grid-template-columns: 43.7rem auto 50.6rem auto;
        // flex-direction: row-reverse;
      }
    }
  }

  @media (min-width: $xl) {
    &__block {
      --media-width: 50.6rem;
      --content-width: 43.7rem;
      --offset-width: minmax(0, 6.9rem);
    }
  }
}
