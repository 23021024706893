$xs: 767px;
$lg: 768px;
$xl: 960px;

@import "base/reset";
@import "base/tipo";
@import "base/root";
@import "base/base";

@import "atoms/btn";
// @import "atoms/highlight";
@import "atoms/container";
@import "atoms/media";

@import "components/app-header";
@import "components/app-footer";
@import "components/sequencies-header";
@import "components/cover";
@import "components/card";
@import "components/more-info";
@import "components/floor";
@import "components/head";
@import "components/product";
@import "components/products-list";
@import "components/numbered-list";
@import "components/slider";
@import "components/parallax-element";
@import "components/page-nav";
@import "components/sequencia";
@import "components/demos";
@import "components/collection";
@import "components/cutom-checkbox";

@import "floors/home-hero-floor";
@import "floors/home-itineraris-floor";
@import "floors/home-tallers-floor";
@import "floors/home-features-floor";
@import "floors/home-projects-floor";
@import "floors/home-sequencies-floor";
@import "floors/home-subjects-floor";

@import "floors/page-hero-floor";
@import "floors/page-metodologia-floor";
@import "floors/page-tallers-floor";
@import "floors/page-materials-floor";
@import "floors/page-facilitats-floor";
@import "floors/page-demos-floor";
@import "floors/page-project-floor";

@import "floors/index-hero-floor";
@import "floors/index-projects-floor";
@import "floors/index-video-floor";
@import "floors/index-testimonals-floor";

@import "floors/page-sequencies-hero-floor";
@import "floors/page-sequencies-metodologia-floor";
@import "floors/page-sequencies-intro-floor";
@import "floors/page-sequencies-facilitats-floor";

@import "floors/root-floor";

#loader {
  position: fixed;
  inset: 0;
  background: var(--clr-accent);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
  transition: opacity 300ms;

  &::before {
    content: "";
    display: flex;
    width: 3vmin;
    height: 3vmin;
    background: var(--clr-dark);
    border-radius: 50%;

    animation: loader-pulse 1000ms linear infinite;
  }
}

@keyframes loader-pulse {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  20% {
    transform: scale(0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

body:not(.loaded) {
  #loader {
    opacity: 1;
  }
}
