.page-nav {
  --transition: 0.2s ease-in-out;
  position: fixed;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: var(--z-layout);
  // filter: drop-shadow(0 0 .4rem #0003);

  --bg: var(--color-white);
  --color: var(--color-professor1);

  li {
    --bullet-width: 0.6rem;
    --bullet-height: var(--bullet-width);

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    cursor: pointer;

    transition: all var(--transition);

    &::after {
      content: "";
      display: block;
      width: var(--bullet-width);
      height: var(--bullet-height);
      border-radius: 9in;

      background: var(--bg);
      transition: all var(--transition);
      // box-shadow: 0 0 0 0.1rem var(--color-professor1);
    }

    span {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
      height: 2.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.8rem;
      border-radius: 0.6rem;
      background: var(--bg);
      font-size: 1.4rem;
      line-height: 1;
      font-weight: 600;
      color: var(--color);
      pointer-events: none;

      opacity: 0;
      transition: opacity var(--transition), color var(--transition), background-color var(--transition);
    }

    &.active {
      --bullet-height: 2.5rem;
    }

    &:hover,
    &.active {
      span {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &[data-variant="dark"] {
    --bg: var(--color-professor1);
    --color: var(--color-white);
  }
}
