.page-sequencies-metodologia-floor {
  margin-bottom: 5.6rem;
  .head {
    margin-bottom: 1rem;
  }
  .sequencia + .sequencia {
    margin-top: 5rem;
  }

  @media (min-width: $lg) {
    margin-bottom: 19.6rem;
    .head {
      margin-bottom: 2.4rem;
    }
    .sequencia + .sequencia {
      margin-top: 14.4rem;
    }
  }
}
