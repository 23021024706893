.home-features-floor {
  --height: 300vh;
  --slide1-color: var(--color-yellow);
  --slide2-color: var(--color-blue);
  --slide3-color: var(--color-green);
  --slide4-color: var(--color-red);

  height: var(--height);
  position: relative;
  z-index: 3;

  .parallax-element {
    z-index: -1;
  }

  .pin-space {
    height: var(--height);
    position: relative;
  }

  .pin-element {
    position: absolute;
    left: 0;
    display: flex;
    place-content: center;
    min-height: 100vh;

    > div {
      // position: absolute;
      width: 100vw;
      height: 100vh;
      inset: 0;
      display: grid;
      padding-top: 8rem;
      grid-template-rows: min-content min-content min-content auto;

      background: var(--bg-section);

      border-bottom-right-radius: 10rem;
      z-index: 1;
      isolation: isolate;
    }

    .content {
      display: grid;
    }

    [data-view="home"] &::before {
      content: "";
      position: absolute;
      display: block;

      inset: 0;
      background: var(--bg-section-alt);
      z-index: -10;
      // transform: translate3d(0, 0, -10);
    }
  }

  .pin-space[data-pinned="top"] .pin-element {
    position: absolute;
    top: 0;
  }
  .pin-space[data-pinned="center"] .pin-element {
    position: fixed;
    top: 0;
  }
  .pin-space[data-pinned="bottom"] .pin-element {
    position: absolute;
    bottom: 0;
  }

  .head {
    // position: absolute;
    // display: none;
  }

  .nums {
    position: relative;
    display: grid;
    gap: 1.5rem;
    padding: 0 2rem;
    margin-bottom: 3rem;

    width: 20rem;
    font-size: 8.6rem;
    line-height: 1;
    font-weight: 700;

    svg {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .num {
    position: relative;
    z-index: 1;
    grid-column: 1;
    grid-row: 1;
    padding-left: 7rem;
    padding: 10rem 7rem 6rem 6rem;
    color: var(--color-white);
  }

  .slides {
    position: relative;
    display: grid;
    // height: 100vh;
  }

  .slide {
    // position: absolute;
    flex: 0 0 100vw;
    grid-column: 1;
    grid-row: 1;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 2rem;
  }

  .bullets {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    // position: absolute;
    bottom: 1rem;
    padding: 2rem;
    margin-top: 2.4rem;
    // left: 50%;
    // transform: translateX(-50%);

    li {
      --size: 1.8rem;
      width: var(--size);
      height: var(--size);
      border-radius: 9in;
    }
  }

  // .slides li ,
  .bullets li {
    &:nth-child(1) {
      background: var(--slide1-color);
    }
    &:nth-child(2) {
      background: var(--slide2-color);
    }
    &:nth-child(3) {
      background: var(--slide3-color);
    }
    &:nth-child(4) {
      background: var(--slide4-color);
    }
  }

  --slide-transition: 0.3s ease-in-out;

  .slide,
  .num {
    opacity: 0;
    transition: opacity var(--slide-transition);
  }

  .bullets li {
    transition: width var(--slide-transition);
  }
  .nums {
    transition: color var(--slide-transition);
  }

  &[data-slide="1"] {
    .slide:nth-child(1),
    .num:nth-child(1) {
      opacity: 1;
    }
    .bullets li:nth-child(1) {
      width: 8rem;
    }
    .nums {
      color: var(--slide1-color);
    }
  }

  &[data-slide="2"] {
    .slide:nth-child(2),
    .num:nth-child(2) {
      opacity: 1;
    }
    .bullets li:nth-child(2) {
      width: 8rem;
    }
    .nums {
      color: var(--slide2-color);
    }
  }

  &[data-slide="3"] {
    .slide:nth-child(3),
    .num:nth-child(3) {
      opacity: 1;
    }
    .bullets li:nth-child(3) {
      width: 8rem;
    }
    .nums {
      color: var(--slide3-color);
    }
  }

  &[data-slide="4"] {
    .slide:nth-child(4),
    .num:nth-child(4) {
      opacity: 1;
    }
    .bullets li:nth-child(4) {
      width: 8rem;
    }
    .nums {
      color: var(--slide4-color);
    }
  }

  &[data-variant="itineraris"] {
    .nums {
      svg {
        // right: -25rem;
        // bottom: -6rem;
        bottom: calc(50% - 11.5rem);
        // transform: scale(0.6);
      }
    }
  }
  &[data-variant="tot-tallers"] {
    .nums {
      svg {
        right: -22rem;
        // bottom: -6rem;
        bottom: calc(50% - 21rem);
        transform: scale(0.6);
      }
    }
  }

  @media (min-width: $lg) {
    .pin-element {
      > div {
        --container-left-padding: clamp(2rem, 2vw, 17rem);
        --container-right-padding: clamp(2rem, 2vw, 17rem);
        --container-width: 103rem;
        border-bottom-right-radius: 30rem;
        grid-template-columns: 25rem auto;
        grid-template-rows: auto min-content min-content min-content min-content auto;
      }
    }

    .head {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    .nums {
      grid-column: 1;
      grid-row: 2 / span 3;
      align-self: center;

      font-size: 17.8rem;
      margin-left: -10vw;

      svg {
        width: 136rem;
        height: 97rem;
        right: -8rem;

        bottom: -6rem;
      }
    }

    .slides {
      grid-column: 2;
      grid-row: 3 / span 2;
      height: min-content;
    }

    .bullets {
      grid-column: 2;
      grid-row: 2;
      justify-self: start;
      margin: 0;
      height: min-content;
    }

    &[data-variant="itineraris"] {
      .nums {
        transform: translateX(10rem);
        svg {
          // right: -25rem;
          // bottom: -6rem;
          bottom: calc(50% - 21rem);
          // transform: scale(0.6);
        }
      }
    }

    &[data-variant="tot-tallers"] {
      .nums {
        transform: translateX(3rem);
        svg {
          position: absolute;
          right: -25rem;
          bottom: calc(-50% - 10rem);
          transform: scale(0.8);
        }
      }
    }
  }

  ///
  ///

  &[data-variant="index"] {
    --slide1-color: #5d4d9b;
    --slide2-color: #eb61a6;
    --slide3-color: #f29045;
    --slide4-color: #2cad6e;

    .pin-element > div {
      border-radius: 0;
      background: #f5f7fa;
      // background: #f00;
    }

    .slide {
      padding: 0;
      p {
        font-weight: bold;
      }
    }

    // .content {
    //   margin-top: 10px;
    // }

    .content {
      display: flex;
      flex-direction: column;
      width: calc(100vw - 4rem);
    }

    .nums {
      display: flex;
      padding: 10rem 0 0;
      width: calc(100vw - 4rem);
      height: 20rem;
      text-align: center;
      justify-content: center;
      margin-bottom: 6rem;

      .num {
        position: absolute;
        padding: 0;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: -10rem 50% 8.1rem;
        background: currentColor;
        // height: 10px;
        width: 200vw;
        transform: translate(-50%, 0);
      }

      svg.xs {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }

    @media (min-width: $lg) {
      overflow: hidden;
      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: auto 0 0;
        height: 5rem;
        border-radius: 5rem 5rem 0 0;
        background: #fff;
        z-index: 10;
      }

      .content {
        flex: 0 0 70%;
        justify-content: flex-start;
      }

      .container-fluid {
        --container-width: 112.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .nums {
        padding: 0;
        margin-right: 5rem;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 6rem;
        padding-top: 2.5rem;

        &::before {
          width: 200%;
          height: 200vh;
          inset: -50vh 0 0 0;
          transform: translate(calc(-50% - 10.8rem), 0);
        }

        svg.lg {
          width: auto;
          height: auto;
          inset: 50% 0 auto auto;
          transform: translate(0, -50%);
        }
      }

      .bullets {
        order: -1;
        justify-content: flex-start;
        margin-bottom: 1rem;
        //   position: absolute;
        //   top: 50%;
        //   margin-top: -20rem;;
      }
    }
  }
}
