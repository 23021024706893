.page-metodologia-floor {
  --item-width: 34rem;
  --item-gap: calc(47.4rem - var(--item-width));
  --item-offset: calc(100vw - var(--item-width));
  --media-width: 202rem;
  --media-height: 41rem;
  --slider-transition: 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  // --slider-transition: 1s ease-in-out;

  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  overflow: hidden;

  .slider {
    position: relative;
    // display: grid;
    // grid-auto-rows: minmax(min-content, max-content);
    display: flex;
    flex-direction: column;

    // gap: 3.6rem;

    // height: max(100vh - 6rem, 70rem);
    height: 78rem;

    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
  }

  .scroll-wrap {
    width: 100vw;
  }

  .scroller {
    position: relative;
    scroll-snap-type: x mandatory;
    display: flex;
    gap: var(--item-gap);

    height: calc(100% + 2rem);

    padding-top: calc(var(--media-height) + 5rem);
    padding-bottom: 2rem;
    padding-left: calc(var(--item-offset) / 2);
    padding-right: calc(var(--item-offset) / 2);
  }

  .carousel {
    // background: #f003;
    overflow: hidden;
    position: absolute;
    inset: 0 0 auto;
    height: var(--media-height);
    width: calc(var(--item-width) * 3 + var(--item-gap) * 2 + var(--item-offset));
    // width: calc(var(--item-width) * 3 + var(--item-gap) * 2);

    .media {
      position: absolute;
      top: 0;
      bottom: 0;
      width: var(--media-width);
      height: var(--media-height);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .slide {
    flex: 0 0 var(--item-width);

    // --padding: 2rem;
    // padding-left: var(--padding);
    // padding-right: var(--padding);

    // > div {
    scroll-snap-align: center;
    width: min(100%, 33.5rem);
    grid-auto-rows: minmax(min-content, max-content);
    display: grid;
    gap: 0.6rem;
    align-items: center;
    text-align: center;
    // }
  }

  .nav {
    display: flex;
    gap: 3rem;
    margin-top: auto;

    justify-content: center;
  }

  @media (min-width: $lg) {
    --media-width: 164.2rem;
    --media-height: 62rem;

    [data-ref-group="badges"] {
      cursor: pointer;
    }

    .slider {
      position: relative;
      height: auto;
      padding-top: 0;
    }
    .carousel {
      width: 100%;
    }

    .scroller {
      overflow: hidden;
      height: var(--media-height);
      padding: 0;
      display: grid;
      justify-content: center;
      padding-top: 40rem;
    }

    .slide {
      grid-column: 1;
      grid-row: 1;
      width: 48.8rem;
    }

    .slide {
      opacity: 0;
      transition: opacity var(--slider-transition);
    }

    .slide1-badge,
    .slide2-badge,
    .slide3-badge {
      transform: translate(var(--translate, 0, 0)) scale(0.62);
      transition: transform var(--slider-transition);
    }

    .circle {
      transition: all var(--slider-transition);
      stroke-dasharray: 0, 2000;
      stroke-dashoffset: -240;
    }

    .slide1-badge {
      --translate: 13.7rem, 14.5rem;
    }
    .slide2-badge {
      --translate: 31.15rem, 10.25rem;
    }
    .slide3-badge {
      --translate: 48rem, 14.5rem;
    }

    .slider[data-slide="1"] {
      .slide:nth-child(1) {
        opacity: 1;
      }
      .slide1-badge {
        transform: none;
        .circle {
          stroke-dasharray: 120, 2000;
          stroke-dashoffset: -705;
        }
      }
    }
    .slider[data-slide="2"] {
      .slide:nth-child(2) {
        opacity: 1;
      }
      .slide2-badge {
        transform: none;
        .circle {
          stroke-dasharray: 120, 2000;
          stroke-dashoffset: -557;
        }
      }
    }
    .slider[data-slide="3"] {
      .slide:nth-child(3) {
        opacity: 1;
      }
      .slide3-badge {
        transform: none;
        .circle {
          stroke-dasharray: 120, 2000;
          stroke-dashoffset: -660;
        }
      }
    }

    .nav {
      display: flex;
      gap: 3rem;
      margin-top: auto;

      justify-content: center;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15rem;
      gap: calc(48.8rem + 5.3rem * 2);
    }
  }
}
