.app-header {
  --nav-gap: 2.3rem;
  --nav-item-padding: 2.3rem;

  height: 7.2rem;
  position: fixed;
  inset: 0.8rem 0.8rem auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(1.9rem - var(--nav-item-padding)) 0 2.2rem;
  background: var(--color-professor1);
  border-radius: 1.9rem;
  z-index: var(--z-layout);
  font-size: 1.4rem;
  line-height: 1;

  > a {
    display: block;
    // max-width: 10.2rem;

    > svg {
      height: 65%;
      width: 65%;
    }
  }

  nav {
    display: flex;
    gap: calc(var(--nav-gap) - var(--nav-item-padding) * 2);

    > a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem var(--nav-item-padding) 0;
      height: 3rem;
      color: var(--color-white);
      border-radius: 9in;
      text-decoration: none;
      font-weight: 600;

      &[aria-current="page"] {
        background: var(--color-professor2);
        font-weight: 700;

        &:before {
          --size: 0.6rem;
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          width: var(--size);
          height: var(--size);
          transform: translate(-50%, -50%);
          background: var(--color-white);
          border-radius: 9in;
        }
      }
    }
  }

  @media (min-width: $lg) {
    --nav-gap: 4rem;
    --nav-item-padding: 1.7rem;

    inset: 1.6rem 1.6rem auto;
    height: 8rem;
    padding: 0 calc(3.5rem - var(--nav-item-padding)) 0 3.5rem;

    > a > svg {
      width: auto;
      height: auto;
    }

    nav {
      gap: 2.2rem;
      > a {
        max-width: 15.4rem;
      }
    }
  }
}
