.home-tallers-floor {
  position: relative;
  display: grid;
  gap: 8.9rem;
  z-index: 10;
  padding-top: 1.4rem;
  padding-bottom: 2rem;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 37.5rem;
    height: 84.4rem;
    bottom: -6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -2;
    background: no-repeat center / contain;
    background-image: url("data:image/svg+xml,%3Csvg width='375' height='844' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M398.003 8.816c11.546.235 20.944 9.358 21.523 20.891l39.734 790.954c.668 13.294-10.531 24.132-23.796 23.028l-517.919-43.088c-12.278-1.022-21.323-11.925-20.06-24.18l77.937-756.034C-23.404 8.998-13.692.414-2.245.647l400.248 8.169Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }

  // &:before

  .content {
    display: grid;
    gap: 1rem;
    text-align: center;
    --inline-padding: 0;

    .h2 {
      --letter-spacing: 0;
    }
    p {
      padding: 0 2rem;
    }
  }

  .tallers {
    display: grid;
    gap: 5.3rem;
    justify-content: center;
    align-content: center;
  }



  @media (min-width: 926px) {
    padding-top: 23.8rem;
    gap: 6.3rem;

    .content {
      display: grid;
      gap: 2.3rem;
      text-align: center;
      --container-width: 89.8rem;

      .h2 {
      }
      p {
        padding: 0;
        margin: 0 auto;
        max-width: 66.7rem;
      }
    }

    &::before {
      width: 82.2rem;
      height: 77.4rem;
      bottom: -12rem;
      background-image: url("data:image/svg+xml,%3Csvg width='822' height='774' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M793.383 1.037c15.475-4.223 30.122 9.003 27.495 24.828L699.821 754.998c-1.876 11.301-12.111 19.271-23.527 18.321l-564.216-46.94a22 22 0 0 1-19.767-17.704L.42 238.562c-2.21-11.31 4.682-22.41 15.8-25.444L793.383 1.038Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
    }

    .tallers {
      --container-width: 88.6rem;
      gap: 2.3rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }


  @media (min-width: 1151px) {
    .tallers {
      --container-width: 111.1rem;
    }
  }

}

.taller {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2rem 30px rgba(0, 0, 0, 0.08);
  border-radius: 2.2rem;
  background: #fff;
  padding: 3.6rem 1rem;
  width: 28rem;
  text-align: center;

  .title {
    margin-bottom: 0.7rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 700;
    color: var(--color);
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 600;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: center / 100% 100%;
    background-image: var(--shape);
    inset: var(--offset, -3rem);
    z-index: -1;
  }

  &[data-variant="red"] {
    --color: var(--color-red);
    --shape: url("data:image/svg+xml,%3Csvg width='340' height='211' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M217.751 11.724c10.449.482 19.113 8.253 20.725 18.587l15.057 96.564c1.93 12.379-6.852 23.868-19.303 25.254L42.533 173.48c-12.21 1.36-23.167-7.543-24.335-19.772L6.102 27.093C4.829 13.77 15.647 2.408 29.015 3.023l188.736 8.701Z' fill='%23FF604F'/%3E%3C/svg%3E");
  }
  &[data-variant="orange"] {
    --color: var(--color-orange);
    --shape: url("data:image/svg+xml,%3Csvg width='340' height='211' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M273.453 72.05c11.198 1.178 19.699 10.62 19.699 21.88v78.212c0 11.691-9.143 21.339-20.817 21.968L73.556 204.817c-12.799.689-23.475-9.666-23.177-22.48l2.547-109.56c.299-12.853 11.509-22.712 24.295-21.367l196.232 20.64Z' fill='%23F29045'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h340v211H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
  &[data-variant="blue"] {
    --color: var(--color-blue);
    --shape: url("data:image/svg+xml,%3Csvg width='340' height='211' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M307.978 46.75c14.134-2.05 26.444 9.7 25.054 23.914l-11.765 120.252c-1.152 11.774-11.409 20.529-23.218 19.818l-194.778-11.729c-10.557-.636-19.167-8.693-20.502-19.185l-9.81-77.139c-1.515-11.904 6.79-22.826 18.665-24.548L307.978 46.75Z' fill='%234AADF8'/%3E%3C/svg%3E");
  }

  @media (min-width: 1151px) {
    --offset: -5rem;
    width: 35.5rem;
    padding: 7.3rem 1rem 5.9rem;

    .title {
      margin-bottom: 1.1rem;
      font-size: 2.6rem;
      line-height: 3.2rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 600;
      margin-top: 0.5rem;
    }
  }
}
