.home-sequencies-floor {
  display: grid;
  gap: 2.5rem;
  text-align: center;
  padding-bottom: 4.4rem;
  background: #f5f7fa;

  &::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    z-index: -1;

    background: #fff;
    border-radius: 0 0 10rem 0;
  }

  .intro {
    display: grid;
    gap: 1rem;

    .title {
      margin: 0 -0.5rem;
      font-size: 4.8rem;
      line-height: 5.2rem;
      font-weight: 700;
    }

    .lead {
      --font-weight: 400;
    }
  }

  .items {
    position: relative;
    display: grid;
    gap: 1.2rem;
  }

  .item {
    height: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9in;
    background: #b2a4d2;
    flex-direction: column;
    padding: 0 2rem;
    max-width: 89.6rem;

    .sub2 {
      color: #fff;
    }
  }

  .parallax1 {
    top: -3rem;
    left: -4rem;
    > div {
      width: 22.9rem;
      height: 9.7rem;
      background-image: url("data:image/svg+xml,%3Csvg width='229' height='97' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='11' width='229' height='86' rx='43' fill='%235D4D9B'/%3E%3Ccircle cx='59' cy='11' r='11' fill='%23FDB71A'/%3E%3C/svg%3E");
    }
  }
  .parallax2,
  .parallax3,
  .parallax4 {
    > div {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .parallax2 {
    right: -1rem;
    top: 50%;
    z-index: 2;

    > div {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23FF604F'/%3E%3C/svg%3E");
    }
  }

  .parallax3 {
    right: -3rem;
    top: calc(50% + 9rem);

    > div {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23F29045'/%3E%3C/svg%3E");
    }
  }

  .parallax4 {
    left: -3rem;
    bottom: -1rem;
    > div {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23789FED'/%3E%3C/svg%3E");
    }
  }

  @media (min-width: $lg) {
    --container-width: 95rem;
    padding-top: 15.7rem;
    padding-bottom: 19.6rem;
    gap: 8.6rem;

    &::before {
      border-radius: 0 0 30rem 0;
    }

    .intro {
      gap: 2rem;
    }

    .items {
      gap: 3.1rem;
    }

    .item {
      height: 17.1rem;
      padding: 0 2rem;
      max-width: 89.6rem;
      padding: 0 calc((89.6rem - 40.5rem) / 2);

      .sub2 {
        font-size: 7rem;
        line-height: 7.2rem;
      }
    }

    .parallax1 {
      top: -4.5rem;
      left: -16rem;
      > div {
        width: 56.9rem;
        height: 12.5rem;
        background-image: url("data:image/svg+xml,%3Csvg width='569' height='125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='569' height='108' rx='54' fill='%235D4D9B'/%3E%3Ccircle cx='77.5' cy='107.5' r='17.5' fill='%23FDB71A'/%3E%3C/svg%3E");
      }
    }

    .parallax5 {
      top: calc(50% - 3.5rem);
      right: -15rem;
      > div {
        width: 56.9rem;
        height: 14.1rem;
        background-image: url("data:image/svg+xml,%3Csvg width='569' height='141' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='16' width='569' height='108' rx='54' fill='%23F5F7FA'/%3E%3Ccircle cx='417.5' cy='123.5' r='17.5' fill='%23F29045'/%3E%3Ccircle cx='499.5' cy='17.5' r='17.5' fill='%23FF604F'/%3E%3C/svg%3E");
      }
    }

    .parallax6 {
      bottom: -5rem;
      left: -65rem;
      > div {
        width: 58.3rem;
        height: 10.8rem;
        background-image: url("data:image/svg+xml,%3Csvg width='583' height='108' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='569' height='108' rx='54' fill='%23F5F7FA'/%3E%3Ccircle cx='565.5' cy='53.5' r='17.5' fill='%23789FED'/%3E%3C/svg%3E");
      }
    }
  }
}
