.cover {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 3.6rem;
  color: var(--color-white);
  min-height: 67.2rem;
  overflow: hidden;
  position: relative;
  justify-content: center;
  gap: 1.2rem;
  border-radius: 2.4rem;

  .background {
    border-radius: 2.4rem;
  }

  .head {
    --color: var(--color-white);
    --dot-color: var(--color-blue);
    position: absolute;
    inset: 0 0 auto;
  }

  .content {
    display: grid;
    gap: 1.2rem;
  }

  @media (min-width: $lg) {
    // padding: 5rem var(--padding-inline);

    --container-left-padding: min(10vw, 15rem);
    --container-right-padding: min(50vw, 15rem);

    .head {
      top: 1.2rem;
    }

    .content {
      gap: 0.5rem;
    }

    .h2 {
      margin-top: 2.1rem;
    }

    .lead {
      width: min(100%, 46rem);
    }

    // min-height: 100vh;
    // margin: 0;

    // .background {
    //   border-radius: 1rem 1rem 5rem 0;
    // }

    // .scroll {
    //   bottom: 6.4rem;
    // }
  }
}
