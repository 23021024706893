.page-sequencies-hero-floor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 27rem;
  padding-bottom: 5.6rem;
  color: var(--color-professor1);

  .media {
    height: 44rem;
    // bottom: auto;
    inset: 0 0 auto;
    // transform: translateX(-50%);

    img {
      left: 50%;
      transform: translateX(-50%);
      object-fit: fill;
      object-position: top center;

      height: 44rem;
      width: 82.3rem;
      max-width: none;
    }
  }

  .content {
    max-width: 80vw;
  }

  .h1,
  .sub1 {
    margin-top: 1.2rem;
  }

  @media (min-width: $lg) {
    --container-width: 113.8rem;
    // --container-width: 110rem;
    min-height: 70.6rem;
    padding-top: 18rem;
    padding-bottom: 5.6rem;

    .media {
      height: 78.7rem;
      // width: 144rem;

      img {
        // max-width: 144rem;
        // max-height: 70.6rem;
        // object-fit: 50%;
        height: 78.7rem;
        width: 172rem;
      }
    }

    .content {
      max-width: 80vw;
      width: min(100%, 66.6rem);
    }

    .h1 {
      margin-top: 2.9rem;
    }

    .sub1 {
      margin-top: 1.8rem;
    }
  }
}
