.product {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  // padding: var(--padding-inline);

  // font-size: 1.6rem;

  .media {
    margin: 0 auto;
    max-width: 27rem;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
    }

    // &::before {
    //   width: 95%;
    //   left: 2.5%;
    //   height: 17%;
    //   bottom: -8.5%;
    //   z-index: -1;
    //   background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    //   filter: blur(20px);
    //   z-index: 3;
    // }

    img {
      //   border-radius: 1.4rem;
      z-index: 4;
    }

    &::after {
      --offset: 30%;
      bottom: 0;
      right: var(--offset);
      width: 130%;
      height: 140%;
      z-index: -2;
      background: no-repeat center / contain;
    }

    .parallax-element {
      left: 0;
      top: -40%;
      transform: scale(0.35);

      z-index: -1;
    }
  }

  &:nth-child(1) .media::after {
    background-image: url("data:image/svg+xml,%3Csvg width='563' height='547' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M562.403 274.498C562.403 424.997 436.505 547 281.201 547 125.898 547 0 424.997 0 274.498 0 123.999 103.838-18.63 305.065 1.995 484.651 20.403 562.403 124 562.403 274.498Z' fill='%23FF604F'/%3E%3C/svg%3E");
  }
  &:nth-child(2) .media::after {
    background-image: url("data:image/svg+xml,%3Csvg width='566' height='497' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M177.439 458.687C36.847 395.699-34.341 252.154 16.928 137.722 68.196 23.289 246.612-37.608 387.204 25.38c140.592 62.988 232.31 184.214 143.357 360.047-76.479 124.62-212.53 136.248-353.122 73.26Z' fill='%2344C887'/%3E%3C/svg%3E");
  }
  &:nth-child(3) .media::after {
    background-image: url("data:image/svg+xml,%3Csvg width='551' height='485' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.095 216.493C.732 61.381 117.68-.521 282.993.158c126.393.52 268.444 97.072 267.862 238.645-.637 155.112-104.71 264.752-318.82 242.615C40.951 461.661-.542 371.604.095 216.493Z' fill='%23EB61A6'/%3E%3C/svg%3E");
  }
  &:nth-child(4) .media::after {
    background-image: url("data:image/svg+xml,%3Csvg width='597' height='490' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M595.515 269.556c-10.06 130.093-94.675 177.548-290.784 213.43C127.658 515.384-8.753 430.205 1.307 300.112c10.06-130.093 124.386-323.09 359.9-296.988 172.708 29.362 244.368 136.339 234.308 266.432Z' fill='%23FDB71A'/%3E%3C/svg%3E");
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .product-group {
    display: flex;
    flex-direction: column;

    img {
      width: 4.8rem;
    }
  }

  &[data-odd] {
    .content,
    .product-group {
      text-align: right;
      align-items: flex-end;
    }

    .media::after {
      left: var(--offset);
      right: auto;
    }

    .media .parallax-element {
      left: -65%;
    }
  }



  &[data-variant="tot-tallers"]:nth-child(1) .media::after {
    background-image: url("data:image/svg+xml,%3Csvg width='540' height='430' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.86 23.59C10.212 10.108 21.599-.438 35.068.244l457.642 23.178c12.145.615 21.91 10.215 22.732 22.347l24.233 357.769c.971 14.346-10.803 26.321-25.163 25.591L23.383 404.188C10.376 403.527.27 392.614.608 379.595L9.861 23.59Z' fill='%23FF604F'/%3E%3C/svg%3E");
  }
  &[data-variant="tot-tallers"]:nth-child(2) .media::after {
    background-image: url("data:image/svg+xml,%3Csvg width='530' height='503' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M529.481 52.417c.837-13.302-9.324-24.738-22.631-25.471L25.321.396C11.568-.362 0 10.586 0 24.36v402.314c0 12.207 9.163 22.469 21.292 23.847l454.632 51.631c13.641 1.549 25.799-8.638 26.661-22.339l26.896-427.396Z' fill='%2344C887'/%3E%3C/svg%3E");
  }
  &[data-variant="tot-tallers"]:nth-child(3) .media::after {
    background-image: url("data:image/svg+xml,%3Csvg width='523' height='562' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M460.336 561.812c12.919.663 24.039-9.033 25.142-21.922l37.204-434.652c1.037-12.114-7.15-23.09-19.057-25.55L122.402.938c-13.135-2.714-25.945 5.865-28.437 19.044L1.11 510.98c-2.703 14.292 7.827 27.683 22.353 28.428l436.873 22.404Z' fill='%23FBB800'/%3E%3C/svg%3E");
  }
  &[data-variant="tot-tallers"]:nth-child(4) .media::after {
    // background-image: url("data:image/svg+xml,%3Csvg width='597' height='490' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M595.515 269.556c-10.06 130.093-94.675 177.548-290.784 213.43C127.658 515.384-8.753 430.205 1.307 300.112c10.06-130.093 124.386-323.09 359.9-296.988 172.708 29.362 244.368 136.339 234.308 266.432Z' fill='%23FDB71A'/%3E%3C/svg%3E");
  }

  @media (min-width: $lg) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 7.5rem;

    .media {
      max-width: 50.6rem;

      &::after {
        --offset: 0;
        transform: scale(0.9);
      }

      .parallax-element {
        left: 33%;
        top: -30%;
        transform: scale(0.6);

        z-index: -1;
      }
    }

    .content {
      flex: 0 0 43rem;

      .h3 {
        margin-bottom: 0.4rem;
      }
    }

    .media-wrapper {
      width: min(100%, 50.6rem);
    }

    &[data-odd] {
      flex-direction: row-reverse;
      .content,
      .product-group {
        align-items: flex-start;
        text-align: left;
      }
    }

    &[data-variant="tot-tallers"] .media::after {
      bottom: -8rem;
    }
  }
}
