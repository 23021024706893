// @font-face {
//   font-family: "campton";
//   src: url("../fonts/campton/camptonbook-webfont.woff2") format("woff2"), url("../fonts/campton/camptonbook-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "campton";
//   src: url("../fonts/campton/camptonbold-webfont.woff2") format("woff2"), url("../fonts/campton/camptonbold-webfont.woff") format("woff");
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: "campton";
//   src: url("../fonts/campton/camptonbolditalic-webfont.woff2") format("woff2"), url("../fonts/campton/camptonbolditalic-webfont.woff") format("woff");
//   font-weight: 700;
//   font-style: italic;
// }

// @font-face {
//   font-family: "campton";
//   src: url("../fonts/campton/camptonmedium-webfont.woff2") format("woff2"), url("../fonts/campton/camptonmedium-webfont.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "campton";
//   src: url("../fonts/campton/camptonsemibold-webfont.woff2") format("woff2"), url("../fonts/campton/camptonsemibold-webfont.woff") format("woff");
//   font-weight: 600;
//   font-style: normal;
// }

@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  // src: url('https://www.textlomloe.cat/wp-content/uploads/2022/03/Campton-Bold.woff') format('woff');
  src: url("../fonts/campton2/Campton-Bold.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  // src: url('https://www.textlomloe.cat/wp-content/uploads/2022/03/Campton-Book.woff') format('woff');
  src: url("../fonts/campton2/Campton-Book.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: italic;
  font-weight: bold;
  font-display: auto;
  // src: url('https://www.textlomloe.cat/wp-content/uploads/2022/03/Campton-BoldItalic.woff') format('woff');
  src: url("../fonts/campton2/Campton-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: italic;
  font-weight: normal;
  font-display: auto;
  // src: url('https://www.textlomloe.cat/wp-content/uploads/2022/03/Campton-BookItalic.woff') format('woff');
  src: url("../fonts/campton2/Campton-BookItalic.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  // src: url('https://www.textlomloe.cat/wp-content/uploads/2022/03/Campton-SemiBold.woff') format('woff');
  src: url("../fonts/campton2/Campton-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  // src: url('https://www.textlomloe.cat/wp-content/uploads/2022/03/Campton-Medium.woff') format('woff');
  src: url("../fonts/campton2/Campton-Medium.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: italic;
  font-weight: 500;
  font-display: auto;
  // src: url('https://www.textlomloe.cat/wp-content/uploads/2022/03/Campton-MediumItalic.woff') format('woff');
  src: url("../fonts/campton2/Campton-MediumItalic.woff") format("woff");
}
