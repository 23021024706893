.more-info {
  --bg: var(--color-professor1);
  --bg-hover: #6856ae;
  --bg-active: #5d4d9b;

  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: var(--z-layout);
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  font-weight: 500;
  // font-size: 1.6rem;
  // line-height: 2.2rem;

  &.hidden {
    opacity: 0;
  }

  @media (max-width: $xs) {
    width: 4.8rem;
    padding: 0;
  }
}
