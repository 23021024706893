.slider {
  .scroll-wrap {
    overflow: hidden;
  }

  .scroller {
    overflow-y: hidden;
    overflow-x: auto;
  }

  .slider-nav {
    --size: 4.8rem;

    display: inline-flex;
    width: var(--size);
    height: var(--size);

    background: var(--color-white);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0.8rem 2.5rem -1rem rgba(0, 0, 0, 0.15);

    background: no-repeat center;
    border-radius: 9in;

    overflow: hidden;
    text-indent: 100%;

    --transition: 0.1s ease-in-out;
    transition: transform var(--transition), box-shadow var(--transition);

    &[data-dir="prev"] {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.248 6.352a1.2 1.2 0 0 1 0 1.697L11.297 12l3.951 3.952a1.2 1.2 0 0 1-1.696 1.696l-4.8-4.8a1.2 1.2 0 0 1 0-1.696l4.8-4.8a1.2 1.2 0 0 1 1.696 0Z' fill='%235D4D9B'/%3E%3C/svg%3E");
    }

    &[data-dir="next"] {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.752 17.648a1.2 1.2 0 0 1 0-1.697L12.703 12 8.752 8.048a1.2 1.2 0 0 1 1.696-1.697l4.8 4.8a1.2 1.2 0 0 1 0 1.697l-4.8 4.8a1.2 1.2 0 0 1-1.696 0Z' fill='%235D4D9B'/%3E%3C/svg%3E");
    }

    &:active {
      transform: translateY(0.1rem);
      box-shadow: 0 0.4rem 2.5rem -1rem rgba(0, 0, 0, 0.15);
    }
  }
}
