.page-hero-floor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 39rem;
  padding-bottom: 5.6rem;
  color: var(--color-professor1);

  .media {
    height: 54rem;
    // bottom: auto;
    inset: 0 0 auto;
    // transform: translateX(-50%);

    img {
      left: 50%;
      transform: translateX(-50%);
      object-fit: fill;
      object-position: top center;

      height: 54rem;
      width: 54rem;
      max-width: none;
    }
  }

  .content {
    max-width: 80vw;
  }

  .h1,
  .sub2 {
    margin-top: 1.2rem;
  }

  &[data-variant="sequencies"] {
    .media {
      height: 54rem;
      // bottom: auto;
      inset: 0 0 auto;
      // transform: translateX(-50%);

      img {
        left: 50%;
        transform: translateX(-50%);
        object-fit: fill;
        object-position: top center;

        height: 44rem;
        width: 82.3rem;
      }
    }
  }

  @media (min-width: $lg) {
    --container-width: 113.8rem;
    min-height: 70.6rem;
    padding-top: 18rem;
    padding-bottom: 5.6rem;

    .media {
      height: 70.6rem;
      // width: 144rem;

      img {
        // max-width: 144rem;
        // max-height: 70.6rem;
        // object-fit: 50%;
        height: 70.6rem;
        width: 144rem;
      }
    }

    .content {
      max-width: 80vw;
      width: min(100%, 46.6rem);
    }

    .h1 {
      margin-top: 2.9rem;
    }
    .sub2 {
      margin-top: 2.8rem;
    }
  }
}
