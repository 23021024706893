/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  font-family: sans-serif, Helvetica, Arial;
}
html {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
}
body {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
img {
  display: block;
  height: auto;
  max-width: 100%;
}
b,
strong {
  font-weight: var(--font-weight-bold, 700);
}
a {
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
input,
select {
  vertical-align: middle;
}
@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: url("../Campton-Bold.b839425e.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../Campton-Book.401e5a6a.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: italic;
  font-weight: bold;
  font-display: auto;
  src: url("../Campton-BoldItalic.8bbe23bb.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: italic;
  font-weight: normal;
  font-display: auto;
  src: url("../Campton-BookItalic.964f95d3.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url("../Campton-SemiBold.d288affa.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url("../Campton-Medium.cd913a13.woff") format("woff");
}
@font-face {
  font-family: "Campton";
  font-style: italic;
  font-weight: 500;
  font-display: auto;
  src: url("../Campton-MediumItalic.3c7527a5.woff") format("woff");
}
:root {
  --color-professor1: #1e1348;
  --color-professor2: #5c4d9b;
  --color-professor3: #e7daed;
  --color-professor4: #b2a4d2;
  --color-red: #ff604f;
  --color-orange: #f29045;
  --color-blue: #4aadf8;
  --color-green: #44c887;
  --color-yellow: #fdb71a;
  --color-white: #fff;
  --color-dark: #000;
  --bg-section: var(--color-white);
  --bg-section-alt: #f5f7fa;
  --font-family-base: "campton", sans-serif;
  --font-size-large: 1.8rem;
  --font-size-base: 1.8rem;
  --font-size-small: 1.6rem;
  --line-height-base: 2.4rem;
  --font-weight-regular: 300;
  --font-weight-bold: 700;
  --font-weight-accent: 700;
  --z-layout: 100;
  --z-modal: 1000;
  --z-debug: 10000;
  --duration-hover: 0.3s;
  --slider-transition: 0.3s ease-in-out;
  --padding-inline: 2rem;
  --header-height: 5.8rem;
  --flow-space: 1.6rem;
}
@media (min-width: 768px) {
  :root {
    --padding-inline: max(5vw, 50vw - 48rem);
  }
}
html {
  font-size: 62.5%;
  scroll-padding-top: 15rem;
  height: 100%;
  text-rendering: optimizelegibility;
}
body {
  height: auto;
  min-height: 100%;
  height: 100%;
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--color-dark);
  background: var(--color-white);
  overflow-x: hidden;
  letter-spacing: 0.01em;
}
body.no-scroll {
  overflow: hidden;
}
body,
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
#viewport {
  z-index: 1;
}
main {
  background: var(--color-white);
  position: relative;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 0;
  --brand: var(--color-professor2);
}
main[data-page=llengua-catalana] {
  --brand: var(--color-red);
  --brand-light: #f7bcbe;
}
main[data-page=lengua-castellana] {
  --brand: var(--color-orange);
  --brand-light: #fbcdac;
}
main[data-page=ciencies-socials] {
  --brand: var(--color-yellow);
  --brand-light: #ffdd7f;
}
main[data-page=tecnologia] {
  --brand: var(--color-blue);
  --brand-light: #d8e5ff;
}
a {
  color: var(--color-blue);
  text-decoration: none;
}
a:hover, a:focus-visible, a[aria-current] {
  text-decoration: underline;
}
i {
  font-style: italic;
}
.h1,
.h2,
.h3,
.sub1,
.sub2,
.pretitle,
.lead,
.product-name {
  font-size: var(--font-size, 4.8rem);
  line-height: var(--line-height, 5.2rem);
  font-weight: var(--font-weight, 700);
  font-weight: var(--font-weight, 700);
  letter-spacing: var(--letter-spacing, 0.01);
}
.h3 {
  --font-size: 3.2rem;
  --line-height: 3.2rem;
}
.sub1 {
  --font-size: 3rem;
  --line-height: 3.6rem;
  --font-weight: 500;
}
.sub2 {
  --font-size: 2.6rem;
  --line-height: 3.2rem;
}
.pretitle {
  --font-size: 1.8rem;
  --line-height: 2.2rem;
  --font-weight: 700;
  --letter-spacing: 0.05em;
  font-style: italic;
  color: var(--color-blue);
}
.lead {
  --font-size: 1.8rem;
  --line-height: 2.4rem;
  --font-weight: 600;
}
.product-name {
  --font-size: 1.6rem;
  --line-height: 3.6rem;
}
@media (min-width: 768px) {
  .h1 {
    --font-size: 11rem;
    --line-height: 10rem;
  }

  .h2 {
    --font-size: 7rem;
    --line-height: 7.2rem;
  }

  .h3 {
    --font-size: 5.2rem;
    --line-height: 5rem;
  }

  .sub1 {
    --font-size: 3.6rem;
    --line-height: 4rem;
  }
}
b,
strong {
  font-weight: var(--font-weight-bold);
}
.vertical-flow > * + * {
  margin-top: var(--flow-space, 1.6rem);
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
@media (max-width: 767.99px) {
  .lg {
    display: none !important;
  }
}
@media (max-width: 959.99px) {
  .xl {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .xs {
    display: none !important;
  }
}
:where(.row) {
  display: flex;
}
:where(.stack) {
  display: flex;
  flex-direction: column;
  justify-content: var(--justify, flex-start);
  align-items: var(--align, center);
}
body {
  background: var(--bg-section);
}
body:not([data-view=home]) .app-footer {
  z-index: 1;
}
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  padding: 0 2.4rem;
  gap: 0.8rem;
  font-family: inherit;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 600;
  color: var(--color, var(--color-white));
  background: var(--bg, var(--color-blue));
  border: 0;
  transition: background-color var(--duration-hover);
  border-radius: 9in;
}
.btn:hover, .btn:focus {
  background: var(--bg-hover, var(--color-blue));
  text-decoration: none;
}
.btn:active {
  background: var(--bg-active, var(--color-blue));
  text-decoration: none;
}
.container {
  ----container-inline-padding: var(--container-inline-padding, 0rem);
  padding-left: calc(var(----container-inline-padding) + var(--container-left-padding, var(--container-inline-padding, 2rem)));
  padding-right: calc(var(----container-inline-padding) + var(--container-right-padding, var(--container-inline-padding, 2rem)));
}
@media (min-width: 768px) {
  .container {
    ----container-inline-padding: clamp(2rem, 2vw, calc(50vw - (min(100vw, var(--container-width, 144rem)) / 2)));
  }
}
.container-only {
  margin-left: auto;
  margin-right: auto;
  width: clamp(4rem, 100vw - 4rem, 144rem);
  background: #0003;
}
.container-fluid {
  --computed-width: min(100vw - var(--inline-padding, 2rem) * 2, var(--container-width, 144rem));
  --computed-inline-padding: calc((100vw - var(--computed-width)) / 2);
  padding-left: var(--computed-inline-padding);
  padding-right: var(--computed-inline-padding);
}
.media {
  position: relative;
}
.media img {
  object-fit: cover;
  object-position: center;
}
.media.background {
  position: absolute;
  border-radius: 1rem 1rem 5rem 0;
  inset: 0 0 11.4rem;
  z-index: -1;
}
.media.background img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
.app-header {
  --nav-gap: 2.3rem;
  --nav-item-padding: 2.3rem;
  height: 7.2rem;
  position: fixed;
  inset: 0.8rem 0.8rem auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(1.9rem - var(--nav-item-padding)) 0 2.2rem;
  background: var(--color-professor1);
  border-radius: 1.9rem;
  z-index: var(--z-layout);
  font-size: 1.4rem;
  line-height: 1;
}
.app-header > a {
  display: block;
}
.app-header > a > svg {
  height: 65%;
  width: 65%;
}
.app-header nav {
  display: flex;
  gap: calc(var(--nav-gap) - var(--nav-item-padding) * 2);
}
.app-header nav > a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem var(--nav-item-padding) 0;
  height: 3rem;
  color: var(--color-white);
  border-radius: 9in;
  text-decoration: none;
  font-weight: 600;
}
.app-header nav > a[aria-current=page] {
  background: var(--color-professor2);
  font-weight: 700;
}
.app-header nav > a[aria-current=page]:before {
  --size: 0.6rem;
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: var(--size);
  height: var(--size);
  transform: translate(-50%, -50%);
  background: var(--color-white);
  border-radius: 9in;
}
@media (min-width: 768px) {
  .app-header {
    --nav-gap: 4rem;
    --nav-item-padding: 1.7rem;
    inset: 1.6rem 1.6rem auto;
    height: 8rem;
    padding: 0 calc(3.5rem - var(--nav-item-padding)) 0 3.5rem;
  }
  .app-header > a > svg {
    width: auto;
    height: auto;
  }
  .app-header nav {
    gap: 2.2rem;
  }
  .app-header nav > a {
    max-width: 15.4rem;
  }
}
.app-footer {
  position: relative;
  display: grid;
  gap: 4.6rem;
  padding-top: 5.4rem;
  padding-bottom: 4.2rem;
  background: var(--color-professor1);
  color: var(--color-white);
}
.app-footer .header,
.app-footer .info,
.app-footer .footer {
  display: flex;
  flex-direction: column;
}
.app-footer .wrapper {
  display: flex;
  gap: 4.6rem;
  flex-direction: column;
}
.app-footer .header {
  font-weight: 700;
  gap: 3rem;
}
.app-footer .info {
  gap: 1.7rem;
  font-size: 2.2rem;
  line-height: 2.6rem;
}
.app-footer .info > div {
  display: flex;
  align-items: center;
  gap: 3.2rem;
}
.app-footer .form {
  display: grid;
  gap: 4.6rem;
  font-weight: 600;
}
.app-footer .form-fields {
  display: grid;
  gap: 2rem;
}
.app-footer .form-fields + .form-fields {
  margin-top: 4.2rem;
}
.app-footer .form-title {
  font-size: 2.4rem;
  line-height: 2.4rem;
}
.app-footer .form-field label {
  display: none;
}
.app-footer .form-field input {
  height: 5.2rem;
  display: flex;
  padding: 0 1.2rem;
  align-items: center;
  border-radius: 0.6rem;
  border: 0;
  width: 100%;
  font-family: inherit;
}
.app-footer .checkbox input {
  display: none;
}
.app-footer .checkbox span {
  display: inline-block;
  padding-left: 2.8rem;
}
.app-footer .checkbox span::before {
  content: "";
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  margin-left: -2.8rem;
  background: center;
  background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.5' y='2.5' width='19' height='19' rx='3.5' fill='%234AADF8' stroke='%231E1348'/%3E%3C/svg%3E%0A");
}
.app-footer .checkbox input:checked + span::before {
  background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.5' y='2.5' width='19' height='19' rx='3.5' fill='%234AADF8' stroke='%231E1348'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m16.288 9.293-.003-.003a.997.997 0 0 0-1.412-.003L10.99 13.17l-.875-.87a1 1 0 0 0-1.41 0l-.005.005a.995.995 0 0 0-.005 1.408c.002 0 .003.002.004.003l1.584 1.58a1 1 0 0 0 1.413-.002l4.59-4.59a1 1 0 0 0 .002-1.411Z' fill='%23fff'/%3E%3C/svg%3E%0A");
}
.app-footer .checkbox a {
  color: inherit;
  text-decoration: underline;
}
.app-footer .form-choices {
  grid-column: 1/span 2;
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}
.app-footer .form-footer {
  display: grid;
  margin-top: 2rem;
  gap: 1.6rem;
}
.app-footer .footer {
  align-items: center;
}
.app-footer .footer svg {
  max-width: 23.4rem;
}
.app-footer .parallax-element {
  top: -4rem;
  right: -18rem;
}
.app-footer .parallax-element > div {
  --size: 28.9rem;
  width: var(--size);
  height: var(--size);
  background: var(--color-professor2);
  border-radius: 9in;
}
@media (min-width: 768px) {
  .app-footer {
    padding-top: 12rem;
    padding-bottom: 14rem;
    --container-width: min(80%, 112rem);
  }
  .app-footer .parallax-element {
    top: -11rem;
    right: -17rem;
  }
  .app-footer .parallax-element > div {
    --size: 33.7rem;
  }
}
@media (min-width: 960px) {
  .app-footer {
    --container-width: min(80%, 112rem);
    --container-left-padding: clamp(2rem, 2vw, 15rem);
    --container-right-padding: clamp(2rem, 2vw, 15rem);
    padding-top: 22rem;
    padding-bottom: 24rem;
    border-top-left-radius: 30rem;
    gap: 1.6rem;
  }
  .app-footer .form,
.app-footer .content {
    flex: 1 0 min(55rem, 50%);
  }
  .app-footer .wrapper {
    flex-direction: row;
    gap: 2rem;
  }
  .app-footer .form {
    gap: 1.7rem;
  }
  .app-footer .form-fields {
    grid-template-columns: repeat(2, 1fr);
  }
  .app-footer .form-title {
    grid-column: 1/span 2;
  }
  .app-footer .form-footer {
    grid-template-columns: auto 15.7rem;
  }
  .app-footer .footer {
    align-items: start;
    margin-top: 2rem;
    grid-column: 1/span 2;
  }
  .app-footer .footer svg {
    max-width: 41.6rem;
  }
  [data-view=home] .app-footer::before {
    content: "";
    position: absolute;
    display: block;
    inset: 0;
    background: var(--bg-section-alt);
    z-index: -1;
  }
  .app-footer .form-choices {
    margin-bottom: 3rem;
  }
  [data-view=index] .app-footer {
    padding-bottom: 10rem;
  }
  [data-view=index] .app-footer .footer {
    width: 50%;
    transform: translateY(-100%);
    margin-top: -2rem;
  }
}
.sequencies-header {
  --nav-gap: 2.3rem;
  --nav-item-padding: 2.3rem;
  height: 7.2rem;
  position: fixed;
  inset: 0.8rem 0.8rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.4rem 0 2.2rem;
  background: var(--color-professor1);
  border-radius: 1.9rem;
  z-index: calc(var(--z-layout) + 1);
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
}
.sequencies-header > a {
  display: block;
  max-width: 10.2rem;
  margin-right: auto;
}
.sequencies-header > a > svg {
  height: 100%;
  width: 100%;
}
.sequencies-header nav {
  position: absolute;
  top: calc(100% + 0.8rem);
  left: 0;
  right: 0;
  padding: 4.6rem;
  background: var(--color-professor1);
  border-radius: 1.9rem;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
}
.sequencies-header nav > a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem var(--nav-item-padding) 0;
  height: 3rem;
  color: var(--color-white);
  border-radius: 9in;
  text-decoration: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  text-align: center;
}
.sequencies-header nav > a[aria-current=page]:before {
  --size: 0.6rem;
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: var(--size);
  height: var(--size);
  transform: translate(-50%, -50%);
  background: var(--color-white);
  border-radius: 9in;
}
.sequencies-header nav > a[data-key=llengua-catalana]::before {
  background: var(--color-red);
}
.sequencies-header nav > a[data-key=lengua-castellana]::before {
  background: var(--color-orange);
}
.sequencies-header nav > a[data-key=ciencies-socials]::before {
  background: var(--color-yellow);
}
.sequencies-header nav > a[data-key=tecnologia]::before {
  background: var(--color-blue);
}
.sequencies-header nav > a[aria-current=page] {
  background: var(--color-professor2);
}
.sequencies-header:not(.open) nav {
  display: none;
}
.sequencies-header .actives {
  margin-left: 1rem;
}
.sequencies-header .actives .nav-item {
  display: none;
  background: #372a69;
  height: 4.4rem;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 2rem 0;
  border-radius: 1.5rem;
  text-align: center;
  max-width: 17rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
[data-page=llengua-catalana] .sequencies-header .actives .nav-item[data-key=llengua-catalana], [data-page=lengua-castellana] .sequencies-header .actives .nav-item[data-key=lengua-castellana], [data-page=ciencies-socials] .sequencies-header .actives .nav-item[data-key=ciencies-socials], [data-page=tecnologia] .sequencies-header .actives .nav-item[data-key=tecnologia] {
  display: flex;
}
.sequencies-header .toggler {
  all: unset;
  display: inline-flex;
  margin-left: 1rem;
  cursor: pointer;
}
.sequencies-header .toggler circle {
  fill: var(--color-professor2);
}
[data-page=sequencies] .sequencies-header .toggler circle[data-key=llengua-catalana], [data-page=llengua-catalana] .sequencies-header .toggler circle[data-key=llengua-catalana] {
  fill: var(--color-red);
}
[data-page=sequencies] .sequencies-header .toggler circle[data-key=lengua-castellana], [data-page=lengua-castellana] .sequencies-header .toggler circle[data-key=lengua-castellana] {
  fill: var(--color-orange);
}
[data-page=sequencies] .sequencies-header .toggler circle[data-key=ciencies-socials], [data-page=ciencies-socials] .sequencies-header .toggler circle[data-key=ciencies-socials] {
  fill: var(--color-yellow);
}
[data-page=sequencies] .sequencies-header .toggler circle[data-key=tecnologia], [data-page=tecnologia] .sequencies-header .toggler circle[data-key=tecnologia] {
  fill: var(--color-blue);
}
@media (min-width: 960px) {
  .sequencies-header {
    inset: 1.6rem 1.6rem auto;
    height: 8rem;
    padding: 0 2.2rem 0 3.5rem;
    justify-content: space-between;
  }
  .sequencies-header > a {
    max-width: 20.8rem;
  }
  .sequencies-header .toggler,
.sequencies-header .actives {
    display: none;
  }
  .sequencies-header nav {
    position: static;
    flex-direction: row;
    padding: 0;
    gap: 3.6rem;
    margin-left: 3.6rem;
  }
  .sequencies-header nav > a {
    height: 5.2rem;
    border-radius: 1.6rem;
    padding: 0 0.7rem;
    width: 15.6rem;
  }
  .sequencies-header nav > a[aria-current=page] {
    font-weight: 700;
  }
  .sequencies-header:not(.open) nav {
    display: flex;
  }
}
.cover {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 3.6rem;
  color: var(--color-white);
  min-height: 67.2rem;
  overflow: hidden;
  position: relative;
  justify-content: center;
  gap: 1.2rem;
  border-radius: 2.4rem;
}
.cover .background {
  border-radius: 2.4rem;
}
.cover .head {
  --color: var(--color-white);
  --dot-color: var(--color-blue);
  position: absolute;
  inset: 0 0 auto;
}
.cover .content {
  display: grid;
  gap: 1.2rem;
}
@media (min-width: 768px) {
  .cover {
    --container-left-padding: min(10vw, 15rem);
    --container-right-padding: min(50vw, 15rem);
  }
  .cover .head {
    top: 1.2rem;
  }
  .cover .content {
    gap: 0.5rem;
  }
  .cover .h2 {
    margin-top: 2.1rem;
  }
  .cover .lead {
    width: min(100%, 46rem);
  }
}
.card {
  --border-radius: 2.2rem;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  background: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.8rem 2.5rem -1rem rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius);
}
.card .media {
  margin-bottom: -4.8rem;
  z-index: -1;
  overflow: hidden;
  border-radius: var(--border-radius);
}
.card .glyph {
  position: absolute;
  width: 6.4rem;
  height: 7.5rem;
  left: 2rem;
  top: 0;
  padding: 1.7rem 0.8rem;
  background: var(--color-white);
  border-radius: 0 0 9in 9in;
}
.card .content {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 2rem;
}
.card a {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  margin-top: 1.2rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .card {
    flex-direction: row;
    align-items: center;
    height: min-content;
  }
  .card .media {
    margin-bottom: 0;
    height: calc(39.6rem + 2px);
    inset: -1px;
    margin-bottom: -2px;
  }
  .card .media picture img {
    height: 100%;
    width: 100%;
  }
  .card .content {
    gap: 2.7rem;
    padding: 1.4rem;
    width: 27rem;
    flex: 0 1 min-content;
  }
  .card .content .h2 {
    position: relative;
    margin-left: -7.5rem;
  }
}
.more-info {
  --bg: var(--color-professor1);
  --bg-hover: #6856ae;
  --bg-active: #5d4d9b;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: var(--z-layout);
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  font-weight: 500;
}
.more-info.hidden {
  opacity: 0;
}
@media (max-width: 767px) {
  .more-info {
    width: 4.8rem;
    padding: 0;
  }
}
.floor {
  position: relative;
  z-index: 1;
}
.floor .background {
  position: absolute;
  inset: 0;
  z-index: -1;
}
.head {
  color: var(--color, var(--color-professor2));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  padding: 2rem;
  text-align: center;
}
.head .bullet {
  content: "";
  display: block;
  --size: 2.4rem;
  width: var(--size);
  height: var(--size);
  background: var(--dot-color, var(--color-professor2));
  border-radius: 9in;
}
.head .head__body {
  margin-top: -1.2rem;
  color: var(--body-color, #000);
  max-width: 67rem;
}
.product {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.product .media {
  margin: 0 auto;
  max-width: 27rem;
  position: relative;
}
.product .media:before, .product .media:after {
  content: "";
  position: absolute;
  display: block;
}
.product .media img {
  z-index: 4;
}
.product .media::after {
  --offset: 30%;
  bottom: 0;
  right: var(--offset);
  width: 130%;
  height: 140%;
  z-index: -2;
  background: no-repeat center/contain;
}
.product .media .parallax-element {
  left: 0;
  top: -40%;
  transform: scale(0.35);
  z-index: -1;
}
.product:nth-child(1) .media::after {
  background-image: url("data:image/svg+xml,%3Csvg width='563' height='547' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M562.403 274.498C562.403 424.997 436.505 547 281.201 547 125.898 547 0 424.997 0 274.498 0 123.999 103.838-18.63 305.065 1.995 484.651 20.403 562.403 124 562.403 274.498Z' fill='%23FF604F'/%3E%3C/svg%3E");
}
.product:nth-child(2) .media::after {
  background-image: url("data:image/svg+xml,%3Csvg width='566' height='497' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M177.439 458.687C36.847 395.699-34.341 252.154 16.928 137.722 68.196 23.289 246.612-37.608 387.204 25.38c140.592 62.988 232.31 184.214 143.357 360.047-76.479 124.62-212.53 136.248-353.122 73.26Z' fill='%2344C887'/%3E%3C/svg%3E");
}
.product:nth-child(3) .media::after {
  background-image: url("data:image/svg+xml,%3Csvg width='551' height='485' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.095 216.493C.732 61.381 117.68-.521 282.993.158c126.393.52 268.444 97.072 267.862 238.645-.637 155.112-104.71 264.752-318.82 242.615C40.951 461.661-.542 371.604.095 216.493Z' fill='%23EB61A6'/%3E%3C/svg%3E");
}
.product:nth-child(4) .media::after {
  background-image: url("data:image/svg+xml,%3Csvg width='597' height='490' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M595.515 269.556c-10.06 130.093-94.675 177.548-290.784 213.43C127.658 515.384-8.753 430.205 1.307 300.112c10.06-130.093 124.386-323.09 359.9-296.988 172.708 29.362 244.368 136.339 234.308 266.432Z' fill='%23FDB71A'/%3E%3C/svg%3E");
}
.product .content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.product .product-group {
  display: flex;
  flex-direction: column;
}
.product .product-group img {
  width: 4.8rem;
}
.product[data-odd] .content,
.product[data-odd] .product-group {
  text-align: right;
  align-items: flex-end;
}
.product[data-odd] .media::after {
  left: var(--offset);
  right: auto;
}
.product[data-odd] .media .parallax-element {
  left: -65%;
}
.product[data-variant=tot-tallers]:nth-child(1) .media::after {
  background-image: url("data:image/svg+xml,%3Csvg width='540' height='430' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.86 23.59C10.212 10.108 21.599-.438 35.068.244l457.642 23.178c12.145.615 21.91 10.215 22.732 22.347l24.233 357.769c.971 14.346-10.803 26.321-25.163 25.591L23.383 404.188C10.376 403.527.27 392.614.608 379.595L9.861 23.59Z' fill='%23FF604F'/%3E%3C/svg%3E");
}
.product[data-variant=tot-tallers]:nth-child(2) .media::after {
  background-image: url("data:image/svg+xml,%3Csvg width='530' height='503' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M529.481 52.417c.837-13.302-9.324-24.738-22.631-25.471L25.321.396C11.568-.362 0 10.586 0 24.36v402.314c0 12.207 9.163 22.469 21.292 23.847l454.632 51.631c13.641 1.549 25.799-8.638 26.661-22.339l26.896-427.396Z' fill='%2344C887'/%3E%3C/svg%3E");
}
.product[data-variant=tot-tallers]:nth-child(3) .media::after {
  background-image: url("data:image/svg+xml,%3Csvg width='523' height='562' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M460.336 561.812c12.919.663 24.039-9.033 25.142-21.922l37.204-434.652c1.037-12.114-7.15-23.09-19.057-25.55L122.402.938c-13.135-2.714-25.945 5.865-28.437 19.044L1.11 510.98c-2.703 14.292 7.827 27.683 22.353 28.428l436.873 22.404Z' fill='%23FBB800'/%3E%3C/svg%3E");
}
@media (min-width: 768px) {
  .product {
    flex-direction: row;
    justify-content: flex-start;
    gap: 7.5rem;
  }
  .product .media {
    max-width: 50.6rem;
  }
  .product .media::after {
    --offset: 0;
    transform: scale(0.9);
  }
  .product .media .parallax-element {
    left: 33%;
    top: -30%;
    transform: scale(0.6);
    z-index: -1;
  }
  .product .content {
    flex: 0 0 43rem;
  }
  .product .content .h3 {
    margin-bottom: 0.4rem;
  }
  .product .media-wrapper {
    width: min(100%, 50.6rem);
  }
  .product[data-odd] {
    flex-direction: row-reverse;
  }
  .product[data-odd] .content,
.product[data-odd] .product-group {
    align-items: flex-start;
    text-align: left;
  }
  .product[data-variant=tot-tallers] .media::after {
    bottom: -8rem;
  }
}
.products-list {
  display: grid;
  gap: 0.8rem;
}
.products-list img {
  width: 3.4rem;
}
.products-list li {
  display: flex;
  align-items: center;
  gap: 0.9rem;
}
@media (min-width: 960px) {
  .products-list {
    background: var(--color-white);
    border-radius: 1.8rem;
    padding: 0.9rem 2rem;
    width: 26.3rem;
    gap: 0rem;
  }
  .products-list .product-name {
    padding: 1.7rem 0;
    flex-grow: 1;
  }
  .products-list li + li .product-name {
    border-top: 1px solid #edecf0;
  }
}
.numbered-list {
  display: grid;
  gap: 3.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.numbered-list li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  text-align: center;
}
.numbered-list .num {
  position: relative;
  font-size: 4.2rem;
  line-height: 1;
  font-weight: 700;
  isolation: isolate;
}
.numbered-list .num::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 0.9rem;
  transform: translateX(-50%);
  width: 5.2rem;
  height: 4.7rem;
  background: no-repeat center/contain;
}
.numbered-list li:nth-child(1) .num::before {
  background-image: url("data:image/svg+xml,%3Csvg width='51' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.938 34.75c-15.137 11.368-23.08 12.811-34.5 6.683C.015 35.305-3.026 23.181 3.1 11.765 9.224.35 24.926-3.144 36.347 2.985c11.422 6.128 20.584 21.027 9.59 31.765Z' fill='%23FDB71A'/%3E%3C/svg%3E");
}
.numbered-list li:nth-child(2) .num::before {
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M41.301 6.703c8.741 10.17 11.687 25.405 2.222 33.54C34.06 48.375 16.287 48.17 7.545 38-1.196 27.83-2.775 15.423 6.69 7.289c9.465-8.134 25.87-10.757 34.612-.586Z' fill='%23FF604F'/%3E%3C/svg%3E");
}
.numbered-list li:nth-child(3) .num::before {
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 25.017C48 37.498 32.513 46 19.932 46 7.352 46 0 33.462 0 20.983 0 8.503 11.826 0 24.407 0 36.987 0 48 12.538 48 25.017Z' fill='%23789FED'/%3E%3C/svg%3E");
}
@media (min-width: 960px) {
  .numbered-list {
    gap: 3.6rem;
  }
  .numbered-list li {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    gap: 6rem;
  }
  .numbered-list .sub2 {
    margin-bottom: 0.6rem;
  }
  .numbered-list .num {
    font-size: 4.3rem;
  }
  .numbered-list .num::before {
    top: 1.2rem;
    width: 6.6rem;
    height: 6rem;
  }
}
.slider .scroll-wrap {
  overflow: hidden;
}
.slider .scroller {
  overflow-y: hidden;
  overflow-x: auto;
}
.slider .slider-nav {
  --size: 4.8rem;
  display: inline-flex;
  width: var(--size);
  height: var(--size);
  background: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.8rem 2.5rem -1rem rgba(0, 0, 0, 0.15);
  background: no-repeat center;
  border-radius: 9in;
  overflow: hidden;
  text-indent: 100%;
  --transition: 0.1s ease-in-out;
  transition: transform var(--transition), box-shadow var(--transition);
}
.slider .slider-nav[data-dir=prev] {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.248 6.352a1.2 1.2 0 0 1 0 1.697L11.297 12l3.951 3.952a1.2 1.2 0 0 1-1.696 1.696l-4.8-4.8a1.2 1.2 0 0 1 0-1.696l4.8-4.8a1.2 1.2 0 0 1 1.696 0Z' fill='%235D4D9B'/%3E%3C/svg%3E");
}
.slider .slider-nav[data-dir=next] {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.752 17.648a1.2 1.2 0 0 1 0-1.697L12.703 12 8.752 8.048a1.2 1.2 0 0 1 1.696-1.697l4.8 4.8a1.2 1.2 0 0 1 0 1.697l-4.8 4.8a1.2 1.2 0 0 1-1.696 0Z' fill='%235D4D9B'/%3E%3C/svg%3E");
}
.slider .slider-nav:active {
  transform: translateY(0.1rem);
  box-shadow: 0 0.4rem 2.5rem -1rem rgba(0, 0, 0, 0.15);
}
.parallax-element {
  position: absolute;
  z-index: -1;
  color: #f5f7fa;
}
.parallax-element[data-shape=circle] > [data-ref=element] {
  width: 49.9rem;
  height: 49.9rem;
  background-image: url("data:image/svg+xml,%3Csvg width='499' height='499' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='249.5' cy='249.5' r='249.5' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
.parallax-element[data-shape=cross] > [data-ref=element] {
  width: 61.6rem;
  height: 62.3rem;
  background-image: url("data:image/svg+xml,%3Csvg width='616' height='623' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M297.845 31.894a116.892 116.892 0 0 1 26.611 37.62l40.139 86.622 86.584-40.122a116.858 116.858 0 0 1 98.249 212.024l-86.584 40.122 40.138 86.62a116.859 116.859 0 0 1-18.896 129.707 116.88 116.88 0 0 1-81.978 37.987 116.858 116.858 0 0 1-84.565-31.814 116.87 116.87 0 0 1-26.61-37.619l-40.139-86.621-86.585 40.123A116.858 116.858 0 0 1 65.96 294.519l86.586-40.122-40.14-86.623a116.879 116.879 0 0 1-4.856-90.22 116.888 116.888 0 0 1 60.922-66.722 116.872 116.872 0 0 1 129.373 21.062Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
.parallax-element[data-shape=morph] > [data-ref=element] {
  width: 290.5rem;
  height: 191rem;
  background-image: url("data:image/svg+xml,%3Csvg width='2905' height='1910' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M620.029 26.552c258.219 39.924 507.291 125.45 735.651 252.604a216.382 216.382 0 0 1 68.12 53.742 217.078 217.078 0 0 1 41.62 76.24 217.531 217.531 0 0 1 8.43 86.516 217.465 217.465 0 0 1-26.1 82.922 217.14 217.14 0 0 1-56.44 66.036 216.634 216.634 0 0 1-77.75 38.562 216.082 216.082 0 0 1-86.58 4.908 216.117 216.117 0 0 1-81.54-29.535C862.287 501.634 542.022 424.435 218.557 435.125c-56.541.558-111.042-21.073-151.858-60.271C25.883 335.655 1.987 281.998.12 225.345c-1.87-56.652 18.436-111.807 56.578-153.68C94.838 29.79 147.792 4.519 204.244 1.249A2225.487 2225.487 0 0 1 620.371 26.37M2168.9 890.996a2205.482 2205.482 0 0 1 485.78 607.574 216.42 216.42 0 0 1 28.72 81.89c3.96 28.97 2.02 58.45-5.71 86.67a217.4 217.4 0 0 1-39.23 77.57 217.508 217.508 0 0 1-66.46 56.02 217.165 217.165 0 0 1-83.03 25.5c-29.1 2.82-58.46-.28-86.31-9.11a216.206 216.206 0 0 1-75.73-42.27 216.195 216.195 0 0 1-53.02-68.64c-155-284.24-384.04-521.08-662.87-685.43-48.03-29.835-82.46-77.309-95.92-132.282-13.46-54.972-4.89-113.083 23.88-161.916 28.78-48.834 75.47-84.518 130.11-99.428 54.63-14.91 112.88-7.865 162.3 19.631a2226.39 2226.39 0 0 1 337.88 244.25' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
.page-nav {
  --transition: 0.2s ease-in-out;
  position: fixed;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: var(--z-layout);
  --bg: var(--color-white);
  --color: var(--color-professor1);
}
.page-nav li {
  --bullet-width: 0.6rem;
  --bullet-height: var(--bullet-width);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  transition: all var(--transition);
}
.page-nav li::after {
  content: "";
  display: block;
  width: var(--bullet-width);
  height: var(--bullet-height);
  border-radius: 9in;
  background: var(--bg);
  transition: all var(--transition);
}
.page-nav li span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  border-radius: 0.6rem;
  background: var(--bg);
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 600;
  color: var(--color);
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--transition), color var(--transition), background-color var(--transition);
}
.page-nav li.active {
  --bullet-height: 2.5rem;
}
.page-nav li:hover span, .page-nav li.active span {
  opacity: 1;
  pointer-events: all;
}
.page-nav[data-variant=dark] {
  --bg: var(--color-professor1);
  --color: var(--color-white);
}
.sequencia {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.sequencia__label {
  margin: 0 auto;
  width: 29rem;
  overflow-x: hidden;
}
.sequencia__label,
.sequencia__label > div {
  display: flex;
  height: 7.6rem;
  border-radius: 9in;
  justify-content: center;
  align-items: center;
}
.sequencia__label > div {
  color: #fff;
  background: var(--brand);
  padding: 0 3.3rem;
  position: relative;
}
.sequencia__label > div::before, .sequencia__label > div::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0 -100vw;
  z-index: -1;
}
.sequencia__label > div::before {
  right: 50%;
  background: var(--brand-light);
}
.sequencia__label > div::after {
  left: 50%;
  background: #f5f7fa;
}
.sequencia[data-step="1"] .sequencia__label {
  justify-content: start;
}
.sequencia[data-step="3"] .sequencia__label {
  justify-content: end;
}
.sequencia__block {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.sequencia__block .media img {
  border-radius: 1.6rem;
}
.sequencia__block .media .video,
.sequencia__block .media .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sequencia__block .content {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}
@media (min-width: 768px) {
  .sequencia {
    gap: 7rem;
    --container-width: min(100%, 108.2rem);
  }
  .sequencia__label {
    width: 49rem;
  }
  .sequencia__block {
    --media-width: 40vw;
    --content-width: 43.7rem;
    --offset-width: 0;
    display: grid;
    place-content: center;
    grid-template-columns: var(--media-width) minmax(2rem, 7rem) var(--content-width) var(--offset-width);
    gap: 0;
  }
  .sequencia__block .media {
    grid-column: 1;
    grid-row: 1;
  }
  .sequencia__block .media img {
    border-radius: 2.2rem;
  }
  .sequencia__block .content {
    grid-column: 3;
    grid-row: 1;
    padding-top: 4.3rem;
  }
  .sequencia__block:nth-child(odd) {
    grid-template-columns: var(--content-width) minmax(2rem, 7rem) var(--media-width) var(--offset-width);
  }
  .sequencia__block:nth-child(odd) .media {
    grid-column: 3;
  }
  .sequencia__block:nth-child(odd) .content {
    grid-column: 1;
  }
}
@media (min-width: 960px) {
  .sequencia__block {
    --media-width: 50.6rem;
    --content-width: 43.7rem;
    --offset-width: minmax(0, 6.9rem);
  }
}
.demos {
  --gap: 0.82rem;
  overflow-y: hidden;
}
.demos[data-variant=hibrid] {
  --media-width: 14.3rem;
  --media-height: 17.6rem;
}
.demos[data-variant=digital] {
  --media-width: 24.6rem;
  --media-height: 17.6rem;
}
.demos .scroll-wrap {
  overflow-x: auto;
  margin-bottom: -2rem;
  scroll-snap-type: x mandatory;
}
.demos .scroller {
  display: flex;
  padding-left: 2rem;
  gap: var(--gap);
  height: calc(100% + 2rem);
  padding-bottom: 10rem;
}
.demos .demo {
  flex: 0 0 var(--media-width);
  position: relative;
}
.demos .demo::before {
  content: "";
  position: absolute;
  left: -2rem;
  scroll-snap-align: start;
}
.demos .demo:last-child {
  flex: 0 0 calc(var(--media-width) + 2rem);
  padding-right: 2rem;
}
.demos .demo img {
  box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;
  position: relative;
  z-index: 2;
}
.demos .demo a,
.demos .demo button {
  display: block;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}
.demos .demo .dropdown {
  position: absolute;
  background: #fff8;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-top: 0;
  border-radius: 0 0 1.1rem 1.1rem;
  inset: 100% 0.7rem auto;
  transform: translateY(-100%);
  transition: transform 0.2s ease-in-out;
  padding: 0.5rem 0.5rem;
  z-index: 1;
}
.demos .demo .dropdown li {
  padding: 0.7rem 0.2rem;
}
.demos .demo .dropdown li + li {
  border-top: 2px solid rgba(30, 19, 72, 0.05);
}
.demos .demo .dropdown a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-professor1);
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
.demos .demo .dropdown a::after {
  flex: 0 0 2.4rem;
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21.6a9.6 9.6 0 1 0 0-19.199 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.697 1.697l1.552 1.551H8.399a1.2 1.2 0 1 0 0 2.4h4.304l-1.552 1.552a1.2 1.2 0 1 0 1.697 1.697l3.6-3.6a1.2 1.2 0 0 0 0-1.697Z' fill='%23FF604F'/%3E%3C/svg%3E");
}
.demos .demo .open .dropdown {
  transform: translateY(0);
}
@media (min-width: 768px) {
  .demos {
    --padding-inline: 2rem;
    padding-left: var(--padding-inline);
    padding-right: var(--padding-inline);
  }
  .demos .scroll-wrap {
    overflow-x: hidden;
    margin-bottom: 0;
    scroll-snap-type: none;
  }
  .demos .scroller {
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: var(--gap);
    height: auto;
    padding-bottom: 0;
  }
  .demos .demo, .demos .demo:last-child {
    flex: 1 1 var(--media-width);
    padding-right: 0;
  }
}
@media (min-width: 960px) {
  .demos {
    --gap: 2.2rem;
    --padding-inline: calc(50vw - 56.5rem);
  }
}
.cutom-checkbox {
  --transition: 0.15s ease-in-out;
}
.cutom-checkbox [type=checkbox] {
  display: none;
}
.cutom-checkbox div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem 0 1.2rem;
  white-space: nowrap;
  height: 4rem;
  border-radius: 0.6rem;
  background-color: rgba(74, 173, 248, 0.3);
  transition: background-color var(--transition);
  font-size: 1.4rem;
  line-height: 2.4rem;
}
.cutom-checkbox div span {
  padding-top: 0.4rem;
  display: block;
}
.cutom-checkbox div svg {
  color: var(--color-blue);
  opacity: 0.3;
  transition: color var(--transition), opacity var(--transition);
}
.cutom-checkbox [type=checkbox]:checked + div {
  background-color: #4aadf8;
  font-weight: 700;
}
.cutom-checkbox [type=checkbox]:checked + div svg {
  color: #fff;
  opacity: 1;
}
.home-hero-floor {
  min-height: calc(100vh - 11.4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  color: var(--color-white);
  margin-bottom: 11.4rem;
  isolation: isolate;
  z-index: 5;
}
.home-hero-floor .conten {
  width: min(100%, 35rem);
  display: grid;
  gap: 2.4rem;
}
.home-hero-floor .background {
  border-radius: 2.2rem 2.2rem 10rem 0;
  inset: 0;
  overflow: hidden;
}
.home-hero-floor[data-variant=sequencies] .background img {
  object-position: top;
}
.home-hero-floor .scroll {
  position: absolute;
  transform: translate(-50%, 50%);
  bottom: 0;
  left: 50%;
  display: block;
  cursor: pointer;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.15) #4444dd);
}
@media (min-width: 768px) {
  .home-hero-floor {
    --container-width: 114rem;
    min-height: 100vh;
    margin: 0;
    padding-top: 8rem;
    padding-bottom: 4rem;
  }
  .home-hero-floor .conten {
    width: min(100%, 79rem);
  }
  .home-hero-floor .background {
    border-radius: 2.2rem 2.2rem 30rem 0;
  }
  .home-hero-floor .scroll {
    bottom: 6.4rem;
  }
}
.home-itineraris-floor {
  position: relative;
  padding-top: 4rem;
  z-index: 4;
}
.home-itineraris-floor .wrapper {
  position: relative;
  height: 66.1rem;
}
.home-itineraris-floor .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 30rem;
  margin-top: -0.7rem;
  z-index: 1;
}
.home-itineraris-floor .title {
  font-size: 4.8rem;
  line-height: 5.2rem;
  font-weight: 700;
  margin-bottom: 1.3rem;
}
.home-itineraris-floor .title b {
  display: block;
  font-size: 11rem;
  line-height: 10rem;
}
.home-itineraris-floor .circle svg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .home-itineraris-floor {
    padding-top: 16rem;
  }
  .home-itineraris-floor .wrapper {
    height: 79rem;
  }
  .home-itineraris-floor .content {
    width: 36.1rem;
    margin-top: -3rem;
  }
  .home-itineraris-floor .title {
    font-size: 7rem;
    line-height: 7.2rem;
    margin-bottom: 1rem;
  }
  .home-itineraris-floor .title b {
    display: block;
    font-size: 17.8rem;
    line-height: 15.45rem;
  }
}
.home-tallers-floor {
  position: relative;
  display: grid;
  gap: 8.9rem;
  z-index: 10;
  padding-top: 1.4rem;
  padding-bottom: 2rem;
}
.home-tallers-floor::before {
  content: "";
  position: absolute;
  display: block;
  width: 37.5rem;
  height: 84.4rem;
  bottom: -6rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: -2;
  background: no-repeat center/contain;
  background-image: url("data:image/svg+xml,%3Csvg width='375' height='844' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M398.003 8.816c11.546.235 20.944 9.358 21.523 20.891l39.734 790.954c.668 13.294-10.531 24.132-23.796 23.028l-517.919-43.088c-12.278-1.022-21.323-11.925-20.06-24.18l77.937-756.034C-23.404 8.998-13.692.414-2.245.647l400.248 8.169Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
.home-tallers-floor .content {
  display: grid;
  gap: 1rem;
  text-align: center;
  --inline-padding: 0;
}
.home-tallers-floor .content .h2 {
  --letter-spacing: 0;
}
.home-tallers-floor .content p {
  padding: 0 2rem;
}
.home-tallers-floor .tallers {
  display: grid;
  gap: 5.3rem;
  justify-content: center;
  align-content: center;
}
@media (min-width: 926px) {
  .home-tallers-floor {
    padding-top: 23.8rem;
    gap: 6.3rem;
  }
  .home-tallers-floor .content {
    display: grid;
    gap: 2.3rem;
    text-align: center;
    --container-width: 89.8rem;
  }
  .home-tallers-floor .content p {
    padding: 0;
    margin: 0 auto;
    max-width: 66.7rem;
  }
  .home-tallers-floor::before {
    width: 82.2rem;
    height: 77.4rem;
    bottom: -12rem;
    background-image: url("data:image/svg+xml,%3Csvg width='822' height='774' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M793.383 1.037c15.475-4.223 30.122 9.003 27.495 24.828L699.821 754.998c-1.876 11.301-12.111 19.271-23.527 18.321l-564.216-46.94a22 22 0 0 1-19.767-17.704L.42 238.562c-2.21-11.31 4.682-22.41 15.8-25.444L793.383 1.038Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
  }
  .home-tallers-floor .tallers {
    --container-width: 88.6rem;
    gap: 2.3rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1151px) {
  .home-tallers-floor .tallers {
    --container-width: 111.1rem;
  }
}
.taller {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2rem 30px rgba(0, 0, 0, 0.08);
  border-radius: 2.2rem;
  background: #fff;
  padding: 3.6rem 1rem;
  width: 28rem;
  text-align: center;
}
.taller .title {
  margin-bottom: 0.7rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 700;
  color: var(--color);
}
.taller p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
}
.taller::before {
  content: "";
  display: block;
  position: absolute;
  background: center/100% 100%;
  background-image: var(--shape);
  inset: var(--offset, -3rem);
  z-index: -1;
}
.taller[data-variant=red] {
  --color: var(--color-red);
  --shape: url("data:image/svg+xml,%3Csvg width='340' height='211' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M217.751 11.724c10.449.482 19.113 8.253 20.725 18.587l15.057 96.564c1.93 12.379-6.852 23.868-19.303 25.254L42.533 173.48c-12.21 1.36-23.167-7.543-24.335-19.772L6.102 27.093C4.829 13.77 15.647 2.408 29.015 3.023l188.736 8.701Z' fill='%23FF604F'/%3E%3C/svg%3E");
}
.taller[data-variant=orange] {
  --color: var(--color-orange);
  --shape: url("data:image/svg+xml,%3Csvg width='340' height='211' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M273.453 72.05c11.198 1.178 19.699 10.62 19.699 21.88v78.212c0 11.691-9.143 21.339-20.817 21.968L73.556 204.817c-12.799.689-23.475-9.666-23.177-22.48l2.547-109.56c.299-12.853 11.509-22.712 24.295-21.367l196.232 20.64Z' fill='%23F29045'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h340v211H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}
.taller[data-variant=blue] {
  --color: var(--color-blue);
  --shape: url("data:image/svg+xml,%3Csvg width='340' height='211' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M307.978 46.75c14.134-2.05 26.444 9.7 25.054 23.914l-11.765 120.252c-1.152 11.774-11.409 20.529-23.218 19.818l-194.778-11.729c-10.557-.636-19.167-8.693-20.502-19.185l-9.81-77.139c-1.515-11.904 6.79-22.826 18.665-24.548L307.978 46.75Z' fill='%234AADF8'/%3E%3C/svg%3E");
}
@media (min-width: 1151px) {
  .taller {
    --offset: -5rem;
    width: 35.5rem;
    padding: 7.3rem 1rem 5.9rem;
  }
  .taller .title {
    margin-bottom: 1.1rem;
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
  .taller p {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }
}
.home-features-floor {
  --height: 300vh;
  --slide1-color: var(--color-yellow);
  --slide2-color: var(--color-blue);
  --slide3-color: var(--color-green);
  --slide4-color: var(--color-red);
  height: var(--height);
  position: relative;
  z-index: 3;
  --slide-transition: 0.3s ease-in-out;
}
.home-features-floor .parallax-element {
  z-index: -1;
}
.home-features-floor .pin-space {
  height: var(--height);
  position: relative;
}
.home-features-floor .pin-element {
  position: absolute;
  left: 0;
  display: flex;
  place-content: center;
  min-height: 100vh;
}
.home-features-floor .pin-element > div {
  width: 100vw;
  height: 100vh;
  inset: 0;
  display: grid;
  padding-top: 8rem;
  grid-template-rows: min-content min-content min-content auto;
  background: var(--bg-section);
  border-bottom-right-radius: 10rem;
  z-index: 1;
  isolation: isolate;
}
.home-features-floor .pin-element .content {
  display: grid;
}
[data-view=home] .home-features-floor .pin-element::before {
  content: "";
  position: absolute;
  display: block;
  inset: 0;
  background: var(--bg-section-alt);
  z-index: -10;
}
.home-features-floor .pin-space[data-pinned=top] .pin-element {
  position: absolute;
  top: 0;
}
.home-features-floor .pin-space[data-pinned=center] .pin-element {
  position: fixed;
  top: 0;
}
.home-features-floor .pin-space[data-pinned=bottom] .pin-element {
  position: absolute;
  bottom: 0;
}
.home-features-floor .nums {
  position: relative;
  display: grid;
  gap: 1.5rem;
  padding: 0 2rem;
  margin-bottom: 3rem;
  width: 20rem;
  font-size: 8.6rem;
  line-height: 1;
  font-weight: 700;
}
.home-features-floor .nums svg {
  position: absolute;
  right: 0;
  bottom: 0;
}
.home-features-floor .num {
  position: relative;
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
  padding-left: 7rem;
  padding: 10rem 7rem 6rem 6rem;
  color: var(--color-white);
}
.home-features-floor .slides {
  position: relative;
  display: grid;
}
.home-features-floor .slide {
  flex: 0 0 100vw;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 2rem;
}
.home-features-floor .bullets {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  bottom: 1rem;
  padding: 2rem;
  margin-top: 2.4rem;
}
.home-features-floor .bullets li {
  --size: 1.8rem;
  width: var(--size);
  height: var(--size);
  border-radius: 9in;
}
.home-features-floor .bullets li:nth-child(1) {
  background: var(--slide1-color);
}
.home-features-floor .bullets li:nth-child(2) {
  background: var(--slide2-color);
}
.home-features-floor .bullets li:nth-child(3) {
  background: var(--slide3-color);
}
.home-features-floor .bullets li:nth-child(4) {
  background: var(--slide4-color);
}
.home-features-floor .slide,
.home-features-floor .num {
  opacity: 0;
  transition: opacity var(--slide-transition);
}
.home-features-floor .bullets li {
  transition: width var(--slide-transition);
}
.home-features-floor .nums {
  transition: color var(--slide-transition);
}
.home-features-floor[data-slide="1"] .slide:nth-child(1),
.home-features-floor[data-slide="1"] .num:nth-child(1) {
  opacity: 1;
}
.home-features-floor[data-slide="1"] .bullets li:nth-child(1) {
  width: 8rem;
}
.home-features-floor[data-slide="1"] .nums {
  color: var(--slide1-color);
}
.home-features-floor[data-slide="2"] .slide:nth-child(2),
.home-features-floor[data-slide="2"] .num:nth-child(2) {
  opacity: 1;
}
.home-features-floor[data-slide="2"] .bullets li:nth-child(2) {
  width: 8rem;
}
.home-features-floor[data-slide="2"] .nums {
  color: var(--slide2-color);
}
.home-features-floor[data-slide="3"] .slide:nth-child(3),
.home-features-floor[data-slide="3"] .num:nth-child(3) {
  opacity: 1;
}
.home-features-floor[data-slide="3"] .bullets li:nth-child(3) {
  width: 8rem;
}
.home-features-floor[data-slide="3"] .nums {
  color: var(--slide3-color);
}
.home-features-floor[data-slide="4"] .slide:nth-child(4),
.home-features-floor[data-slide="4"] .num:nth-child(4) {
  opacity: 1;
}
.home-features-floor[data-slide="4"] .bullets li:nth-child(4) {
  width: 8rem;
}
.home-features-floor[data-slide="4"] .nums {
  color: var(--slide4-color);
}
.home-features-floor[data-variant=itineraris] .nums svg {
  bottom: calc(50% - 11.5rem);
}
.home-features-floor[data-variant=tot-tallers] .nums svg {
  right: -22rem;
  bottom: calc(50% - 21rem);
  transform: scale(0.6);
}
@media (min-width: 768px) {
  .home-features-floor .pin-element > div {
    --container-left-padding: clamp(2rem, 2vw, 17rem);
    --container-right-padding: clamp(2rem, 2vw, 17rem);
    --container-width: 103rem;
    border-bottom-right-radius: 30rem;
    grid-template-columns: 25rem auto;
    grid-template-rows: auto min-content min-content min-content min-content auto;
  }
  .home-features-floor .head {
    grid-row: 1;
    grid-column: 1/span 2;
  }
  .home-features-floor .nums {
    grid-column: 1;
    grid-row: 2/span 3;
    align-self: center;
    font-size: 17.8rem;
    margin-left: -10vw;
  }
  .home-features-floor .nums svg {
    width: 136rem;
    height: 97rem;
    right: -8rem;
    bottom: -6rem;
  }
  .home-features-floor .slides {
    grid-column: 2;
    grid-row: 3/span 2;
    height: min-content;
  }
  .home-features-floor .bullets {
    grid-column: 2;
    grid-row: 2;
    justify-self: start;
    margin: 0;
    height: min-content;
  }
  .home-features-floor[data-variant=itineraris] .nums {
    transform: translateX(10rem);
  }
  .home-features-floor[data-variant=itineraris] .nums svg {
    bottom: calc(50% - 21rem);
  }
  .home-features-floor[data-variant=tot-tallers] .nums {
    transform: translateX(3rem);
  }
  .home-features-floor[data-variant=tot-tallers] .nums svg {
    position: absolute;
    right: -25rem;
    bottom: calc(-50% - 10rem);
    transform: scale(0.8);
  }
}
.home-features-floor[data-variant=index] {
  --slide1-color: #5d4d9b;
  --slide2-color: #eb61a6;
  --slide3-color: #f29045;
  --slide4-color: #2cad6e;
}
.home-features-floor[data-variant=index] .pin-element > div {
  border-radius: 0;
  background: #f5f7fa;
}
.home-features-floor[data-variant=index] .slide {
  padding: 0;
}
.home-features-floor[data-variant=index] .slide p {
  font-weight: bold;
}
.home-features-floor[data-variant=index] .content {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 4rem);
}
.home-features-floor[data-variant=index] .nums {
  display: flex;
  padding: 10rem 0 0;
  width: calc(100vw - 4rem);
  height: 20rem;
  text-align: center;
  justify-content: center;
  margin-bottom: 6rem;
}
.home-features-floor[data-variant=index] .nums .num {
  position: absolute;
  padding: 0;
}
.home-features-floor[data-variant=index] .nums::before {
  content: "";
  display: block;
  position: absolute;
  inset: -10rem 50% 8.1rem;
  background: currentColor;
  width: 200vw;
  transform: translate(-50%, 0);
}
.home-features-floor[data-variant=index] .nums svg.xs {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .home-features-floor[data-variant=index] {
    overflow: hidden;
  }
  .home-features-floor[data-variant=index]::before {
    content: "";
    display: block;
    position: absolute;
    inset: auto 0 0;
    height: 5rem;
    border-radius: 5rem 5rem 0 0;
    background: #fff;
    z-index: 10;
  }
  .home-features-floor[data-variant=index] .content {
    flex: 0 0 70%;
    justify-content: flex-start;
  }
  .home-features-floor[data-variant=index] .container-fluid {
    --container-width: 112.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-features-floor[data-variant=index] .nums {
    padding: 0;
    margin-right: 5rem;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 6rem;
    padding-top: 2.5rem;
  }
  .home-features-floor[data-variant=index] .nums::before {
    width: 200%;
    height: 200vh;
    inset: -50vh 0 0 0;
    transform: translate(calc(-50% - 10.8rem), 0);
  }
  .home-features-floor[data-variant=index] .nums svg.lg {
    width: auto;
    height: auto;
    inset: 50% 0 auto auto;
    transform: translate(0, -50%);
  }
  .home-features-floor[data-variant=index] .bullets {
    order: -1;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
}
.home-projects-floor {
  display: grid;
  gap: 3rem;
  padding-top: 7.5rem;
  padding-bottom: 8.6rem;
  background: var(--bg-section-alt);
}
.home-projects-floor .cards {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
@media (min-width: 768px) {
  .home-projects-floor {
    --container-inline-padding: 4rem;
    padding-top: 12rem;
    padding-bottom: 15rem;
    position: relative;
    gap: 9rem;
  }
  .home-projects-floor .cards {
    flex-direction: row;
    gap: 2.6rem;
  }
  .home-projects-floor .card:first-child {
    margin-bottom: 11rem;
  }
  .home-projects-floor .card:last-child {
    margin-top: 11rem;
  }
  .home-projects-floor::before {
    --offset: 40rem;
  }
  .home-projects-floor .cards {
    isolation: isolate;
  }
  .home-projects-floor .cards .parallax-element {
    content: "";
    position: absolute;
    display: block;
    width: 141.5rem;
    height: 86.9rem;
    left: 50%;
    bottom: -3rem;
    margin-left: -23rem;
  }
  .home-projects-floor .cards .parallax-element > div {
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='1415' height='869' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M419.263 253.463A2205.358 2205.358 0 0 1 1156.13 3.943a216.498 216.498 0 0 1 86.78.924 217.1 217.1 0 0 1 79.53 34.932 217.59 217.59 0 0 1 59.53 63.342 217.776 217.776 0 0 1 29.99 81.598 217.32 217.32 0 0 1-4.36 86.772 216.598 216.598 0 0 1-38.01 78.036 216.133 216.133 0 0 1-65.57 56.79 216.118 216.118 0 0 1-82.61 26.44c-320.08 48.785-620.855 183.359-870.473 389.471-44.433 34.99-100.806 51.166-157.074 45.072-56.268-6.093-107.97-33.974-144.058-77.684-36.089-43.71-53.703-99.784-49.08-156.242C5.35 576.936 31.844 524.572 74.55 487.489A2226.429 2226.429 0 0 1 419.422 253.11' fill='%234AADF8'/%3E%3C/svg%3E");
  }
  .home-projects-floor[data-variant=tot-tallers] .cards {
    isolation: isolate;
  }
  .home-projects-floor[data-variant=tot-tallers] .cards .parallax-element {
    content: "";
    width: 78.5rem;
    height: 65.4rem;
    left: 50%;
    bottom: -3rem;
    margin-left: -23rem;
  }
  .home-projects-floor[data-variant=tot-tallers] .cards .parallax-element > div {
    background-image: url("data:image/svg+xml,%3Csvg width='785' height='654' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M69.487 178.042a42.49 42.49 0 0 1 32.15-34.593L700.065 1.599c25.592-6.066 50.493 12.34 52.2 38.586l32.09 493.165c1.478 22.722-15.217 42.571-37.857 45.006l-699.16 75.193C19.472 656.545-3.61 632.219.843 604.551l68.643-426.509Z' fill='%23FF604F'/%3E%3C/svg%3E");
  }
  .home-projects-floor[data-variant=sequencies] .cards {
    isolation: isolate;
  }
  .home-projects-floor[data-variant=sequencies] .cards .parallax-element {
    content: "";
    width: 138.9rem;
    height: 98.8rem;
    left: 90%;
    bottom: 5rem;
    margin-left: -23rem;
  }
  .home-projects-floor[data-variant=sequencies] .cards .parallax-element > div {
    background-image: url("data:image/svg+xml,%3Csvg width='1391' height='990' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30.25 878.939c-60.89-105.463-24.755-240.318 80.708-301.208L1059.22 30.249c105.47-60.889 240.32-24.754 301.21 80.709 60.89 105.463 24.76 240.318-80.71 301.207L331.457 959.647C225.994 1020.54 91.139 984.402 30.25 878.939Z' fill='%235D4D9B'/%3E%3C/svg%3E");
  }
  .home-projects-floor[data-variant=sequencies] .card:first-child {
    margin-bottom: 0;
  }
  .home-projects-floor[data-variant=sequencies] .card:last-child {
    margin-top: 0;
  }
}
.home-sequencies-floor {
  display: grid;
  gap: 2.5rem;
  text-align: center;
  padding-bottom: 4.4rem;
  background: #f5f7fa;
}
.home-sequencies-floor::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  z-index: -1;
  background: #fff;
  border-radius: 0 0 10rem 0;
}
.home-sequencies-floor .intro {
  display: grid;
  gap: 1rem;
}
.home-sequencies-floor .intro .title {
  margin: 0 -0.5rem;
  font-size: 4.8rem;
  line-height: 5.2rem;
  font-weight: 700;
}
.home-sequencies-floor .intro .lead {
  --font-weight: 400;
}
.home-sequencies-floor .items {
  position: relative;
  display: grid;
  gap: 1.2rem;
}
.home-sequencies-floor .item {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9in;
  background: #b2a4d2;
  flex-direction: column;
  padding: 0 2rem;
  max-width: 89.6rem;
}
.home-sequencies-floor .item .sub2 {
  color: #fff;
}
.home-sequencies-floor .parallax1 {
  top: -3rem;
  left: -4rem;
}
.home-sequencies-floor .parallax1 > div {
  width: 22.9rem;
  height: 9.7rem;
  background-image: url("data:image/svg+xml,%3Csvg width='229' height='97' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='11' width='229' height='86' rx='43' fill='%235D4D9B'/%3E%3Ccircle cx='59' cy='11' r='11' fill='%23FDB71A'/%3E%3C/svg%3E");
}
.home-sequencies-floor .parallax2 > div,
.home-sequencies-floor .parallax3 > div,
.home-sequencies-floor .parallax4 > div {
  width: 2.2rem;
  height: 2.2rem;
}
.home-sequencies-floor .parallax2 {
  right: -1rem;
  top: 50%;
  z-index: 2;
}
.home-sequencies-floor .parallax2 > div {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23FF604F'/%3E%3C/svg%3E");
}
.home-sequencies-floor .parallax3 {
  right: -3rem;
  top: calc(50% + 9rem);
}
.home-sequencies-floor .parallax3 > div {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23F29045'/%3E%3C/svg%3E");
}
.home-sequencies-floor .parallax4 {
  left: -3rem;
  bottom: -1rem;
}
.home-sequencies-floor .parallax4 > div {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23789FED'/%3E%3C/svg%3E");
}
@media (min-width: 768px) {
  .home-sequencies-floor {
    --container-width: 95rem;
    padding-top: 15.7rem;
    padding-bottom: 19.6rem;
    gap: 8.6rem;
  }
  .home-sequencies-floor::before {
    border-radius: 0 0 30rem 0;
  }
  .home-sequencies-floor .intro {
    gap: 2rem;
  }
  .home-sequencies-floor .items {
    gap: 3.1rem;
  }
  .home-sequencies-floor .item {
    height: 17.1rem;
    padding: 0 2rem;
    max-width: 89.6rem;
    padding: 0 24.55rem;
  }
  .home-sequencies-floor .item .sub2 {
    font-size: 7rem;
    line-height: 7.2rem;
  }
  .home-sequencies-floor .parallax1 {
    top: -4.5rem;
    left: -16rem;
  }
  .home-sequencies-floor .parallax1 > div {
    width: 56.9rem;
    height: 12.5rem;
    background-image: url("data:image/svg+xml,%3Csvg width='569' height='125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='569' height='108' rx='54' fill='%235D4D9B'/%3E%3Ccircle cx='77.5' cy='107.5' r='17.5' fill='%23FDB71A'/%3E%3C/svg%3E");
  }
  .home-sequencies-floor .parallax5 {
    top: calc(50% - 3.5rem);
    right: -15rem;
  }
  .home-sequencies-floor .parallax5 > div {
    width: 56.9rem;
    height: 14.1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='569' height='141' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='16' width='569' height='108' rx='54' fill='%23F5F7FA'/%3E%3Ccircle cx='417.5' cy='123.5' r='17.5' fill='%23F29045'/%3E%3Ccircle cx='499.5' cy='17.5' r='17.5' fill='%23FF604F'/%3E%3C/svg%3E");
  }
  .home-sequencies-floor .parallax6 {
    bottom: -5rem;
    left: -65rem;
  }
  .home-sequencies-floor .parallax6 > div {
    width: 58.3rem;
    height: 10.8rem;
    background-image: url("data:image/svg+xml,%3Csvg width='583' height='108' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='569' height='108' rx='54' fill='%23F5F7FA'/%3E%3Ccircle cx='565.5' cy='53.5' r='17.5' fill='%23789FED'/%3E%3C/svg%3E");
  }
}
.home-subjects-floor {
  padding-top: 2rem;
  padding-bottom: 6rem;
  background: #f5f7fa;
  z-index: 0;
}
.home-subjects-floor .subjects {
  display: grid;
  gap: 3.6rem;
}
.home-subjects-floor .subject img {
  border-radius: 1.2rem;
  box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 1;
}
.home-subjects-floor .subject .content {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 32px -10px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 1.2rem 1.2rem;
  padding: 1.6rem 1rem 1.2rem;
  margin: 0 1.3rem;
  color: #000;
  text-align: center;
  background: #fff;
}
.home-subjects-floor .subject, .home-subjects-floor .subject:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  .home-subjects-floor {
    --container-width: min(100%, 134rem);
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .home-subjects-floor .subjects {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
    margin-top: 6.7rem;
  }
  .home-subjects-floor .subject img {
    aspect-ratio: 1/1;
  }
  .home-subjects-floor .subject .content {
    min-height: 9.4rem;
  }
  .home-subjects-floor .parallax-element {
    content: "";
    position: absolute;
    display: block;
    width: 141.5rem;
    height: 86.9rem;
    left: 50%;
    bottom: -3rem;
    margin-left: -23rem;
  }
  .home-subjects-floor .parallax-element > div {
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='1415' height='869' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M419.263 253.463A2205.358 2205.358 0 0 1 1156.13 3.943a216.498 216.498 0 0 1 86.78.924 217.1 217.1 0 0 1 79.53 34.932 217.59 217.59 0 0 1 59.53 63.342 217.776 217.776 0 0 1 29.99 81.598 217.32 217.32 0 0 1-4.36 86.772 216.598 216.598 0 0 1-38.01 78.036 216.133 216.133 0 0 1-65.57 56.79 216.118 216.118 0 0 1-82.61 26.44c-320.08 48.785-620.855 183.359-870.473 389.471-44.433 34.99-100.806 51.166-157.074 45.072-56.268-6.093-107.97-33.974-144.058-77.684-36.089-43.71-53.703-99.784-49.08-156.242C5.35 576.936 31.844 524.572 74.55 487.489A2226.429 2226.429 0 0 1 419.422 253.11' fill='%23fff'/%3E%3C/svg%3E");
  }
}
.page-hero-floor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 39rem;
  padding-bottom: 5.6rem;
  color: var(--color-professor1);
}
.page-hero-floor .media {
  height: 54rem;
  inset: 0 0 auto;
}
.page-hero-floor .media img {
  left: 50%;
  transform: translateX(-50%);
  object-fit: fill;
  object-position: top center;
  height: 54rem;
  width: 54rem;
  max-width: none;
}
.page-hero-floor .content {
  max-width: 80vw;
}
.page-hero-floor .h1,
.page-hero-floor .sub2 {
  margin-top: 1.2rem;
}
.page-hero-floor[data-variant=sequencies] .media {
  height: 54rem;
  inset: 0 0 auto;
}
.page-hero-floor[data-variant=sequencies] .media img {
  left: 50%;
  transform: translateX(-50%);
  object-fit: fill;
  object-position: top center;
  height: 44rem;
  width: 82.3rem;
}
@media (min-width: 768px) {
  .page-hero-floor {
    --container-width: 113.8rem;
    min-height: 70.6rem;
    padding-top: 18rem;
    padding-bottom: 5.6rem;
  }
  .page-hero-floor .media {
    height: 70.6rem;
  }
  .page-hero-floor .media img {
    height: 70.6rem;
    width: 144rem;
  }
  .page-hero-floor .content {
    max-width: 80vw;
    width: min(100%, 46.6rem);
  }
  .page-hero-floor .h1 {
    margin-top: 2.9rem;
  }
  .page-hero-floor .sub2 {
    margin-top: 2.8rem;
  }
}
.page-metodologia-floor {
  --item-width: 34rem;
  --item-gap: calc(47.4rem - var(--item-width));
  --item-offset: calc(100vw - var(--item-width));
  --media-width: 202rem;
  --media-height: 41rem;
  --slider-transition: 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  overflow: hidden;
}
.page-metodologia-floor .slider {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 78rem;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
}
.page-metodologia-floor .scroll-wrap {
  width: 100vw;
}
.page-metodologia-floor .scroller {
  position: relative;
  scroll-snap-type: x mandatory;
  display: flex;
  gap: var(--item-gap);
  height: calc(100% + 2rem);
  padding-top: calc(var(--media-height) + 5rem);
  padding-bottom: 2rem;
  padding-left: calc(var(--item-offset) / 2);
  padding-right: calc(var(--item-offset) / 2);
}
.page-metodologia-floor .carousel {
  overflow: hidden;
  position: absolute;
  inset: 0 0 auto;
  height: var(--media-height);
  width: calc(var(--item-width) * 3 + var(--item-gap) * 2 + var(--item-offset));
}
.page-metodologia-floor .carousel .media {
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--media-width);
  height: var(--media-height);
  left: 50%;
  transform: translateX(-50%);
}
.page-metodologia-floor .slide {
  flex: 0 0 var(--item-width);
  scroll-snap-align: center;
  width: min(100%, 33.5rem);
  grid-auto-rows: minmax(min-content, max-content);
  display: grid;
  gap: 0.6rem;
  align-items: center;
  text-align: center;
}
.page-metodologia-floor .nav {
  display: flex;
  gap: 3rem;
  margin-top: auto;
  justify-content: center;
}
@media (min-width: 768px) {
  .page-metodologia-floor {
    --media-width: 164.2rem;
    --media-height: 62rem;
  }
  .page-metodologia-floor [data-ref-group=badges] {
    cursor: pointer;
  }
  .page-metodologia-floor .slider {
    position: relative;
    height: auto;
    padding-top: 0;
  }
  .page-metodologia-floor .carousel {
    width: 100%;
  }
  .page-metodologia-floor .scroller {
    overflow: hidden;
    height: var(--media-height);
    padding: 0;
    display: grid;
    justify-content: center;
    padding-top: 40rem;
  }
  .page-metodologia-floor .slide {
    grid-column: 1;
    grid-row: 1;
    width: 48.8rem;
  }
  .page-metodologia-floor .slide {
    opacity: 0;
    transition: opacity var(--slider-transition);
  }
  .page-metodologia-floor .slide1-badge,
.page-metodologia-floor .slide2-badge,
.page-metodologia-floor .slide3-badge {
    transform: translate(var(--translate, 0, 0)) scale(0.62);
    transition: transform var(--slider-transition);
  }
  .page-metodologia-floor .circle {
    transition: all var(--slider-transition);
    stroke-dasharray: 0, 2000;
    stroke-dashoffset: -240;
  }
  .page-metodologia-floor .slide1-badge {
    --translate: 13.7rem, 14.5rem;
  }
  .page-metodologia-floor .slide2-badge {
    --translate: 31.15rem, 10.25rem;
  }
  .page-metodologia-floor .slide3-badge {
    --translate: 48rem, 14.5rem;
  }
  .page-metodologia-floor .slider[data-slide="1"] .slide:nth-child(1) {
    opacity: 1;
  }
  .page-metodologia-floor .slider[data-slide="1"] .slide1-badge {
    transform: none;
  }
  .page-metodologia-floor .slider[data-slide="1"] .slide1-badge .circle {
    stroke-dasharray: 120, 2000;
    stroke-dashoffset: -705;
  }
  .page-metodologia-floor .slider[data-slide="2"] .slide:nth-child(2) {
    opacity: 1;
  }
  .page-metodologia-floor .slider[data-slide="2"] .slide2-badge {
    transform: none;
  }
  .page-metodologia-floor .slider[data-slide="2"] .slide2-badge .circle {
    stroke-dasharray: 120, 2000;
    stroke-dashoffset: -557;
  }
  .page-metodologia-floor .slider[data-slide="3"] .slide:nth-child(3) {
    opacity: 1;
  }
  .page-metodologia-floor .slider[data-slide="3"] .slide3-badge {
    transform: none;
  }
  .page-metodologia-floor .slider[data-slide="3"] .slide3-badge .circle {
    stroke-dasharray: 120, 2000;
    stroke-dashoffset: -660;
  }
  .page-metodologia-floor .nav {
    display: flex;
    gap: 3rem;
    margin-top: auto;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15rem;
    gap: 59.4rem;
  }
}
.page-tallers-floor {
  --height: 100vh;
  --slide1-color: var(--color-yellow);
  --slide2-color: var(--color-blue);
  --slide3-color: var(--color-green);
  --slide4-color: var(--color-red);
  position: relative;
  z-index: 3;
  padding-bottom: 9.1rem;
  --slide-transition: 0.3s ease-in-out;
}
.page-tallers-floor .pin-space {
  position: relative;
}
.page-tallers-floor .pin-element {
  left: 0;
  display: flex;
  place-content: center;
}
.page-tallers-floor .pin-element > div {
  width: 100vw;
  height: 100vh;
  inset: 0;
  display: grid;
  padding-top: 8rem;
  grid-template-rows: min-content min-content min-content auto;
  background: var(--bg-section);
  border-bottom-right-radius: 10rem;
  z-index: 1;
  isolation: isolate;
  place-content: center;
}
.page-tallers-floor .pin-element .content {
  display: grid;
}
.page-tallers-floor .pin-space[data-pinned=top] .pin-element {
  position: absolute;
  top: 0;
}
.page-tallers-floor .pin-space[data-pinned=center] .pin-element {
  position: fixed;
  top: 0;
}
.page-tallers-floor .pin-space[data-pinned=bottom] .pin-element {
  position: absolute;
  bottom: 0;
}
.page-tallers-floor .slides {
  position: relative;
  display: grid;
}
.page-tallers-floor .slide {
  flex: 0 0 100vw;
  grid-column: 1;
  grid-row: 1;
}
.page-tallers-floor .bullets {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  bottom: 1rem;
  padding: 2rem;
  margin-top: 2.4rem;
}
.page-tallers-floor .bullets li {
  --size: 1.8rem;
  width: var(--size);
  height: var(--size);
  border-radius: 9in;
  cursor: pointer;
}
.page-tallers-floor .bullets li:nth-child(1) {
  background: var(--color-red);
}
.page-tallers-floor .bullets li:nth-child(2) {
  background: var(--color-orange);
}
.page-tallers-floor .bullets li:nth-child(3) {
  background: var(--color-blue);
}
.page-tallers-floor .bullets li:nth-child(4) {
  background: var(--color-red);
}
.page-tallers-floor .slide,
.page-tallers-floor .num {
  opacity: 0;
  transition: opacity var(--slide-transition);
}
.page-tallers-floor .bullets li {
  transition: width var(--slide-transition);
}
.page-tallers-floor .nums {
  transition: color var(--slide-transition);
}
.page-tallers-floor[data-slide="1"] .slide:nth-child(1),
.page-tallers-floor[data-slide="1"] .num:nth-child(1) {
  opacity: 1;
}
.page-tallers-floor[data-slide="1"] .bullets li:nth-child(1) {
  width: 8rem;
}
.page-tallers-floor[data-slide="1"] .nums {
  color: var(--slide1-color);
}
.page-tallers-floor[data-slide="2"] .slide:nth-child(2),
.page-tallers-floor[data-slide="2"] .num:nth-child(2) {
  opacity: 1;
}
.page-tallers-floor[data-slide="2"] .bullets li:nth-child(2) {
  width: 8rem;
}
.page-tallers-floor[data-slide="2"] .nums {
  color: var(--slide2-color);
}
.page-tallers-floor[data-slide="3"] .slide:nth-child(3),
.page-tallers-floor[data-slide="3"] .num:nth-child(3) {
  opacity: 1;
}
.page-tallers-floor[data-slide="3"] .bullets li:nth-child(3) {
  width: 8rem;
}
.page-tallers-floor[data-slide="3"] .nums {
  color: var(--slide3-color);
}
.page-tallers-floor .pas-a-pas {
  margin-left: auto;
  margin-right: auto;
  max-width: 22.4rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .page-tallers-floor .bullets {
    position: absolute;
    top: 70rem;
    left: 50%;
  }
  .page-tallers-floor .pas-a-pas {
    max-width: 45rem;
    margin-top: 3.9rem;
  }
}
@media (min-width: 960px) {
  .page-tallers-floor {
    padding-bottom: 6rem;
  }
  .page-tallers-floor .bullets {
    top: 55rem;
  }
}
.taller-slide {
  --inline-padding: 1rem;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3.8rem;
  display: grid;
  gap: 4rem;
}
.taller-slide .taller-slide-media {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taller-slide .taller-slide-media img {
  max-width: 18.3rem;
  filter: drop-shadow(var(--media-shadow));
}
.taller-slide .taller-slide-media::before {
  content: "";
  display: block;
  position: absolute;
  width: 37.5rem;
  height: 28.9rem;
  top: 6.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  background: no-repeat center/contain;
  background-image: var(--shape);
}
.taller-slide .taller-slide-content {
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-align: center;
}
.taller-slide[data-variant=red] {
  --media-shadow: 0 2rem 4rem rgba(255, 96, 79, 0.4);
  --shape: url("data:image/svg+xml,%3Csvg width='468' height='484' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.514 68.674c-.84-11.993 8.104-22.44 20.083-23.458l356.953-30.35c12.118-1.03 22.773 7.967 23.788 20.086l33.56 400.937c1.112 13.284-9.75 24.496-23.062 23.806l-365.89-18.97c-11.102-.575-20.03-9.343-20.806-20.433L16.514 68.674Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
.taller-slide[data-variant=orange] {
  --media-shadow: 0 2rem 4rem rgba(247, 186, 138, 0.4);
  --shape: url("data:image/svg+xml,%3Csvg width='468' height='484' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.683 71.38c-.358-13.562 11.526-24.213 24.968-22.378l328.254 44.822c11.143 1.522 19.345 11.202 19.014 22.444l-9.81 334.007c-.342 11.64-9.694 20.997-21.333 21.345l-308.208 9.22c-12.175.364-22.329-9.234-22.65-21.41L47.683 71.38Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
.taller-slide[data-variant=blue] {
  --media-shadow: 0 2rem 4rem rgba(162, 189, 243, 0.4);
  --shape: url("data:image/svg+xml,%3Csvg width='468' height='484' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.088 443.244c-12.628 1.368-23.787-8.21-24.349-20.899L7.921 87.5c-.493-11.147 7.437-20.899 18.451-22.688l340.301-55.27c11.803-1.918 22.977 5.944 25.158 17.702l64.533 347.846c2.335 12.589-6.531 24.505-19.261 25.885L47.088 443.244Z' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
@media (min-width: 768px) {
  .taller-slide {
    --container-width: 96rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .taller-slide .taller-slide-media {
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 7.8rem;
  }
  .taller-slide .taller-slide-media img {
    max-width: 28.2rem;
  }
  .taller-slide .taller-slide-media::before {
    width: 46.8rem;
    height: 48.4rem;
    top: -11rem;
    margin-left: -3rem;
  }
  .taller-slide .taller-slide-content {
    padding-top: 2.4rem;
    font-size: 2.4rem;
    line-height: 3.8rem;
    text-align: left;
  }
}
.page-materials-floor .products {
  display: grid;
  gap: 12rem;
  padding-top: 5rem;
  padding-bottom: 8rem;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .page-materials-floor {
    padding-bottom: 9.7rem;
  }
  .page-materials-floor .products {
    --container-width: 114rem;
    gap: 15.4rem;
  }
}
.page-facilitats-floor {
  z-index: 2;
}
.page-facilitats-floor .cover {
  position: relative;
  z-index: 2;
  margin-bottom: -5.2rem;
  min-height: 67rem;
}
.page-facilitats-floor .cover .products-list {
  position: absolute;
  z-index: 1;
  inset: auto 2rem 2.8rem;
  color: var(--color-white);
}
.page-facilitats-floor .content {
  --inline-padding: 1rem;
  position: relative;
  display: grid;
  z-index: 1;
}
.page-facilitats-floor .numbered-list {
  padding: 10.5rem 1.2rem 5.2rem;
  background: var(--color-white);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  border-radius: 22px;
}
.page-facilitats-floor .products-wrapper {
  display: none;
}
@media (min-width: 960px) {
  .page-facilitats-floor .cover {
    padding-top: 22.3rem;
    padding-bottom: 47.9rem;
  }
  .page-facilitats-floor .cover .lead {
    max-width: 46.7rem;
  }
  .page-facilitats-floor .cover .products-list {
    display: none;
  }
  .page-facilitats-floor .content {
    --container-width: 113rem;
    --inline-padding: 2rem;
    --border-radius: 6rem;
    display: grid;
    z-index: 3;
    margin-top: -42.5rem;
    grid-template-columns: 380fr 173fr 576fr;
    grid-template-columns: 380fr 173fr 707fr;
  }
  .page-facilitats-floor .content .products-wrapper {
    position: relative;
    isolation: isolate;
    display: block;
    grid-column: 1/span 2;
    grid-row: 1;
    background: #0005;
    border-radius: var(--border-radius) 9in 9in var(--border-radius);
    padding: 1.7rem;
    overflow: hidden;
  }
  .page-facilitats-floor .content .numbered-list {
    grid-column: 2/span 2;
    grid-row: 1;
    padding: 3.6rem 3.4rem 4.4rem calc(var(--computed-width) * 0.173 + 4rem);
    border-radius: 9in var(--border-radius) var(--border-radius) 9in;
  }
}
.page-demos-floor {
  padding-top: 5rem;
  padding-bottom: 11.4rem;
}
.page-demos-floor .head {
  margin-bottom: 7rem;
}
.page-demos-floor .collections {
  display: grid;
  gap: 2rem;
}
.page-demos-floor .collection {
  --item-gap: 0.8rem;
  --item-width: 14.4rem;
  display: grid;
  gap: 2rem;
  overflow: hidden;
}
.page-demos-floor .collection .h2 {
  color: var(--color-professor1);
}
.page-demos-floor .demos .scroller {
  padding-bottom: 12rem;
}
.page-demos-floor .demos[data-variant=hibrid] {
  --media-width: 20.5rem;
  --media-height: 25.2rem;
}
.page-demos-floor .demos[data-variant=digital] {
  --media-width: 22.9rem;
  --media-height: 32.1rem;
}
.page-demos-floor .collection[data-variant=red] img {
  box-shadow: 0px 20px 40px -20px rgba(255, 96, 79, 0.6);
}
.page-demos-floor .collection[data-variant=orange] img {
  box-shadow: 0px 20px 40px -20px rgba(247, 186, 138, 0.84);
}
.page-demos-floor .collection[data-variant=blue] img {
  box-shadow: 0px 20px 40px -20px rgba(162, 189, 243, 0.61);
}
.page-demos-floor .collection[data-variant=green] img {
  box-shadow: 0px 20px 40px -20px rgba(68, 200, 135, 0.57);
}
.page-demos-floor .collection[data-direction=even] .h2 {
  text-align: left;
}
.page-demos-floor .collection[data-direction=odd] .h2 {
  text-align: right;
}
.page-demos-floor .list {
  height: 200vh;
}
.page-demos-floor .list .parallax-element {
  width: 290.5rem;
  height: 191rem;
  background-image: url("data:image/svg+xml,%3Csvg width='2905' height='1910' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M620.029 26.552c258.219 39.924 507.291 125.45 735.651 252.604a216.382 216.382 0 0 1 68.12 53.742 217.078 217.078 0 0 1 41.62 76.24 217.531 217.531 0 0 1 8.43 86.516 217.465 217.465 0 0 1-26.1 82.922 217.14 217.14 0 0 1-56.44 66.036 216.634 216.634 0 0 1-77.75 38.562 216.082 216.082 0 0 1-86.58 4.908 216.117 216.117 0 0 1-81.54-29.535C862.287 501.634 542.022 424.435 218.557 435.125c-56.541.558-111.042-21.073-151.858-60.271C25.883 335.655 1.987 281.998.12 225.345c-1.87-56.652 18.436-111.807 56.578-153.68C94.838 29.79 147.792 4.519 204.244 1.249A2225.487 2225.487 0 0 1 620.371 26.37M2168.9 890.996a2205.482 2205.482 0 0 1 485.78 607.574 216.42 216.42 0 0 1 28.72 81.89c3.96 28.97 2.02 58.45-5.71 86.67a217.4 217.4 0 0 1-39.23 77.57 217.508 217.508 0 0 1-66.46 56.02 217.165 217.165 0 0 1-83.03 25.5c-29.1 2.82-58.46-.28-86.31-9.11a216.206 216.206 0 0 1-75.73-42.27 216.195 216.195 0 0 1-53.02-68.64c-155-284.24-384.04-521.08-662.87-685.43-48.03-29.835-82.46-77.309-95.92-132.282-13.46-54.972-4.89-113.083 23.88-161.916 28.78-48.834 75.47-84.518 130.11-99.428 54.63-14.91 112.88-7.865 162.3 19.631a2226.39 2226.39 0 0 1 337.88 244.25' fill='%23F5F7FA'/%3E%3C/svg%3E");
}
@media (min-width: 1034px) {
  .page-demos-floor {
    position: relative;
    z-index: -1;
    isolation: isolate;
    padding-top: 18rem;
  }
  .page-demos-floor .collection {
    --container-width: 136rem;
    --item-gap: 2.6rem;
    --item-width: 100%;
  }
  .page-demos-floor .collection .h2 {
    color: #000;
  }
  .page-demos-floor .demos {
    --padding-inline: calc(50vw - var(--container-width) / 2);
  }
  .page-demos-floor .collection[data-direction=odd] .scroller {
    justify-content: flex-end;
  }
  .page-demos-floor .parallax-element {
    z-index: -1;
  }
  .page-demos-floor[data-variant=digital] .collection {
    --item-width: clamp(17.6rem, 20vw, 32.1rem);
  }
}
.page-project-floor {
  display: grid;
  --padding-inline: 1rem;
  --overlap: 3.8rem;
  grid-template-columns: var(--padding-inline) auto var(--padding-inline);
  grid-template-rows: auto var(--overlap) auto auto;
}
.page-project-floor .cover {
  grid-row: 1;
  grid-column: 2;
  padding: 0 1rem;
  gap: 0;
  min-height: 0;
}
.page-project-floor .cover .head {
  position: relative;
}
.page-project-floor .cover .cover-content {
  padding-top: 14.8rem;
  padding-bottom: 2rem;
}
.page-project-floor .cover .cover-content .h2 {
  margin: 1.2rem 0;
}
.page-project-floor .cover .cover-content .lead {
  --font-weight: 400;
}
.page-project-floor .background {
  position: relative;
  grid-row: 1/span 2;
  grid-column: 1/span 3;
}
.page-project-floor .background img {
  border-radius: 2.4rem;
}
.page-project-floor .items {
  grid-column: 2;
  grid-row: 2/span 2;
  padding: 4rem 1.2rem;
  background: #fff;
  display: grid;
  gap: 2.2rem;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  border-radius: 1.8rem;
  text-align: center;
}
.page-project-floor .item .num {
  font-size: 4.32rem;
  line-height: 5.76rem;
  color: var(--color-blue);
  font-weight: 700;
}
.page-project-floor .item .title {
  font-size: 2.6rem;
  line-height: 3.2rem;
  font-weight: 700;
}
.page-project-floor .item p {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.page-project-floor .collection {
  padding-top: 6.4rem;
  padding-bottom: 5.8rem;
  grid-column: 1/span 3;
  grid-row: 4;
  display: grid;
  gap: 2.8rem;
}
.page-project-floor .collection .sub2 {
  text-align: center;
}
@media (min-width: 768px) {
  .page-project-floor {
    padding-bottom: 8.6rem;
    --padding-inline: max(2rem, calc(50vw - 60.4rem));
    --overlap: 3.8rem;
    grid-template-rows: auto auto var(--overlap) auto;
  }
  .page-project-floor .cover {
    padding: 0 4rem;
  }
  .page-project-floor .cover .cover-content {
    padding-bottom: 7rem;
  }
  .page-project-floor .items {
    padding: 6.4rem 6.3rem 5.7rem;
    text-align: left;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 3.4rem;
  }
  .page-project-floor .item {
    position: relative;
    padding-left: 6.3rem;
    max-width: 47.3rem;
  }
  .page-project-floor .item .num {
    position: absolute;
    left: 0;
    top: -0.2rem;
  }
  .page-project-floor .item .title {
    margin-bottom: 0.6rem;
  }
  .page-project-floor .item:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
  .page-project-floor .scroller {
    padding-bottom: 7.4rem;
  }
  .page-project-floor .collection {
    padding-top: 8.4rem;
    padding-bottom: 0;
    gap: 4rem;
  }
}
.index-hero-floor {
  --media-height: 29.4rem;
  padding-top: 15rem;
  padding-bottom: calc(var(--media-height) + 5.6rem);
  background: var(--color-professor1);
  color: #fff;
}
.index-hero-floor .logo {
  position: absolute;
  top: 4rem;
  left: 2.2rem;
  width: 10.7rem;
}
.index-hero-floor .h1 {
  margin-top: 2.9rem;
  margin-bottom: 2rem;
}
.index-hero-floor .h1 b {
  color: #eb61a6;
}
.index-hero-floor .scroll {
  position: absolute;
  left: 50%;
  bottom: var(--media-height);
  transform: translate(-50%, 50%);
}
.index-hero-floor .background {
  height: var(--media-height);
  top: auto;
}
.index-hero-floor .background img {
  border-top-left-radius: 10rem;
}
@media (min-width: 768px) {
  .index-hero-floor {
    border-radius: 0 0 30rem 0;
    padding-top: 14rem;
    padding-bottom: 14rem;
    min-height: max(79rem, 100vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .index-hero-floor .logo {
    left: 4rem;
    width: 12.4rem;
  }
  .index-hero-floor .h1 {
    --font-size: 4.8rem;
    --line-height: 4.8rem;
  }
  .index-hero-floor .scroll {
    bottom: 6.5rem;
  }
  .index-hero-floor .background {
    top: 0;
    height: auto;
    left: auto;
    width: 50%;
    border-radius: 0 0 30rem 0;
    overflow: hidden;
  }
  .index-hero-floor .background img {
    border-radius: 0;
  }
  .index-hero-floor .background video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
  .index-hero-floor .content {
    width: 50%;
    padding-right: 10rem;
  }
}
@media (min-width: 1025px) {
  .index-hero-floor .h1 {
    --font-size: 8rem;
    --line-height: 8.2rem;
  }
  .index-hero-floor .content {
    padding-right: 13rem;
  }
}
.index-projects-floor {
  padding-top: 2rem;
  position: relative;
  overflow: hidden;
}
.index-projects-floor .parallax-element > div {
  --size: 31.4rem;
  width: var(--size);
  height: var(--size);
  background-image: url("data:image/svg+xml,%3Csvg width='314' height='314' viewBox='0 0 314 314' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='157' cy='157' r='128.743' stroke='%231E1348' stroke-opacity='.05' stroke-width='56.513'/%3E%3C/svg%3E");
}
.index-projects-floor .parallax-element1 {
  top: 16rem;
  right: -16rem;
}
.index-projects-floor .parallax-element2 {
  bottom: 72rem;
  left: -15rem;
}
@media (min-width: 768px) {
  .index-projects-floor {
    position: relative;
    padding-top: 1rem;
  }
  .index-projects-floor .head {
    padding-top: 0;
    margin-bottom: 4rem;
  }
  .index-projects-floor::before {
    content: "";
    position: absolute;
    inset: auto 0 4rem;
    height: 5rem;
    border-bottom-right-radius: 5rem;
    background: #fff;
  }
  .index-projects-floor .parallax-element > div {
    --size: 49.9rem;
    background-image: url("data:image/svg+xml,%3Csvg width='499' height='499' viewBox='0 0 499 499' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M449 249.5C449 359.681 359.681 449 249.5 449S50 359.681 50 249.5 139.319 50 249.5 50 449 139.319 449 249.5Z' stroke='%231E1348' stroke-opacity='.05' stroke-width='100'/%3E%3C/svg%3E");
  }
  .index-projects-floor .parallax-element1 {
    top: 15rem;
    right: -25.4rem;
  }
  .index-projects-floor .parallax-element2 {
    bottom: 72rem;
    left: calc(50% - 16rem);
  }
}
.index-project__media {
  position: relative;
}
.index-project__media .bg {
  height: 37rem;
  object-fit: cover;
}
.index-project__media .logo.xs {
  position: absolute;
  bottom: 0;
  width: auto;
  height: 14.8rem;
}
.index-project__content {
  padding: 2.2rem 2.2rem 4.6rem 1.8rem;
}
.index-project__title {
  font-size: 2.6rem;
  line-height: 3.4rem;
  font-weight: bold;
}
.index-project__body {
  margin-top: 1rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.index-project__body p + p {
  margin-top: 1.4rem;
}
.index-project__tags {
  margin-top: 1.8rem;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
}
.index-project__cta {
  margin-top: 3.7rem;
}
.index-project__tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3.2rem;
  padding: 0.3rem 1.3rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0.8rem 2.5rem -1rem rgba(30, 19, 72, 0.08);
  border-radius: 9in;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 700;
  white-space: nowrap;
}
.index-project__tag[data-variant=red] {
  color: var(--color-red);
}
.index-project__tag[data-variant=orange] {
  color: var(--color-orange);
}
.index-project__tag[data-variant=blue] {
  color: var(--color-blue);
}
.index-project__tag[data-variant=green] {
  color: var(--color-green);
}
:where([data-even]) .index-project__media {
  margin-right: 3.5rem;
}
:where([data-even]) .index-project__media .bg {
  border-radius: 0 9in 9in 0;
}
:where([data-even]) .index-project__media .logo.xs {
  margin-left: -5rem;
}
:where([data-odd]) .index-project__media {
  margin-left: 3.5rem;
}
:where([data-odd]) .index-project__media .bg {
  border-radius: 9in 0 0 9in;
}
:where([data-brand="blue"]) .index-project__media {
  padding-bottom: 2rem;
}
:where([data-brand="blue"]) .index-project__cta {
  --bg: var(--color-blue);
  --bg-hover: var(--color-blue);
  --bg-active: var(--color-blue);
}
:where([data-brand="red"]) .index-project__media {
  padding-bottom: 3.7rem;
}
:where([data-brand="red"]) .index-project__cta {
  --bg: var(--color-red);
  --bg-hover: var(--color-red);
  --bg-active: var(--color-red);
}
:where([data-brand="green"]) .index-project__media {
  padding-bottom: 3.7rem;
}
:where([data-brand="green"]) .index-project__cta {
  --bg: var(--color-green);
  --bg-hover: var(--color-green);
  --bg-active: var(--color-green);
}
@media (min-width: 768px) {
  .index-project {
    display: flex;
  }
  .index-project[data-odd] {
    flex-direction: row-reverse;
  }
  .index-project__title {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
  .index-project__body {
    margin-top: 2.6rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .index-project__tags {
    margin-top: 2.4rem;
  }
  .index-project__cta {
    margin-top: 2.8rem;
  }
  .index-project__media {
    flex: 50%;
    margin: 0;
    padding: 0;
  }
  .index-project__media .bg {
    min-height: 69rem;
    width: 100%;
  }
  .index-project__media .logo.lg {
    position: absolute;
    height: 26rem;
    width: auto;
    max-width: none;
  }
  .index-project__content {
    flex: 50%;
    display: flex;
    align-items: center;
  }
  .index-project__content > div {
    max-width: 51rem;
  }
  :where([data-even]) .index-project__media {
    margin-right: 0;
    padding-right: 3rem;
  }
  :where([data-even]) .index-project__media .bg {
    border-radius: 0 9in 9in 0;
  }
  :where([data-even]) .index-project__media .logo {
    margin-left: -5rem;
  }
  :where([data-even]) .index-project__content {
    padding-left: 5.8rem;
    justify-content: flex-start;
  }

  :where([data-odd]) .index-project__media {
    margin-left: 0;
    padding-left: 3rem;
  }
  :where([data-odd]) .index-project__media .bg {
    border-radius: 9in 0 0 9in;
  }
  :where([data-odd]) .index-project__content {
    padding-right: 5.8rem;
    justify-content: flex-end;
  }

  :where([data-brand="blue"]) .index-project__media .logo.lg {
    top: 3.8rem;
    right: 1.1rem;
  }

  :where([data-brand="red"]) .index-project__media .logo.lg {
    top: 6.5rem;
    left: -3.5rem;
  }

  :where([data-brand="green"]) .index-project__media .logo.lg {
    top: 11.5rem;
    right: -1rem;
  }
}
.index-video-floor {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  justify-content: center;
  align-items: center;
  background: #4aadf8;
  color: #fff;
  padding: 0 2rem 4rem;
}
.index-video-floor .head {
  --color: #fff;
  --dot-color: var(--color-professor1);
  --body-color: #fff;
}
.index-video-floor__video a {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 1.2rem;
}
.index-video-floor__video .video-play {
  --size: 9.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--size);
  height: var(--size);
}
.index-video-floor__video iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}
.index-video-floor__video .active iframe {
  opacity: 1;
  pointer-events: all;
}
@media (min-width: 768px) {
  .index-video-floor {
    gap: 3rem;
    padding: 5rem 2rem 18rem;
    padding-top: 12rem;
    margin-top: -9rem;
  }
  .index-video-floor__video {
    max-width: 113rem;
  }
  .index-video-floor__video a {
    border-radius: 2.2rem;
  }
  .index-video-floor__video .video-play {
    --size: 16rem;
  }
}
.index-testimonals-floor {
  --offset-bottom: 29rem;
  position: relative;
  height: 80rem;
}
@media (min-width: 768px) {
  .index-testimonals-floor {
    height: calc(88rem + var(--offset-bottom));
    margin-bottom: calc(var(--offset-bottom) * -1);
    padding-top: 0;
    padding-bottom: var(--offset-bottom);
  }
}
.index-testimonals-slider {
  position: relative;
  display: grid;
  width: 100vw;
  user-select: none;
  overflow: hidden;
  height: 100%;
}
.index-testimonal {
  --brand-color: var(--color-blue);
  --max-width: 50rem;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 11rem 2rem 0;
  text-align: center;
  color: #fff;
}
.index-testimonal img {
  --size: 17.4rem;
  width: var(--size);
  height: var(--size);
  border-radius: 9in;
  border: 4px solid var(--brand-color);
  pointer-events: none;
}
.index-testimonal__title {
  max-width: var(--max-width);
  position: relative;
  margin-top: 3.4rem;
  margin-bottom: 5.4rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-style: italic;
}
.index-testimonal__title b {
  position: relative;
}
.index-testimonal__title b::before {
  content: "";
  position: absolute;
  display: block;
  background: var(--brand-color);
  inset: auto -0.7rem -0.1rem -1.4rem;
  height: 2.2rem;
  font-weight: 600;
  box-shadow: 0px 1rem 1.2rem -0.4rem rgba(0, 0, 0, 0.14);
  border-radius: 9in;
  z-index: -1;
}
.index-testimonal__title::after {
  content: "";
  display: block;
  position: absolute;
  --size: 2.4rem;
  width: var(--size);
  height: var(--size);
  background: var(--brand-color);
  border-radius: 9in;
  bottom: -1rem;
  left: 50%;
  transform: translate(-50%, 100%);
}
.index-testimonal__name {
  max-width: var(--max-width);
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 700;
  letter-spacing: 1%;
}
.loading .index-testimonal:not(:first-child) {
  display: none;
}
@media (min-width: 768px) {
  .index-testimonal {
    --max-width: 90rem;
    padding-top: 18rem;
  }
  .index-testimonal__title {
    margin-top: 3.4rem;
    margin-bottom: 6.3rem;
    font-size: 3.8rem;
    line-height: 5.2rem;
  }
  .index-testimonal__title b::before {
    inset: auto 0.7rem -0.1rem -0.8rem;
    height: 2.6rem;
  }
  .index-testimonal__name {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
}
.index-testimonal[data-brand=blue] {
  --brand-color: var(--color-blue);
}
.index-testimonal[data-brand=red] {
  --brand-color: var(--color-red);
}
.index-testimonal[data-brand=green] {
  --brand-color: var(--color-green);
}
.index-testimonals-nav {
  display: none;
  position: absolute;
  display: flex;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;
  inset: auto 0 3rem;
}
.index-testimonals-nav__item {
  --size: 1.6rem;
  width: var(--size);
  height: var(--size);
  opacity: 0.4;
  transition: width 0.25s ease-in-out, opacity 0.25s ease-in-out;
  background: #fff;
  border-radius: 9in;
  cursor: pointer;
}
.index-testimonals-nav__item.active {
  width: 4.4rem;
  opacity: 1;
}
@media (min-width: 768px) {
  .index-testimonals-nav {
    bottom: calc(4.7rem + var(--offset-bottom));
  }
}
.page-sequencies-hero-floor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 27rem;
  padding-bottom: 5.6rem;
  color: var(--color-professor1);
}
.page-sequencies-hero-floor .media {
  height: 44rem;
  inset: 0 0 auto;
}
.page-sequencies-hero-floor .media img {
  left: 50%;
  transform: translateX(-50%);
  object-fit: fill;
  object-position: top center;
  height: 44rem;
  width: 82.3rem;
  max-width: none;
}
.page-sequencies-hero-floor .content {
  max-width: 80vw;
}
.page-sequencies-hero-floor .h1,
.page-sequencies-hero-floor .sub1 {
  margin-top: 1.2rem;
}
@media (min-width: 768px) {
  .page-sequencies-hero-floor {
    --container-width: 113.8rem;
    min-height: 70.6rem;
    padding-top: 18rem;
    padding-bottom: 5.6rem;
  }
  .page-sequencies-hero-floor .media {
    height: 78.7rem;
  }
  .page-sequencies-hero-floor .media img {
    height: 78.7rem;
    width: 172rem;
  }
  .page-sequencies-hero-floor .content {
    max-width: 80vw;
    width: min(100%, 66.6rem);
  }
  .page-sequencies-hero-floor .h1 {
    margin-top: 2.9rem;
  }
  .page-sequencies-hero-floor .sub1 {
    margin-top: 1.8rem;
  }
}
.page-sequencies-metodologia-floor {
  margin-bottom: 5.6rem;
}
.page-sequencies-metodologia-floor .head {
  margin-bottom: 1rem;
}
.page-sequencies-metodologia-floor .sequencia + .sequencia {
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .page-sequencies-metodologia-floor {
    margin-bottom: 19.6rem;
  }
  .page-sequencies-metodologia-floor .head {
    margin-bottom: 2.4rem;
  }
  .page-sequencies-metodologia-floor .sequencia + .sequencia {
    margin-top: 14.4rem;
  }
}
.page-sequencies-intro-floor {
  padding-top: 20.4rem;
  padding-bottom: 22.4rem;
  text-align: center;
}
.page-sequencies-intro-floor .content {
  max-width: 40rem;
  margin: 0 auto;
}
.page-sequencies-intro-floor .h2 b {
  display: block;
  font-size: 11rem;
  line-height: 1;
  color: var(--color-professor2);
}
.page-sequencies-intro-floor .lead {
  font-weight: 400;
  margin-top: 1.2rem;
}
.page-sequencies-intro-floor [data-label] {
  --size: 13.9rem;
  position: absolute;
  width: var(--size);
  height: var(--size);
}
.page-sequencies-intro-floor [data-label] > div {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.01em;
  color: var(--brand);
  position: absolute;
}
.page-sequencies-intro-floor [data-label] svg circle {
  fill: var(--brand);
}
.page-sequencies-intro-floor [data-label]::before {
  content: "";
  position: absolute;
  display: block;
  width: 50.7rem;
  height: 21.2rem;
  background-image: url("data:image/svg+xml,%3Csvg width='507' height='212' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='507' height='212' rx='100' fill='%23F5F7FA'/%3E%3C/svg%3E");
  z-index: -1;
}
.page-sequencies-intro-floor [data-label="1"] {
  top: 3.8rem;
  left: 4.4rem;
}
.page-sequencies-intro-floor [data-label="1"] > div {
  top: 7.2rem;
  left: 12.3rem;
}
.page-sequencies-intro-floor [data-label="1"]::before {
  top: 3.7rem;
  left: 4.2rem;
}
.page-sequencies-intro-floor [data-label="2"] {
  bottom: 5.2rem;
  right: 1.4rem;
}
.page-sequencies-intro-floor [data-label="2"] > div {
  bottom: 4.2rem;
  right: 10.2rem;
}
.page-sequencies-intro-floor [data-label="2"]::before {
  bottom: 2.8rem;
  right: 3.4rem;
}
@media (min-width: 768px) {
  .page-sequencies-intro-floor {
    padding-top: 22.7rem;
    padding-bottom: 12.7rem;
  }
  .page-sequencies-intro-floor .h2 b {
    font-size: 17.8rem;
    margin-bottom: -2rem;
  }
  .page-sequencies-intro-floor .lead {
    margin-top: 2rem;
  }
  .page-sequencies-intro-floor [data-label="1"] {
    top: calc(50% - 18rem);
    left: 4.4rem;
  }
  .page-sequencies-intro-floor [data-label="2"] {
    top: calc(50% - 3rem);
    bottom: auto;
    right: 1.4rem;
  }
  .page-sequencies-intro-floor [data-label="2"]::before {
    top: 3.8rem;
    bottom: auto;
  }
}
@media (min-width: 960px) {
  .page-sequencies-intro-floor [data-label="1"] {
    left: calc(50% - 55rem);
  }
  .page-sequencies-intro-floor [data-label="2"] {
    right: calc(50% - 55rem);
  }
}
.page-sequencies-facilitats-floor {
  z-index: 2;
  margin-bottom: 5rem;
}
.page-sequencies-facilitats-floor .cover {
  position: relative;
  z-index: 2;
  margin-bottom: -5.2rem;
  min-height: 55.2rem;
  justify-content: flex-end;
}
.page-sequencies-facilitats-floor .content {
  --inline-padding: 1rem;
  position: relative;
  display: grid;
  z-index: 1;
}
.page-sequencies-facilitats-floor .numbered-list {
  padding: 10.5rem 1.2rem 40%;
  background: var(--color-white);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 50vw 50vw;
}
.page-sequencies-facilitats-floor .features-list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 45% 2rem 4rem;
  background: #5c4d9b;
  border-radius: 50vw 50vw 2.2rem 2.2rem;
  margin-top: -30vw;
}
.page-sequencies-facilitats-floor .features-list .h3 {
  color: var(--color-professor3);
}
.page-sequencies-facilitats-floor .features-list ul {
  display: grid;
  gap: 2.2rem;
}
.page-sequencies-facilitats-floor .features-list li {
  padding-left: 4.5rem;
  background: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.998 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Zm4.449-11.152a1.2 1.2 0 0 0-1.697-1.697l-3.952 3.952-1.551-1.551a1.2 1.2 0 0 0-1.697 1.696l2.4 2.4a1.2 1.2 0 0 0 1.697 0l4.8-4.8Z' fill='%23B2A4D2'/%3E%3C/svg%3E");
  color: #fff;
}
@media (min-width: 960px) {
  .page-sequencies-facilitats-floor {
    margin-bottom: 18rem;
  }
  .page-sequencies-facilitats-floor .cover {
    padding-top: 22.3rem;
    padding-bottom: 47.9rem;
  }
  .page-sequencies-facilitats-floor .cover .lead {
    max-width: 46.7rem;
  }
  .page-sequencies-facilitats-floor .cover .products-list {
    display: none;
  }
  .page-sequencies-facilitats-floor .content {
    --container-width: 113rem;
    --inline-padding: 2rem;
    --border-radius: 6rem;
    display: grid;
    z-index: 3;
    margin-top: -42.5rem;
    grid-template-columns: 380fr 173fr 576fr;
    grid-template-columns: 380fr 173fr 707fr;
  }
  .page-sequencies-facilitats-floor .content .numbered-list {
    grid-column: 2/span 2;
    grid-row: 1;
    padding: 3.8rem 3.4rem 4.4rem calc(var(--computed-width) * 0.173 + 4rem);
    border-radius: 9in var(--border-radius) var(--border-radius) 9in;
  }
  .page-sequencies-facilitats-floor .content .features-list {
    grid-column: 1/span 2;
    grid-row: 1;
    margin: 0;
    padding: 0;
    border-radius: var(--border-radius) 9in 9in var(--border-radius);
    padding: 4.2rem 4rem 4.2rem 3.2rem;
  }
  .page-sequencies-facilitats-floor .content .features-list ul {
    gap: 1.8rem;
  }
}
.root-floor {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--color-professor1);
}
#loader {
  position: fixed;
  inset: 0;
  background: var(--clr-accent);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
  transition: opacity 300ms;
}
#loader::before {
  content: "";
  display: flex;
  width: 3vmin;
  height: 3vmin;
  background: var(--clr-dark);
  border-radius: 50%;
  animation: loader-pulse 1000ms linear infinite;
}
@keyframes loader-pulse {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  20% {
    transform: scale(0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
body:not(.loaded) #loader {
  opacity: 1;
}
/*# sourceMappingURL=main.css.map */
