.demos {
  --gap: 0.82rem;

  &[data-variant="hibrid"] {
    --media-width: 14.3rem;
    --media-height: 17.6rem;
  }
  &[data-variant="digital"] {
    --media-width: 24.6rem;
    --media-height: 17.6rem;
  }

  overflow-y: hidden;

  .scroll-wrap {
    overflow-x: auto;
    margin-bottom: -2rem;
    scroll-snap-type: x mandatory;
  }

  .scroller {
    display: flex;
    padding-left: 2rem;
    gap: var(--gap);

    height: calc(100% + 2rem);
    padding-bottom: calc(8rem + 2rem);
  }

  .demo {
    flex: 0 0 var(--media-width);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -2rem;
      scroll-snap-align: start;
    }

    &:last-child {
      flex: 0 0 calc(var(--media-width) + 2rem);
      padding-right: 2rem;
    }

    img {
      box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.25);
      border-radius: 1.2rem;
      position: relative;
      z-index: 2;
    }

    a,
    button {
      display: block;
      padding: 0;
      border: 0;
      background: none;
      cursor: pointer;
    }

    .dropdown {
      position: absolute;
      background: #fff8;
      border: 2px solid rgba(0, 0, 0, 0.05);
      border-top: 0;
      border-radius: 0 0 1.1rem 1.1rem;
      inset: 100% 0.7rem auto;
      transform: translateY(-100%);
      transition: transform 0.2s ease-in-out;
      padding: 0.5rem 0.5rem;
      z-index: 1;

      li {
        padding: 0.7rem 0.2rem;
        + li {
          border-top: 2px solid rgba(#1e1348, 0.05);
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--color-professor1);
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.6rem;

        &::after {
          flex: 0 0 2.4rem;
          content: "";
          display: block;
          width: 2.4rem;
          height: 2.4rem;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21.6a9.6 9.6 0 1 0 0-19.199 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.697 1.697l1.552 1.551H8.399a1.2 1.2 0 1 0 0 2.4h4.304l-1.552 1.552a1.2 1.2 0 1 0 1.697 1.697l3.6-3.6a1.2 1.2 0 0 0 0-1.697Z' fill='%23FF604F'/%3E%3C/svg%3E");
        }
      }
    }
    .open .dropdown {
      transform: translateY(0);
    }
  }

  @media (min-width: $lg) {
    --padding-inline: 2rem;
    padding-left: var(--padding-inline);
    padding-right: var(--padding-inline);

    .scroll-wrap {
      overflow-x: hidden;
      margin-bottom: 0;
      scroll-snap-type: none;
    }

    .scroller {
      // width: calc(var(--media-width) * var(--nb-items) + var(--gap) * (var(--nb-items) - 1));
      display: flex;
      padding-left: 2rem;
      padding-right: 2rem;
      gap: var(--gap);

      height: auto;
      padding-bottom: 0;
    }

    .demo {
      &,
      &:last-child {
        flex: 1 1 var(--media-width);
        padding-right: 0;
      }
    }
  }

  @media (min-width: $xl) {
    --gap: 2.2rem;
    --padding-inline: calc(50vw - 56.5rem);
  }

  // .scroll-wrap {
  //   width: 100vw;
  //   overflow-y: hidden;
  //   overflow-x: auto;
  //   scroll-snap-type: x mandatory;
  //   height: calc(100% + 2rem);
  // }

  // .scroller {
  //   display: flex;
  //   // gap: var(--item-gap);
  // }

  // .demo {
  //   scroll-snap-align: start;
  //   width: min-content;
  //   margin-bottom: 12rem;
  //   margin-left: -2rem;
  //   padding-left: 2rem;

  //   &:first-child {
  //     margin-left: 0;
  //   }
  //   &:last-child {
  //     padding-right: 2rem;
  //   }

  //   > div {
  //     width: min-content;
  //     position: relative;
  //   }

  //   button {
  //     all: unset;
  //     position: relative;
  //     display: block;
  //     cursor: pointer;
  //     z-index: 2;
  //   }

  //   img {
  //     width: var(--item-width);
  //     max-width: none;
  //     border-radius: 1.2rem;
  //     box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.25);
  //   }

  //   .dropdown {
  //     position: absolute;
  //     background: #fff8;
  //     border: 2px solid rgba(0, 0, 0, 0.05);
  //     border-top: 0;
  //     border-radius: 0 0 1.1rem 1.1rem;
  //     inset: 100% 0.7rem auto;
  //     transform: translateY(-100%);
  //     transition: transform 0.2s ease-in-out;
  //     padding: 0.5rem 0.5rem;
  //     z-index: 1;

  //     li {
  //       padding: 0.7rem 0.2rem;
  //       + li {
  //         border-top: 2px solid rgba(#1e1348, 0.05);
  //       }
  //     }

  //     a {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       color: var(--color-professor1);
  //       font-weight: 700;
  //       font-size: 1.4rem;
  //       line-height: 1.6rem;

  //       &::after {
  //         flex: 0 0 2.4rem;
  //         content: "";
  //         display: block;
  //         width: 2.4rem;
  //         height: 2.4rem;
  //         background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21.6a9.6 9.6 0 1 0 0-19.199 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.697 1.697l1.552 1.551H8.399a1.2 1.2 0 1 0 0 2.4h4.304l-1.552 1.552a1.2 1.2 0 1 0 1.697 1.697l3.6-3.6a1.2 1.2 0 0 0 0-1.697Z' fill='%23FF604F'/%3E%3C/svg%3E");
  //       }
  //     }
  //   }

  //   .open .dropdown {
  //     transform: translateY(0);
  //   }
  // }
}
