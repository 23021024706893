.index-projects-floor {
  padding-top: 2rem;
  position: relative;
  overflow: hidden;

  .parallax-element > div {
    --size: 31.4rem;
    width: var(--size);
    height: var(--size);
    background-image: url("data:image/svg+xml,%3Csvg width='314' height='314' viewBox='0 0 314 314' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='157' cy='157' r='128.743' stroke='%231E1348' stroke-opacity='.05' stroke-width='56.513'/%3E%3C/svg%3E");
  }

  .parallax-element1 {
    top: 16rem;
    right: -16rem;
  }

  .parallax-element2 {
    bottom: 72rem;
    left: -15rem;
  }

  @media (min-width: $lg) {
    position: relative;
    padding-top: 1rem;

    .head {
      padding-top: 0;
      margin-bottom: 4rem;
    }

    &::before {
      content: "";
      position: absolute;
      inset: auto 0 4rem;
      height: 5rem;
      border-bottom-right-radius: 5rem;
      background: #fff;
    }

    .parallax-element > div {
      --size: 49.9rem;
      background-image: url("data:image/svg+xml,%3Csvg width='499' height='499' viewBox='0 0 499 499' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M449 249.5C449 359.681 359.681 449 249.5 449S50 359.681 50 249.5 139.319 50 249.5 50 449 139.319 449 249.5Z' stroke='%231E1348' stroke-opacity='.05' stroke-width='100'/%3E%3C/svg%3E");
    }

    .parallax-element1 {
      top: 15rem;
      right: -25.4rem;
    }

    .parallax-element2 {
      bottom: 72rem;
      // left: calc(50% );
      left: calc(50% - 16rem);
    }
  }
}

.index-project {
  // --slide1-color: var(--color-yellow);
  // --slide2-color: var(--color-blue);
  // --slide3-color: var(--color-green);
  // --slide4-color: var(--color-red);

  &__media {
    position: relative;

    .bg {
      height: 37rem;
      object-fit: cover;
    }

    .logo.xs {
      position: absolute;
      bottom: 0;
      width: auto;
      height: 14.8rem;
    }
  }

  &__content {
    padding: 2.2rem 2.2rem 4.6rem 1.8rem;
  }

  &__title {
    font-size: 2.6rem;
    line-height: 3.4rem;
    font-weight: bold;
  }

  &__body {
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 2.4rem;

    p + p {
      margin-top: 1.4rem;
    }
  }

  &__tags {
    margin-top: 1.8rem;
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;
  }

  &__cta {
    margin-top: 3.7rem;
  }

  &__tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3.2rem;
    padding: 0.3rem 1.3rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0.8rem 2.5rem -1rem rgba(30, 19, 72, 0.08);
    border-radius: 9in;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 700;
    white-space: nowrap;

    &[data-variant="red"] {
      color: var(--color-red);
    }
    &[data-variant="orange"] {
      color: var(--color-orange);
    }
    &[data-variant="blue"] {
      color: var(--color-blue);
    }
    &[data-variant="green"] {
      color: var(--color-green);
    }
  }

  @at-root :where([data-even]) & {
    &__media {
      margin-right: 3.5rem;

      .bg {
        border-radius: 0 9in 9in 0;
      }

      .logo.xs {
        margin-left: -5rem;
      }
    }
  }
  @at-root :where([data-odd]) & {
    &__media {
      margin-left: 3.5rem;

      .bg {
        border-radius: 9in 0 0 9in;
      }
    }
  }

  @at-root :where([data-brand="blue"]) & {
    &__media {
      padding-bottom: 2rem;
    }
    &__cta {
      --bg: var(--color-blue);
      --bg-hover: var(--color-blue);
      --bg-active: var(--color-blue);
    }
  }

  @at-root :where([data-brand="red"]) & {
    &__media {
      padding-bottom: 3.7rem;
    }
    &__cta {
      --bg: var(--color-red);
      --bg-hover: var(--color-red);
      --bg-active: var(--color-red);
    }
  }

  @at-root :where([data-brand="green"]) & {
    &__media {
      padding-bottom: 3.7rem;
    }
    &__cta {
      --bg: var(--color-green);
      --bg-hover: var(--color-green);
      --bg-active: var(--color-green);
    }
  }

  @media (min-width: $lg) {
    display: flex;

    &[data-odd] {
      flex-direction: row-reverse;
    }

    &__title {
      font-size: 3.6rem;
      line-height: 4.4rem;
    }

    &__body {
      margin-top: 2.6rem;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }

    &__tags {
      margin-top: 2.4rem;
    }

    &__cta {
      margin-top: 2.8rem;
    }

    &__media {
      flex: 50%;
      margin: 0;
      padding: 0;

      .bg {
        min-height: 69rem;
        width: 100%;
      }

      .logo.lg {
        position: absolute;
        height: 26rem;
        width: auto;
        max-width: none;
      }
    }

    &__content {
      flex: 50%;
      display: flex;
      align-items: center;

      > div {
        max-width: 51rem;
      }
    }

    @at-root :where([data-even]) & {
      &__media {
        margin-right: 0;
        padding-right: 3rem;

        .bg {
          border-radius: 0 9in 9in 0;
        }

        .logo {
          margin-left: -5rem;
        }
      }
      &__content {
        padding-left: 5.8rem;
        justify-content: flex-start;
      }
    }
    @at-root :where([data-odd]) & {
      &__media {
        margin-left: 0;
        padding-left: 3rem;

        .bg {
          border-radius: 9in 0 0 9in;
        }
      }
      &__content {
        padding-right: 5.8rem;
        justify-content: flex-end;
      }
    }

    @at-root :where([data-brand="blue"]) & {
      &__media {
        .logo.lg {
          top: 3.8rem;
          right: 1.1rem;
        }
      }
    }

    @at-root :where([data-brand="red"]) & {
      &__media {
        .logo.lg {
          top: 6.5rem;
          left: -3.5rem;
        }
      }
    }

    @at-root :where([data-brand="green"]) & {
      &__media {
        .logo.lg {
          top: 11.5rem;
          right: -1rem;
        }
      }
    }
  }
}
