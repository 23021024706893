.container {
  ----container-inline-padding: var(--container-inline-padding, 0rem);

  padding-left: calc(var(----container-inline-padding) + var(--container-left-padding, var(--container-inline-padding, 2rem)));
  padding-right: calc(var(----container-inline-padding) + var(--container-right-padding, var(--container-inline-padding, 2rem)));

  @media (min-width: $lg) {
    // ----container-fluid-width: calc(100% - var(--container-inline-padding, 2rem) * 2);
    // ----container-fixed-width: calc(var(--container-width, 144rem) - var(--container-inline-padding, 2rem) * 2);
    // ----container-inline-padding: calc(50vw - max(var(----container-fluid-width), var(----container-fixed-width)) / 2);

    // ----padding-offset: var(--container-inline-padding, 2rem) * 2;
    // ----container-inline-padding: calc(50vw - (min(100vw, var(--container-width, 144rem)) / 2));
    ----container-inline-padding: clamp(2rem, 2vw, calc(50vw - (min(100vw, var(--container-width, 144rem)) / 2)));
    // ----container-inline-padding: calc(50vw - 50vw + var(--container-inline-padding, 2rem));
  }
}

.container-only {
  margin-left: auto;
  margin-right: auto;
  // width: clamp(100% - 4rem, 100% - 2vw, var(--container-width, 144rem));
  // width: clamp(100vw - 4rem, 100vw - 20vw, 144rem);
  // width: clamp(100vw - 4rem, 80%, 144rem);
  width: clamp(4rem, 100vw - 4rem, 144rem);
  background: #0003;
}

.container-fluid {
  --computed-width: min(100vw - var(--inline-padding, 2rem) * 2, var(--container-width, 144rem));
  --computed-inline-padding: calc((100vw - var(--computed-width)) / 2);
  padding-left: var(--computed-inline-padding);
  padding-right: var(--computed-inline-padding);
}

// .container {
//   ----container-inline-padding: var(--container-inline-padding, 2rem);

//   padding-left: calc(var(----container-inline-padding) + var(--container-left-padding, 2rem));
//   padding-right: var(----container-inline-padding);

//   @media (min-width: $lg) {
//     // ----container-fluid-width: calc(100% - var(--container-inline-padding, 2rem) * 2);
//     // ----container-fixed-width: calc(var(--container-width, 144rem) - var(--container-inline-padding, 2rem) * 2);
//     // ----container-inline-padding: calc(50vw - max(var(----container-fluid-width), var(----container-fixed-width)) / 2);

//     ----padding-offset: var(--container-inline-padding, 2rem) * 2;
//     ----container-inline-padding: calc(50vw - (min(100vw, var(--container-width, 144rem)) / 2) + var(--container-inline-padding, 2rem));
//     // ----container-inline-padding: calc(50vw - 50vw + var(--container-inline-padding, 2rem));
//   }
// }
