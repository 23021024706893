.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  padding: 0 2.4rem;
  gap:.8rem;
  font-family: inherit;
  // color: var(--clr-dark);

  font-size: 1.6rem;
  line-height: 1;
  font-weight: 600;
  color: var(--color, var(--color-white));
  background: var(--bg, var(--color-blue));
  border: 0;

  transition: background-color var(--duration-hover);
  border-radius: 9in;

  &:hover,
  &:focus {
    background: var(--bg-hover, var(--color-blue));
    text-decoration: none;
  }

  &:active {
    background: var(--bg-active, var(--color-blue));
    text-decoration: none;
  }
}
