.numbered-list {
  display: grid;
  gap: 3.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.4rem;
    text-align: center;
  }

  .num {
    position: relative;
    font-size: 4.2rem;
    line-height: 1;
    font-weight: 700;
    // width: 5.2rem;
    isolation: isolate;

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 0.9rem;
      transform: translateX(-50%);
      width: 5.2rem;
      height: 4.7rem;

      background: no-repeat center / contain;
    }
  }

  li:nth-child(1) .num::before {
    background-image: url("data:image/svg+xml,%3Csvg width='51' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.938 34.75c-15.137 11.368-23.08 12.811-34.5 6.683C.015 35.305-3.026 23.181 3.1 11.765 9.224.35 24.926-3.144 36.347 2.985c11.422 6.128 20.584 21.027 9.59 31.765Z' fill='%23FDB71A'/%3E%3C/svg%3E");
  }
  li:nth-child(2) .num::before {
    background-image: url("data:image/svg+xml,%3Csvg width='50' height='47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M41.301 6.703c8.741 10.17 11.687 25.405 2.222 33.54C34.06 48.375 16.287 48.17 7.545 38-1.196 27.83-2.775 15.423 6.69 7.289c9.465-8.134 25.87-10.757 34.612-.586Z' fill='%23FF604F'/%3E%3C/svg%3E");
  }
  li:nth-child(3) .num::before {
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 25.017C48 37.498 32.513 46 19.932 46 7.352 46 0 33.462 0 20.983 0 8.503 11.826 0 24.407 0 36.987 0 48 12.538 48 25.017Z' fill='%23789FED'/%3E%3C/svg%3E");
  }

  @media (min-width: $xl) {
    gap: 3.6rem;

    li {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      gap: 6rem;
    }
    .sub2 {
      margin-bottom: 0.6rem;
    }

    .num {
      font-size: 4.3rem;
      &::before {
        top: 1.2rem;
        width: 6.6rem;
        height: 6rem;
      }
    }
  }
}
