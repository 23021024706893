.media {
  position: relative;
  // overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
  }

  &.background {
    position: absolute;
    border-radius: 1rem 1rem 5rem 0;
    inset: 0 0 11.4rem;
    z-index: -1;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
}
