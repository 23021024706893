.index-testimonals-floor {
  --offset-bottom: 29rem;
  position: relative;

  height: 80rem;

  @media (min-width: $lg) {
    height: calc(88rem + var(--offset-bottom));
    margin-bottom: calc(var(--offset-bottom) * -1);
    padding-top: 0;
    padding-bottom: var(--offset-bottom);
  }
}

.index-testimonals-slider {
  position: relative;
  display: grid;
  width: 100vw;
  user-select: none;
  overflow: hidden;
  height: 100%;
}

.index-testimonal {
  --brand-color: var(--color-blue);
  --max-width: 50rem;

  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 11rem 2rem 0;
  text-align: center;

  color: #fff;

  img {
    --size: 17.4rem;
    width: var(--size);
    height: var(--size);
    border-radius: 9in;
    border: 4px solid var(--brand-color);
    pointer-events: none;
  }

  &__title {
    max-width: var(--max-width);
    position: relative;
    margin-top: 3.4rem;
    margin-bottom: 5.4rem;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-style: italic;

    b {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        display: block;
        background: var(--brand-color);
        inset: auto -0.7rem -0.1rem -1.4rem;
        height: 2.2rem;
        font-weight: 600;
        box-shadow: 0px 1rem 1.2rem -0.4rem rgba(0, 0, 0, 0.14);
        border-radius: 9in;
        z-index: -1;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      --size: 2.4rem;
      width: var(--size);
      height: var(--size);
      background: var(--brand-color);
      border-radius: 9in;
      bottom: -1rem;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }

  &__name {
    max-width: var(--max-width);
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 700;
    letter-spacing: 1%;
  }

  .loading &:not(:first-child) {
    display: none;
  }

  @media (min-width: $lg) {
    --max-width: 90rem;
    padding-top: 18rem;

    &__title {
      margin-top: 3.4rem;
      margin-bottom: 6.3rem;
      font-size: 3.8rem;
      line-height: 5.2rem;

      b::before {
        inset: auto 0.7rem -0.1rem -0.8rem;
        height: 2.6rem;
      }
    }

    &__name {
      font-size: 2.2rem;
      line-height: 2.6rem;
    }
  }

  &[data-brand="blue"] {
    --brand-color: var(--color-blue);
  }
  &[data-brand="red"] {
    --brand-color: var(--color-red);
  }
  &[data-brand="green"] {
    --brand-color: var(--color-green);
  }
}

.index-testimonals-nav {
  display: none;
  position: absolute;
  display: flex;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;
  inset: auto 0 3rem;

  &__item {
    --size: 1.6rem;
    width: var(--size);
    height: var(--size);
    opacity: 0.4;
    transition: width 0.25s ease-in-out, opacity 0.25s ease-in-out;
    background: #fff;
    border-radius: 9in;
    cursor: pointer;

    &.active {
      width: 4.4rem;
      opacity: 1;
    }
  }
  @media (min-width: $lg) {
    bottom: calc(4.7rem + var(--offset-bottom));
  }
}
