.home-subjects-floor {
  padding-top: 2rem;
  padding-bottom: 6rem;
  background: #f5f7fa;
  z-index: 0;

  .subjects {
    display: grid;
    gap: 3.6rem;
  }

  .subject {
    img {
      border-radius: 1.2rem;
      box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 1;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 10px 32px -10px rgba(0, 0, 0, 0.15);
      border-radius: 0 0 1.2rem 1.2rem;

      padding: 1.6rem 1rem 1.2rem;
      margin: 0 1.3rem;

      color: #000;
      text-align: center;
      background: #fff;
    }
    &,
    &:hover {
      text-decoration: none;
    }

    //   &[data-variant="llengua-catalana"] img { --box-shadow}
    //   &[data-variant="lengua-castellana"] img { --box-shadow}
    //   &[data-variant="ciencies-socials"] img { --box-shadow}
    //   &[data-variant="tecnologia"] img { --box-shadow}
  }

  @media (min-width: $lg) {
    --container-width: min(100%, 134rem);
    padding-top: 14rem;
    padding-bottom: 14rem;

    .subjects {
      grid-template-columns: repeat(4, 1fr);
      gap: 2.5rem;
      margin-top: 6.7rem;
    }

    .subject {
      img {
        aspect-ratio: 1/1;
      }

      .content {
        min-height: 9.4rem;
      }
    }

    .parallax-element {
      content: "";
      position: absolute;
      display: block;
      width: 141.5rem;
      height: 86.9rem;
      left: 50%;
      bottom: -3rem;
      margin-left: -23rem;

      > div {
        position: absolute;
        inset: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='1415' height='869' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M419.263 253.463A2205.358 2205.358 0 0 1 1156.13 3.943a216.498 216.498 0 0 1 86.78.924 217.1 217.1 0 0 1 79.53 34.932 217.59 217.59 0 0 1 59.53 63.342 217.776 217.776 0 0 1 29.99 81.598 217.32 217.32 0 0 1-4.36 86.772 216.598 216.598 0 0 1-38.01 78.036 216.133 216.133 0 0 1-65.57 56.79 216.118 216.118 0 0 1-82.61 26.44c-320.08 48.785-620.855 183.359-870.473 389.471-44.433 34.99-100.806 51.166-157.074 45.072-56.268-6.093-107.97-33.974-144.058-77.684-36.089-43.71-53.703-99.784-49.08-156.242C5.35 576.936 31.844 524.572 74.55 487.489A2226.429 2226.429 0 0 1 419.422 253.11' fill='%23fff'/%3E%3C/svg%3E");
      }
    }
  }
}
